import { createAction, props } from '@ngrx/store'
import { CreateLioCommand } from '@app-shared-types/commands/createLioCommand.model'
import { LioInterface } from '@app-shared-types/lio.interface'
import { PaginatedResult } from '@app/shared/types/paginated-result.interface'
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface'
import { UpdateLioCommand } from '@app/shared/types/commands/updateLioCommand.model'
import { ConfigureSftpCommand } from '../../shared/types/commands/configureSFTPCommand.model'
import { LioDocument } from '@app/shared/types/lio-document'

export const CREATE_LIO = '[LIO Component] Create lio'
export const CREATE_LIO_SUCCESSFUL = '[LIO Component] Create lio success'
export const CREATE_LIO_FAILED = '[LIO Component] Create lio failed'

export const UPDATE_LIO = '[LIO Component] Update lio'
export const UPDATE_LIO_SUCCESSFUL = '[LIO Component] Update lio success'
export const UPDATE_LIO_FAILED = '[LIO Component] Update lio failed'

export const GET_LIOS = '[LIO Component] Get lios'
export const GET_LIOS_SUCCESSFUL = '[LIO Component] Get lios success'
export const GET_LIOS_FAILED = '[LIO Component] Get lios failed'

export const GET_LIO_BY_ID = '[LIO Component] Get lio by id'
export const GET_LIO_BY_ID_SUCCESSFUL = '[LIO Component] Get lio by id success'
export const GET_LIO_BY_ID_FAILED = '[LIO Component] Get lio by id failed'

export const GET_LIO_BY_LIO_NUMBER = '[LIO Component] Get lio by lio number'
export const GET_LIO_BY_LIO_NUMBER_SUCCESSFUL = '[LIO Component] Get lio by lio number success'
export const GET_LIO_BY_LIO_NUMBER_FAILED = '[LIO Component] Get lio by lio number failed'

export const CONFIGURE_SFTP_FOR_LIO = '[LIO Component] Configure sftp for lio'
export const CONFIGURE_SFTP_FOR_LIO_SUCCESSFUL = '[LIO Component] Configure sftp for lio success'
export const CONFIGURE_SFTP_FOR_LIO_FAILED = '[LIO Component] Configure sftp for lio failed'

export const UPDATE_SFTP_FOR_LIO = '[LIO Component] Update sftp for lio'
export const UPDATE_SFTP_FOR_LIO_SUCCESSFUL = '[LIO Component] Update sftp for lio success'
export const UPDATE_SFTP_FOR_LIO_FAILED = '[LIO Component] Update sftp for lio failed'

export const DELETE_LIO_DOCUMENT = '[LIO Component] Delete lio document'
export const DELETE_LIO_DOCUMENT_SUCCESSFUL = '[LIO Component] Delete lio document success'
export const DELETE_LIO_DOCUMENT_FAILED = '[LIO Component] Delete lio document failed'

export const create = createAction(CREATE_LIO, props<CreateLioCommand>())

export const createSuccessfully = createAction(CREATE_LIO_SUCCESSFUL)

export const createFailed = createAction(CREATE_LIO_FAILED, props<Error>())

export const getLios = createAction(
  GET_LIOS,
  props<{ tableOptions: TableFilteringSortingOptions }>()
)

export const getLiosSuccessfully = createAction(
  GET_LIOS_SUCCESSFUL,
  props<{ paginatedResult: PaginatedResult<LioInterface> }>()
)

export const getLiosFailed = createAction(GET_LIOS_FAILED, props<{ error: string }>())

export const getLioById = createAction(GET_LIO_BY_ID, props<{ id: number }>())

export const getLioByIdSuccessfully = createAction(
  GET_LIO_BY_ID_SUCCESSFUL,
  props<{ lio: LioInterface }>()
)

export const getLioByIdFailed = createAction(GET_LIO_BY_ID_FAILED, props<{ error: string }>())

export const getLioByLioNumber = createAction(GET_LIO_BY_LIO_NUMBER, props<{ lioNumber: number }>())

export const getLioByLioNumberSuccessfully = createAction(
  GET_LIO_BY_LIO_NUMBER_SUCCESSFUL,
  props<{ lio: LioInterface }>()
)

export const getLioByLioNumberFailed = createAction(
  GET_LIO_BY_LIO_NUMBER_FAILED,
  props<{ error: string }>()
)

export const update = createAction(UPDATE_LIO, props<UpdateLioCommand>())

export const updateSuccessfully = createAction(UPDATE_LIO_SUCCESSFUL, props<{ lio: UpdateLioCommand }>())

export const updateFailed = createAction(UPDATE_LIO_FAILED, props<{ error: string }>())
export const configureSftpForLio = createAction(
  CONFIGURE_SFTP_FOR_LIO, 
  props<{ sftpConfig: ConfigureSftpCommand }>())

export const configureSftpForLioSuccessfully = createAction(
  CONFIGURE_SFTP_FOR_LIO_SUCCESSFUL,
  props<{ sftpConfig: ConfigureSftpCommand }>()
)

export const configureSftpForLioFailed = createAction(
  CONFIGURE_SFTP_FOR_LIO_FAILED,
  props<{ error: string }>()
)

export const updateSftpForLio = createAction(
  UPDATE_SFTP_FOR_LIO, 
  props<{ sftpConfig: ConfigureSftpCommand }>())

export const updateSftpForLioSuccessfully = createAction(
  UPDATE_SFTP_FOR_LIO_SUCCESSFUL,
  props<{ sftpConfig: ConfigureSftpCommand }>()
)

export const updateSftpForLioFailed = createAction(
  UPDATE_SFTP_FOR_LIO_FAILED,
  props<{ error: string }>()
)

export const deleteLioDocument = createAction(
  DELETE_LIO_DOCUMENT,
  props<{ documentId: number }>()
)

export const deleteLioDocumentSuccessfully = createAction(
  DELETE_LIO_DOCUMENT_SUCCESSFUL,
  props<{ deletedDocument: LioDocument }>()
)

export const deleteLioDocumentFailed = createAction(
  DELETE_LIO_DOCUMENT_FAILED,
  props<{ error: string }>()
)
