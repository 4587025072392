import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, catchError, throwError } from 'rxjs'
import { environment } from 'environments/environment'
import { TableFilteringSortingOptions } from '../types/export-options-columns.interface'

@Injectable()
export class ReportsService {
  constructor(private http: HttpClient) {}

  getActiveEICCodes(
    exportOptionColumns: TableFilteringSortingOptions | undefined
  ): Observable<any> {
    return this.http
      .get<any>('reports/active-eic-codes', { params: this.ToHttpParams(exportOptionColumns) })
      .pipe(catchError(this.handleError))
  }

  getAllEICCodes(exportOptionColumns: TableFilteringSortingOptions | undefined): Observable<any> {
    return this.http
      .get<any>('reports/all-eic-codes', { params: this.ToHttpParams(exportOptionColumns) })
      .pipe(catchError(this.handleError))
  }

  getDeactivatedCodes(
    exportOptionColumns: TableFilteringSortingOptions | undefined
  ): Observable<any> {
    return this.http
      .get<any>('reports/deactivated-codes', { params: this.ToHttpParams(exportOptionColumns) })
      .pipe(catchError(this.handleError))
  }

  getAllApprovedRequests(
    exportOptionColumns: TableFilteringSortingOptions | undefined
  ): Observable<any> {
    return this.http
      .get<any>('reports/all-approved-requests', { params: this.ToHttpParams(exportOptionColumns) })
      .pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    var errorMessage = ''
    if (error.status === 0) {
      errorMessage = 'Could not connect to the backend API. Please contact the system administrator'
    } else {
      errorMessage = error.error
    }
    return throwError(() => new Error(errorMessage))
  }

  private ToHttpParams(request: any): HttpParams {
    let httpParams = new HttpParams()
    Object.keys(request).forEach(function (key) {
      httpParams = httpParams.append(key, request[key])
    })
    return httpParams
  }
}
