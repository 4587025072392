import { Inject, Injectable } from "@angular/core";
import { UserInfoStateInterface } from "@app/shared/types/user-info-state.interface";
import { Store } from "@ngrx/store";
import { KeycloakService } from "keycloak-angular";
import * as UserActions from '../store/actions/user-info.actions';
import { UserInfoInterface } from "@app/shared/types/user-info.interface";

import { APP_BASE_HREF } from '@angular/common';
import { Router } from "@angular/router";

const IMPERSONATED_LIO = 'impersonatedLio'
const CIO = 'CIO'

@Injectable()
export class AuthService {
    userDetails: any;
    lioNumber: number;

    constructor(private keycloakAngular: KeycloakService,
        private userStore: Store<UserInfoStateInterface>) {
        this.userDetails = this.keycloakAngular.getKeycloakInstance().tokenParsed;
    }

    updateUserStore(){
        let currentUser: UserInfoInterface = {
            lioNumber: this.getLioNumber(),
            isLio10: this.isLio10(),
            isImpersonating: this.getLioNumber() == 10? true : false 
          };

        if(localStorage.getItem(IMPERSONATED_LIO) == CIO){
            currentUser.isImpersonating = false;
        }
        else if(localStorage.getItem(IMPERSONATED_LIO) && this.isLio10()){
            var lioNumberString = localStorage.getItem(IMPERSONATED_LIO)
            if(lioNumberString){
                var lioNumber = parseInt(lioNumberString)
                currentUser.lioNumber = lioNumber
            }
        }
          
        this.userStore.dispatch(UserActions.setUserInfo({currentUser: currentUser}));
    }
    
    logOut(){        
        this.keycloakAngular.logout(window.location.origin).then(() => this.keycloakAngular.clearToken());
        localStorage.clear();
        sessionStorage.clear();
    }

    isLio(){
        return this.userDetails['LIO_ID'] != 10;
    }

    isLio10(){
        return this.userDetails['LIO_ID'] == 10;
    }

    getLioNumber(){
        return this.userDetails['LIO_ID'];
    }

    getUserEmail(){
        return this.userDetails['email'];
    }

    getUserName(){
        return this.userDetails['given_name'];
    }

    isLoggedIn() {
        return this.keycloakAngular.isLoggedIn();
    }
}
