import { MessageInterface } from "@app/shared/types/message.interface"
import { createAction, props } from "@ngrx/store"

export const GET_MESSAGES = '[MESSAGE Component] Get messages'
export const GET_MESSAGES_SUCCESSFUL = '[MESSAGE Component] Get messages success'
export const GET_MESSAGES_FAILED = '[MESSAGE Component] Get messages failed'

export const getMessages = createAction(GET_MESSAGES)

export const getMessagesSuccesfully = createAction(
    GET_MESSAGES_SUCCESSFUL,
    props<{messages: MessageInterface[]}>()
)

export const getMessagesFailed = createAction(
    GET_MESSAGES_FAILED,
    props<{error: string}>()
)