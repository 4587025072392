import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LioInterface } from '@app/shared/types/lio.interface';

@Component({
  selector: 'app-modal-update-lio-infos',
  templateUrl: './modal-update-lio-infos.component.html',
  styleUrls: ['./modal-update-lio-infos.component.scss']
})
export class ModalUpdateLioInfosComponent {
  @Input() sendLio: LioInterface

  visible: boolean = false;

  showDialog(){
    this.visible = true;
  }
  onClose(){
    this.visible = false;
  }
}
