import { createReducer, on } from '@ngrx/store'
import * as LioActions from '../actions/lio.actions'
import { LioStateInterface } from '@app-shared-types/lio-state.interface'
import { LioDocument } from '@app/shared/types/lio-document'
import { immerOn } from 'ngrx-immer/store'

export const initialState: LioStateInterface = {
  isLoading: false,
  lios: [],
  error: null,
  pageNumber: 0,
  rowsPerPage: 10,
  totalRecords: 0
}

export const lioReducer = createReducer(
  { ...initialState },
  on(LioActions.getLios, (state) => ({
    ...state,
    isLoading: true
  })),
  on(LioActions.getLiosSuccessfully, (state, action) => ({
    ...state,
    isLoading: false,
    lios: action.paginatedResult.entities,
    totalRecords: action.paginatedResult.totalRecords,
    pageNumber: action.paginatedResult.pageNumber,
    rowsPerPage: action.paginatedResult.rowsPerPage
  })),
  on(LioActions.getLiosFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  on(LioActions.getLioById, (state) => ({
    ...state,
    isLoading: true
  })),
  on(LioActions.getLioByIdSuccessfully, (state, action) => ({
    ...state,
    isLoading: false,
    lios: action.lio != null ? [...state.lios, action.lio] : state.lios
  })),
  on(LioActions.getLioByIdFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  on(LioActions.getLioByLioNumber, (state) => ({
    ...state,
    isLoading: true
  })),
  immerOn(LioActions.getLioByLioNumberSuccessfully, (state, action) => {
    state.isLoading = false
    state.lios = state.lios.filter((item) => item.id != action.lio.id)
    state.lios.push(action.lio)
  }),
  // on(LioActions.getLioByLioNumberSuccessfully, (state, action) => ({
  //   ...state,
  //   isLoading: false,
  //   lios: action.lio != null ? [...state.lios, action.lio] : state.lios
  // })),
  on(LioActions.getLioByLioNumberFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  on(LioActions.update, (state) => ({
    ...state,
    isLoading: true
  })),
  on(LioActions.updateSuccessfully, (state, action) => ({
    ...state,
    isLoading: false,
    lios: state.lios.map((l) => {
      return l.lioNumber === action.lio.lioNumber
        ? {
            ...l,
            name: action.lio.name,
            contactPersonName: action.lio.contactPersonName,
            contactPersonEmail: action.lio.contactPersonEmail,
            isElectricity: action.lio.isElectricity,
            isGas: action.lio.isGas,
            createdBy: action.lio.createdBy,
            creationDate: action.lio.creationDate,
            phoneNumber: action.lio.phoneNumber,
            address: action.lio.address,
            companyName: action.lio.companyName,
            companyFunction: action.lio.companyFunction,
            vatCode: action.lio.vatCode,
            acerCode: action.lio.acerCode,
            documents: action.lio.documents!,
            eicCode: action.lio.eicCode
          }
        : l
    })
  })),
  on(LioActions.updateFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  on(LioActions.configureSftpForLio, (state) => ({
    ...state,
    isLoading: true
  })),
  on(LioActions.configureSftpForLioSuccessfully, (state, action) => ({
    ...state,
    isLoading: false,
    lios: state.lios.map((l) =>
      l.lioNumber === action.sftpConfig.lioNumber
        ? {
            ...l,
            sftpUsername: action.sftpConfig.username,
            sftpPublicKey: action.sftpConfig.sshPublicKey
          }
        : l
    )
  })),
  on(LioActions.configureSftpForLioFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  on(LioActions.updateSftpForLio, (state) => ({
    ...state,
    isLoading: true
  })),
  on(LioActions.updateSftpForLioSuccessfully, (state, action) => ({
    ...state,
    isLoading: false,
    lios: state.lios.map((l) =>
      l.lioNumber === action.sftpConfig.lioNumber
        ? {
            ...l,
            sftpUsername: action.sftpConfig.username,
            sftpPublicKey: action.sftpConfig.sshPublicKey
          }
        : l
    )
  })),
  on(LioActions.updateSftpForLioFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  })),
  on(LioActions.deleteLioDocument, (state) => ({
    ...state,
    isLoading: true
  })),
  on(LioActions.deleteLioDocumentSuccessfully, (state, action) => ({
    ...state,
    isLoading: false,
    lios: state.lios.map((l) =>
      l.id === action.deletedDocument.lioId
        ? {
            ...l,
            documents: l.documents.filter((doc) => doc.id !== action.deletedDocument.id)
          }
        : l
    )
  })),
  on(LioActions.deleteLioDocumentFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error
  }))
)
