import { createAction, props } from '@ngrx/store';
import { FunctionInterface } from '@app/shared/types/function.interface';

export const GET_FUNCTIONS = '[APP Component] Get functions';
export const GET_FUNCTIONS_SUCCESSFUL = '[APP Component] Get functions succes';
export const GET_FUNCTIONS_FAILED = '[APP Component] Get functions failed';

export const getFunctions = createAction(
    GET_FUNCTIONS
);

export const getFunctionsSuccessfully = createAction(
    GET_FUNCTIONS_SUCCESSFUL,
    props<{functions: FunctionInterface[]}>()
);

export const getFunctionsFailed = createAction(
    GET_FUNCTIONS_FAILED,
    props<{error: string}>()
);