<div [formGroup]="form">
    <p-dropdown dataKey="value" optionValue="value" formControlName="country" [options]="countries" [(ngModel)]="selectedCountry" optionLabel="label" [filter]="true" filterBy="label" [showClear]="true" placeholder="Select country">
        <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
                <div>{{ getFullCountryName() }}</div>
            </div>
        </ng-template>
        <ng-template let-country pTemplate="item">
            <div class="flex align-items-center gap-2">
                <div>{{ country.label + " (" + country.value + ")" }}</div>
            </div>
        </ng-template>
    </p-dropdown>
</div>
