<p-dialog
  header="RequestAction"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '80vw' }"
  closeIcon="icon icon--close"
  (onHide)="onClose()"
>
  <ng-template pTemplate="header">
    <h2 class="text-secondary">New request</h2>
  </ng-template>

  <form [formGroup]="requestForm">
    <app-allocated-eic-code [requestForm]="requestForm" formType="create" />
    <app-eic-identification [requestForm]="requestForm" />
    <app-contact-details [requestForm]="requestForm" />
    <app-function [functionsControls]="functions" />
    <app-description [descriptionControl]="requestForm.controls['description']" />
  </form>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-end gap-3">
      <p-button
        label="Cancel"
        styleClass="p-button-outlined p-button-secondary"
        [outlined]="true" severity="secondary"
        (click)="onClose()"
      ></p-button>
      <p-button
        label="Save"
        type="submit"
        (click)="onSubmit()"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
