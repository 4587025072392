<p-dialog 
header="Are you sure you want to delete this file?" 
[(visible)]="visible" 
[modal]="true"
[style]="{ width: '50vw' }" 
closeIcon="icon icon--close">

    <ng-template pTemplate="header">
        <h4>Are you sure you want to delete this file?</h4>
    </ng-template>

    <div *ngIf="lioDocument != null || lioDocument != undefined" class="container">
        <h5>{{ lioDocument.name }}</h5>
    </div>

    <ng-template pTemplate="footer">
        <div class="flex justify-content-end gap-3">
            <p-button label="No" [outlined]="true" severity="secondary" (click)="onClose()"></p-button>
            <p-button label="Yes" type="submit" (click)="onSubmit()"></p-button>
        </div>
    </ng-template>

</p-dialog>