import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http'
import { CreateLioCommand } from '../types/commands/createLioCommand.model'
import { Observable, catchError, throwError } from 'rxjs'
import { LioInterface } from '../types/lio.interface'
import { PaginatedResult } from '../types/paginated-result.interface'
import { TableFilteringSortingOptions } from '../types/export-options-columns.interface'
import { UpdateLioCommand } from '../types/commands/updateLioCommand.model'
import { ConfigureSftpCommand } from '../types/commands/configureSFTPCommand.model'
import { ofType } from '@ngrx/effects'
import { LioDocument } from '../types/lio-document'

@Injectable()
export class LioService {
  constructor(private http: HttpClient) {}

  createLio(lio: CreateLioCommand) {
    var formData = new FormData()

    type ObjectKey = keyof typeof lio
    for (const property in lio) {
      if (lio[property as ObjectKey] && property != 'files') {
        formData.append(property, String(lio[property as ObjectKey]))
      }
    }

    lio.files.forEach((file) => {
      formData.append('files', file, file.name)
    })

    return this.http.post('lios', formData).pipe(catchError(this.handleError))
  }

  updateLio(lio: UpdateLioCommand): Observable<UpdateLioCommand> {
    var formData = new FormData()

    type ObjectKey = keyof typeof lio
    for (const property in lio) {
      if (lio[property as ObjectKey] && property != 'files') {
        formData.append(property, String(lio[property as ObjectKey]))
      }
    }

    lio.files.forEach((file) => {
      formData.append('files', file, file.name)
    })

    return this.http.post<UpdateLioCommand>('lios/update', formData).pipe(catchError(this.handleError))
  }

  getLioById(lioId: number): Observable<LioInterface> {
    return this.http
      .get<LioInterface>('lios/' + lioId.toString())
      .pipe(catchError(this.handleError))
  }

  getLioByLioNumber(lioNumber: number): Observable<LioInterface> {
    return this.http
      .get<LioInterface>('lios?lioNumber=' + lioNumber)
      .pipe(catchError(this.handleError))
  }

  getLios(
    tableOptions: TableFilteringSortingOptions | undefined
  ): Observable<PaginatedResult<LioInterface>> {
    return this.http
      .get<PaginatedResult<LioInterface>>('lios/all', { params: this.ToHttpParams(tableOptions) })
      .pipe(catchError(this.handleError))
  }

  configureSftpForLio(sftpConfig: ConfigureSftpCommand) {
    return this.http.post('sftp', sftpConfig)
    .pipe(catchError(this.handleError))
  }

  updateSftpConfiguration(sftpConfig: ConfigureSftpCommand) {
    return this.http.put('sftp', sftpConfig)
      .pipe(catchError(this.handleError))
  }

  deleteDocumentById(documentId: number){
    return this.http.delete<LioDocument>('lios/document/' + documentId.toString())
    .pipe(catchError(this.handleError))
  }

  private ToHttpParams(request: any): HttpParams {
    let httpParams = new HttpParams()
    Object.keys(request).forEach(function (key) {
      httpParams = httpParams.append(key, request[key])
    })

    return httpParams
  }

  private handleError(error: HttpErrorResponse) {
    var errorMessage = ''
    if (error.status === 0) {
      errorMessage = 'Could not connect to the backend API. Please contact the system administrator'
    } else {
      errorMessage = error.error
    }
    return throwError(() => new Error(errorMessage))
  }
}
