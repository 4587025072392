import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as exportActions from '../actions/export.actions';
import { MessageService } from 'primeng/api';
import { ExportsService } from '@app/shared/services/exports.service';


@Injectable()
export class ExportEffect {
	constructor(
		private actions$: Actions,
		private exportService: ExportsService,
		private messageService: MessageService
	) {}

	public downloadTP$ = createEffect(() =>
		this.actions$.pipe(
			ofType(exportActions.downloadTP),
			mergeMap(({eicId: eicId}) => {
				return this.exportService.downloadTP(eicId).pipe(
					map((exp) => {
                        return exportActions.downloadTPSuccessful({export: exp})
					}),
					catchError((error) => {
						this.messageService.add({
							key: 'toast',
							severity: 'error',
							summary: 'Error',
							detail: 'Download failed.',
						});
						return of(
							exportActions.downloadTPFailed({ error: error.message })
						);
					})
				);
			})
		)
	);

	public exportToTP$ = createEffect(() =>
		this.actions$.pipe(
			ofType(exportActions.exportToTP),
			mergeMap(({eicIds: eicIds}) => {
				return this.exportService.exportToTP(eicIds).pipe(
					map((exp) => {
						this.messageService.add({
							key: 'toast',
							severity: 'success',
							summary: 'Success',
							detail: "Upload success",
						});
                        return exportActions.exportToTPSuccessful({export: exp})
					}),
					catchError((error) => {
						this.messageService.add({
							key: 'toast',
							severity: 'error',
							summary: 'Error',
							detail: 'Export failed.',
						});
						return of(
							exportActions.exportToTPFailed({ error: error.message })
						);
					})
				);
			})
		)
	);

	public getEICs$ = createEffect(() =>
		this.actions$.pipe(
			ofType(exportActions.getEICsTpHistory),
			mergeMap(({tableOptions}) => {
				return this.exportService.getAllEICsTpHistoryExports(tableOptions).pipe(
					map((paginatedResult) => exportActions.getEICsTpHistorySuccessfully({paginatedResult})),
					catchError((error) => {
						this.messageService.add({
							key: 'toast',
							severity: 'error',
							summary: 'Error',
							detail: 'Get EICs failed.',
						});
						return of(
							exportActions.getEICsTpHistoryFailed({ error: error.message })
						);
					})
				);
			})
		)
	);
}
