import { SelectItem } from "primeng/api"

export enum PendingRequestActionEnum{
    Approve = 0,
    Reject = 1
}

export const PendingRequestActionList: SelectItem[] = [
    {label: "Approve", value: 0},
    {label: "Reject", value: 1}
]
    