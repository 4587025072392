import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MessageStateInterface } from "@app/shared/types/message-state.interface";

export const messagesSelector = createSelector(
    createFeatureSelector('messages'),
    (state: MessageStateInterface) => { 
        return state.messages
    }
)
export const isLoadingSelector = createSelector(
    createFeatureSelector('isLoading'),
    (state: MessageStateInterface) => { 
        return state.isLoading
    }
)
export const errorSelector = createSelector(
    createFeatureSelector('error'),
    (state: MessageStateInterface) => { 
        return state.error
    }
)