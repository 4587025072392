<p-toast key="toast" *ngIf="!(isMaintenance$ |async)">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p>{{ message.detail }}</p>
      </div>
    </div>
  </ng-template>
</p-toast>
<router-outlet></router-outlet>

<div class="progress-spinner" *ngIf="loaderService.isLoading | async">
  <p-progressSpinner styleClass="custom-progress-spinner-color"></p-progressSpinner>
</div>
