import { environment } from "environments/environment";

export class SideBarMenu {
    menu = [
        {
            label: 'Contact CIO Administrator',
            url: 'mailto:' + environment.adminEmail,
            icon: 'icon icon--envelope'
        },
        {
            label: 'Home',
            routerLink: '/',
            icon: 'icon icon--home',
            routerLinkActiveOptions: { exact: true }
        },
        {
            label: 'EIC Codes (All)',
            routerLink: '/eics',
            icon: 'icon icon--eye',
        }
    ]
}