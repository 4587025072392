import { createFeatureSelector, createSelector } from '@ngrx/store'
import { RequestStateInterface } from '@app-shared-types/request-state.interface'
import { RequestStatusEnum } from '@app-shared-types/enums/request-status.enum'

export const requestsSelector = createSelector(
  createFeatureSelector('requests'),
  (state: RequestStateInterface) => {
    return state.requests
  }
)

export const latestRequestsSelector = createSelector(
  createFeatureSelector('requests'),
  (state: RequestStateInterface) => {
    return state.latestRequests
  }
)

export const requestsOfLio = (lioNumber: number) =>
  createSelector(requestsSelector, (requests) => requests.filter((r) => r.lioNumber == lioNumber))

export const requestById = (requestId: number) =>
  createSelector(requestsSelector, (requests) => requests.filter((r) => r.id == requestId)[0])

export const latestFivePendingRequests = () =>
  createSelector(requestsSelector, (requests) =>
    requests.filter((r) => r.status == RequestStatusEnum.Pending).slice(0, 5)
  )

export const latestFiveRequestsOfLio = (lioNumber: number | null) =>
  createSelector(latestRequestsSelector, (latestRequests) =>
    latestRequests.filter((r) => r.lioNumber == lioNumber).slice(0, 5)
  )

export const isLoadingSelector = createSelector(
  createFeatureSelector('isLoading'),
  (state: RequestStateInterface) => {
    return state.isLoading
  }
)
export const errorSelector = createSelector(
  createFeatureSelector('requests'),
  (state: RequestStateInterface) => {
    return state.error
  }
)
export const totalRecordsSelector = createSelector(
  createFeatureSelector('requests'),
  (state: RequestStateInterface) => {
    return state.totalRecords
  }
)
export const pageNumberSelector = createSelector(
  createFeatureSelector('requests'),
  (state: RequestStateInterface) => {
    return state.pageNumber
  }
)
export const rowsPerPageSelector = createSelector(
  createFeatureSelector('requests'),
  (state: RequestStateInterface) => {
    return state.rowsPerPage
  }
)

export const bulkRequestsStatusSelector = createSelector(
  createFeatureSelector('requests'),
  (state: RequestStateInterface) => {
    return state.bulkRequestStatus
  }
)
