import { AbstractControl, Validators } from "@angular/forms";

export function responsiblePartyValidator(formControl: AbstractControl) {

  if (!formControl.parent) {
    return null;
  }
  
  return formControl.parent.get('eicCodeType')?.value == 'V' ? Validators.required(formControl) : null;
  
}