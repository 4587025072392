import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { CreateLioCommand } from '@app/shared/types/commands/createLioCommand.model'
import { UpdateLioCommand } from '@app/shared/types/commands/updateLioCommand.model'
import { LioInterface } from '@app/shared/types/lio.interface'
import { UserInfoStateInterface } from '@app/shared/types/user-info-state.interface'
import { UserInfoInterface } from '@app/shared/types/user-info.interface'
import { availableMarketValidator } from '@app/shared/validators/availableMarketValidator'
import { create, update } from '@app/store/actions/lio.actions'
import { currentUserSelector } from '@app/store/selectors/user-info.selector'
import { Store, select } from '@ngrx/store'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-lio',
  templateUrl: './lio.component.html',
  styleUrls: ['./lio.component.scss']
})
export class LioComponent implements OnInit {

  @Input() lio: LioInterface
  lioForm: FormGroup

  isGas: boolean = false
  isElectricity: boolean = false
  submitted: boolean = false

  uploadedFiles: any[] = []
  selectedValues: string[] = []

  userSub: Subscription
  isLio10: boolean | null

  @Output('onCloseUpdateModal') onCloseUpdateModal: EventEmitter<any> = new EventEmitter()
  @Output('onLioDataChange') onLioDataChange: EventEmitter<any> = new EventEmitter()

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private userStore: Store<UserInfoStateInterface>
  ) {
    this.userSub = this.userStore
      .pipe(select(currentUserSelector))
      .subscribe((user: UserInfoInterface) => {
        this.isLio10 = user.isLio10
      })

    this.lioForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      contactPersonName: new FormControl(''),
      contactPersonEmail: new FormControl(
        '',
        Validators.compose([Validators.email, Validators.required])
      ),
      phoneNumber: new FormControl(
        '',
        Validators.compose([Validators.pattern('^([+]?[0-9]+)?$'), Validators.required])
      ),
      address: new FormControl(''),
      companyName: new FormControl(''),
      companyFunction: new FormControl('', Validators.required),
      availableMarketGroup: new FormGroup(
        {
          isElectricity: new FormControl([]),
          isGas: new FormControl([])
        },
        { validators: availableMarketValidator }
      ),
      VATCode: new FormControl(''),
      EICCode: new FormControl(
        '',
        Validators.compose([Validators.minLength(16), Validators.maxLength(16)])
      ),
      ACERCode: new FormControl('')
    })
  }

  ngOnInit() {
    if (this.lio != null && this.lio != undefined) {
      this.lioForm.patchValue(this.lio)
      if (this.lio.isElectricity) {
        this.selectedValues.push('Electricity')
      }
      if (this.lio.isGas) {
        this.selectedValues.push('Gas')
      }
      this.lioForm.controls['EICCode'].setValue(this.lio.eicCode);
      this.lioForm.controls['ACERCode'].setValue(this.lio.acerCode);
      this.lioForm.controls['VATCode'].setValue(this.lio.vatCode);
    }
  }

  onUpload(event: any) {
    this.uploadedFiles = []
    for (let file of event.files) {
      this.uploadedFiles.push(file)
    }
  }

  onSave() {
    if (this.lioForm.valid) {
      let lio: CreateLioCommand = {
        name: this.lioForm.controls['name'].value,
        contactPersonName: this.lioForm.controls['contactPersonName'].value,
        contactPersonEmail: this.lioForm.controls['contactPersonEmail'].value,
        phoneNumber: this.lioForm.controls['phoneNumber'].value,
        address: this.lioForm.controls['address'].value,
        companyName: this.lioForm.controls['companyName'].value,
        companyFunction: this.lioForm.controls['companyFunction'].value,
        isElectricity:
          this.selectedValues.find((item) => item == 'Electricity') != undefined ? true : false,
        isGas: this.selectedValues.find((item) => item == 'Gas') != undefined ? true : false,
        VATCode: this.lioForm.controls['VATCode'].value,
        EICCode: this.lioForm.controls['EICCode'].value,
        ACERCode: this.lioForm.controls['ACERCode'].value,
        files: this.uploadedFiles
      }
      this.store.dispatch(create(lio))
    }
    this.submitted = true
  }

  onUpdate() {
    if (this.lioForm.valid) {
      let lio: UpdateLioCommand = {
        id: this.lio.id,
        lioNumber: this.lio.lioNumber,
        createdBy: this.lio.createdBy,
        creationDate: this.lio.creationDate,
        name: this.lioForm.controls['name'].value,
        contactPersonName: this.lioForm.controls['contactPersonName'].value,
        contactPersonEmail: this.lioForm.controls['contactPersonEmail'].value,
        phoneNumber: this.lioForm.controls['phoneNumber'].value,
        address: this.lioForm.controls['address'].value,
        companyName: this.lioForm.controls['companyName'].value,
        companyFunction: this.lioForm.controls['companyFunction'].value,
        isElectricity:
          this.selectedValues.find((item) => item == 'Electricity') != undefined ? true : false,
        isGas: this.selectedValues.find((item) => item == 'Gas') != undefined ? true : false,
        vatCode: this.lioForm.controls['VATCode'].value,
        eicCode: this.lioForm.controls['EICCode'].value,
        acerCode: this.lioForm.controls['ACERCode'].value,
        files: this.uploadedFiles
      }

      this.store.dispatch(update(lio))
      this.onCloseUpdateModalMethod()
    }
    this.submitted = true
  }

  onCloseUpdateModalMethod() {
    this.onCloseUpdateModal.emit()
  }
}
