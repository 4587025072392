import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as exportActions from '../actions/history.actions';
import { MessageService } from 'primeng/api';
import { HistoryService } from '@app/shared/services/history.service';


@Injectable()
export class HistoryEffect {
	constructor(
		private actions$: Actions,
		private historyService: HistoryService,
		private messageService: MessageService
	) {}

	public getHistory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(exportActions.getHistory),
			mergeMap(({tableOptions}) => {
				return this.historyService.getHistory(tableOptions).pipe(
					map((paginatedResult) => {
                        return exportActions.getHistorySuccessful({paginatedResult})
					}),
					catchError((error) => {
						this.messageService.add({
							key: 'toast',
							severity: 'error',
							summary: 'Error',
							detail: 'Get history failed.',
						});
						return of(
							exportActions.getHistoryFailed({ error: error.message })
						);
					})
				);
			})
		)
	);
}
