<p-fieldset legend="Description" styleClass="mb-5">
  <div class="grid">
    <div class="col-8">
      <textarea
        class="w-full"
        pInputTextarea
        placeholder="Leave a note"
        [formControl]="descriptionControl"
      ></textarea>
      <span *ngIf="descriptionControl?.errors?.['maxlength']" class="p-error"> 
        The description can contain a maximum of 700 characters.
      </span>      
    </div>
  </div>
</p-fieldset>
