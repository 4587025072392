import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MessageInterface } from '@app/shared/types/message.interface';
import { messagesSelector } from '@app/store/selectors/message.selector';
import { Store, select } from '@ngrx/store';
import { Message } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import * as MessageActions from '@app/store/actions/message.action'

@Component({
  selector: 'app-homepage-messages',
  templateUrl: './homepage-messages.component.html',
  styleUrls: ['./homepage-messages.component.scss']
})
export class HomepageMessagesComponent {
  messages: Message[] = []
  firstMessages: Message[] = []
  messages$: Observable<MessageInterface[]>
  messagesSub: Subscription
  openedMessages: boolean = false;
  fullText: string[] = []

  constructor(
  private messageStore: Store<MessageInterface>,
  private datepipe: DatePipe) {

    this.messages$ = this.messageStore.pipe(select(messagesSelector));
 
  }

  ngOnInit(): void {
    this.messageStore.dispatch(MessageActions.getMessages());

    this.messagesSub = this.messages$.subscribe(data => {
      let newMessages: Message[] = []
      data.forEach(message =>{
        var textMessage = message.textMessage
        this.fullText.push(textMessage)

        const newMessage: Message = {
          severity: 'error',
          detail: textMessage,
        }
        newMessages.push(newMessage)
       })

      this.firstMessages = newMessages.slice(0,2)
      this.messages = newMessages

      this.firstMessages.forEach(message => {
        if(message.detail != undefined && message.detail?.length > 250){
          message.detail = message.detail.slice(0,250) + "..."
        }
      })
      
    });
    
  }

  openMessages(){
    this.openedMessages = true;
    let i = 0;
    this.messages.forEach(message => {
      message.detail = this.fullText[i]
      i++;
    })
  }

  closeMessages(){
    this.openedMessages = false;
    this.firstMessages.forEach(message => {
      if(message.detail != undefined && message.detail?.length > 250){
        message.detail = message.detail.slice(0,250) + "..."
      }
    })
  }

  ngOnDestroy(){
    this.messagesSub?.unsubscribe();
  }
}
