export class AllEicCodesReportTableHeaders {
    defaultColumns = [
        { field: 'eicCode', header: 'EIC code', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 160},
        { field: 'longName', header: 'EIC long name', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150},
        { field: 'displayName', header: 'EIC display name', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150 },
        { field: 'euvatCode', header: 'EU VAT code', visible: true, activeStatus: true, sort: true, filter: true , minWidth: 150},
        { field: 'requestActionObj', header: 'Request Action', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 150 },
        { field: 'statusObj', header: 'Status', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 100 },
        { field: 'requestDate', header: 'Request Date', visible: true, activeStatus: true, sort: true, filter: true, minWidth: 220 },
    ];

    additionalColumns = [
        { field: 'creationDate', header: 'Creation date', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 220},
        { field: 'deactivationDate', header: 'Deactivation date', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 220},
        { field: 'approvalDate', header: 'Approval date', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 220},
        { field: 'updateDate', header: 'Update date', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 220},
        { field: 'approvalReason', header: 'Approval reason', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 250},
        { field: 'sourceObj', header: 'Source', visible: false, activeStatus: false, sort: false, filter: true, minWidth: 250 },
        { field: 'description', header: 'Description', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 300},
        { field: 'eicCodeTypeObj', header: 'EIC code type', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 150},
        { field: 'lioNumber', header: 'LIO code', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 100},
        { field: 'parent', header: 'EIC parent', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 300},
        { field: 'responsibleParty', header: 'Responsible party', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 300 },
        { field: 'acerNumber', header: 'ACER number', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 150},
        { field: 'functions', header: 'Functions', visible: false, activeStatus: false, sort: true, filter: true , minWidth: 150},
    ];

    allColumns = [...this.defaultColumns, ...this.additionalColumns];
}