import { createAction, props } from '@ngrx/store';
import { SettingInterface } from '@app/shared/types/setting.interface';

export const GET_SETTINGS = '[Settings Overview Component] Get settings';
export const GET_SETTINGS_SUCCESSFUL = '[Settings Overview Component] Get settings succes';
export const GET_SETTINGS_FAILED = '[Settings Overview Component] Get settings failed';

export const CREATE_SETTING = '[SETTING Component] Create setting';
export const CREATE_SETTING_SUCCESSFUL = '[SETTING Component] Create setting succes';
export const CREATE_SETTING_FAILED = '[SETTING Component] Create setting failed';

export const getSettings = createAction(
    GET_SETTINGS
);

export const getSettingsSuccessful = createAction(
    GET_SETTINGS_SUCCESSFUL,
    props<{setting: any}>()
);

export const getSettingsFailed = createAction(
    GET_SETTINGS_FAILED,
    props<{error: string}>()
);

export const createSetting = createAction(
    CREATE_SETTING,
    props<{setting: SettingInterface}>()
);

export const createSettingSuccessfully = createAction(
    CREATE_SETTING_SUCCESSFUL,
    props<{setting: any }>()
);

export const createSettingFailed = createAction(
    CREATE_SETTING_FAILED,
    props<{error: string}>()
);