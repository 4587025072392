import { SelectItem } from "primeng/api";
import { EicStatusObj } from "../eic.interface";

export enum EICStatusEnum{
    Active = 0,
    Inactive = 1
}

export const eicStatusList: SelectItem[] = [
    {label: "Active", value: 0, icon: 'success'},
    {label: "Inactive", value: 1, icon: 'danger'}
]

export const eicStatusFilterOptions : EicStatusObj[] = [
    {label: "Active", value: 0},
    {label: "Inactive", value: 1}
  ]