import { createReducer, on } from '@ngrx/store';
import * as eicActions from '../actions/eic.actions';
import { EICStateInterface } from '@app-shared-types/eic-state.interface';

export const initialState: EICStateInterface = {
    isLoading: false,
	eics: [],
	error: null,
	pageNumber: 0,
	rowsPerPage: 10,
	totalRecords: 0
};

export const eicReducer = createReducer(
    { ...initialState },
    on(eicActions.getEICs, (state) => ({
		...state,
		isLoading: true 
	})),
	on(eicActions.getEICsSuccessfully, (state, action) => ({
		...state,
		isLoading: false,
		eics: action.paginatedResult.entities,
		totalRecords: action.paginatedResult.totalRecords,
		pageNumber: action.paginatedResult.pageNumber,
		rowsPerPage: action.paginatedResult.rowsPerPage
	})),
	on(eicActions.getEICsFailed, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	})),
    on(eicActions.getEICsOfLio, (state) => ({
		...state,
		isLoading: true 
	})),
	on(eicActions.getEICsOfLioSuccessfully, (state, action) => ({
		...state,
		isLoading: false,
		eics: action.paginatedResult.entities,
		totalRecords: action.paginatedResult.totalRecords,
		pageNumber: action.paginatedResult.pageNumber,
		rowsPerPage: action.paginatedResult.rowsPerPage
	})),
	on(eicActions.getEICsOfLioFailed, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	})),	
);
	