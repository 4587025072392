<div class="flex header">
    <p>{{requestActionsList[version.requestAction].label}}</p>
    <p-tag 
      [severity]="requestStatusList[version.status].icon" 
      [value]="requestStatusList[version.status].label"
    ></p-tag>
</div>


<div class='version-description'>
  <div class='row'>
    <div class='column'>
        <li class="version-description__row">
          <p>EIC code: &nbsp;</p>
        </li>
        <li class="version-description__row">
          <p>EIC display name: &nbsp;</p>
        </li>
        <li class="version-description__row">
          <p>EIC long name: &nbsp;</p>
        </li>
        <li class="version-description__row">
          <p>Request date: &nbsp;</p>
        </li>
        <li class="version-description__row" *ngIf="version.requestAction == RequestActionEnum.Create">
          <p>Creation date: &nbsp;</p>
        </li>
        <li class="version-description__row" *ngIf="version.requestAction == RequestActionEnum.Update">
          <p>Update date: &nbsp;</p>
        </li>
        <li class="version-description__row" *ngIf="version.requestAction == RequestActionEnum.Deactivate">
          <p>Deactivation date: &nbsp;</p>
        </li>
        <li class="version-description__row">
          <p>Status code: &nbsp;</p>
        </li>
        <li class="version-description__row">
          <p>Functions: &nbsp;</p>
        </li>
    </div>
    <div class='column'>
        <li class="version-description__row">
          <p>{{version.eicCode ?? '-'}} </p>
        </li>
        <li class="version-description__row">
          <p>{{version.eicDisplayName ?? '-'}}</p>
        </li>
        <li class="version-description__row">
          <p>{{version.eicLongName ?? '-'}}</p>
        </li>
        <li class="version-description__row">
          <p>{{(version.requestDate+'Z' | date:'dd/MM/YYYY') ?? '-'}}</p>
        </li>
        <li class="version-description__row" *ngIf="version.requestAction == RequestActionEnum.Create">
          <p>{{(version.creationDate+'Z' | date:'dd/MM/YYYY') ?? '-'}}</p>
        </li>
        <li class="version-description__row" *ngIf="version.requestAction == RequestActionEnum.Update">
          <p>{{(version.updateDate+'Z' | date:'dd/MM/YYYY') ?? '-'}}</p>
        </li>
        <li class="version-description__row" *ngIf="version.requestAction == RequestActionEnum.Deactivate">
          <p>{{(version.deactivationDate+'Z' | date:'dd/MM/YYYY') ?? '-'}}</p>
        </li>
        <li class="version-description__row">
          <p>{{requestEicStatus()}}</p>
        </li>
        <li class="version-description__row">
          <p class="version-description__text-wrap">{{requestFunctions()}}</p>
        </li>
    </div>
  </div>
</div>
