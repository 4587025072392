import { RouterModule } from '@angular/router';
import { AuthService } from './auth.service';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AuthenticationIssueComponent } from './authentication-issue/authentication-issue.component';

@NgModule({
  declarations: [
    UnauthorizedComponent,
    AuthenticationIssueComponent
  ],
  providers: [
    AuthService,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    RouterModule,
  ],
  exports: [
  ]
})
export class AuthenticationModule { }
