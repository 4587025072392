<div class="p-24px bg-white border-round">
  <div class="flex justify-content-between">
    <h1 class="h3 text-secondary">All EIC codes</h1>
  </div>

  <app-table
    [values]="eics"
    [visibilityColumnsHeader]="true"
    [visibilityColumnsHeaderObj]="visibilityColumnsHeaderObj"
    [settingType]="settingType"
    (onRowExpand)="getEICVersions($event)"
    [requestActionFilterOptions]="requestActionFilterOptions"
    [statusFilterOptions]="eicStatusFilterOptions"
    [requestActionsList]="requestActionsList"
    [eicCodeTypesOptions]="eicCodeTypesOptions"
    [statusList]="eicStatusList"
    [expandableRows]="true"
    [checkBoxMultiSelect]="false"
    [totalRecords]="totalRecords"
    [firstRow]="firstRow"
    (onPageChange)="onPageChange($event)"
    [filterOptions]="tableFilterOrSortOptions"
  >
  </app-table>
</div>
