import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable()
export class CioGuard implements CanActivate {

    constructor(
        private authService: AuthService, 
        private router: Router
    ) { }

    canActivate() {
        var hasRights = this.authService.isLio10();
        if(!hasRights){
            console.log("Unauthorized acces to this route");
            this.router.navigate(['unauthorized']);
        }
        return true;
    }
}