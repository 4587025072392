<div class="p-24px bg-white border-round">
  <div class="flex flex-column mb-5">
    <h1 class="h3 text-secondary">Active EIC codes</h1>
    <h5>Report is created on {{ today | date: 'd/M/YYYY' }}</h5>
  </div>

  <app-table
    [values]="eicCodes"
    [settingType]="settingType"
    [showCaption]="true"
    selectionMode="false"
    styleClass="p-datatable-gridlines"
    tableStyleClass="table-layout-fixed border-round"
    export="true"
    [exportPlace]="exportPlace"
    [totalRecords]="totalRecords"
    [exportOptions]="exportOptions"
    [firstRow]="firstRow"
    (onPageChange)="onPageChange($event)"
  >
  </app-table>
</div>
