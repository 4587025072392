<p-dialog
  [header]="title"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  closeIcon="icon icon--close"
>
  <ng-template pTemplate="header">
    <h2 class="h5">{{ title }}</h2>
  </ng-template>
  <ng-template pTemplate="content">
  <div>
    <div class="mb-6">
      <p class="m-0 p-0">{{ subTitle }}</p>
      <div *ngIf="eicCodes">
        <span class="m-0 p-0 text-light">{{ eicCodes.join(', ') }}</span>
      </div>
    </div>
    <div *ngIf="manual" class="mb-6">
      <p class="m-0 mb-3 p-0">Action</p>

      <div class="card flex">
        <div class="flex flex-column gap-2">
          <div class="field-checkbox mb-0">
            <p-radioButton
              inputId="approve"
              name="approve"
              value="approve"
              [(ngModel)]="action"
            ></p-radioButton>
            <label for="approve" class="ml-2">Approve</label>
          </div>
          <div class="field-checkbox mb-0">
            <p-radioButton
              inputId="reject"
              name="reject"
              value="reject"
              [(ngModel)]="action"
            ></p-radioButton>
            <label for="reject" class="ml-2">Reject</label>
          </div>
        </div>
      </div>
    </div>
    <form [formGroup]="approveForm">
      <div>
        <label>
          Comment
          <span class="text-light">(optional)</span>
        </label>
        <textarea
          formControlName="comment"
          pInputTextarea
          class="w-full p-inputtextarea-sm mt-3"
        ></textarea>
      </div>
    </form>
  </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-end gap-3">
      <p-button
        label="Cancel"
        styleClass="p-button-outlined p-button-secondary"
        [outlined]="true" severity="secondary"
        (click)="visible = false"
      ></p-button>
      <p-button [label]="label" type="submit" (click)="onSubmit()"></p-button>
    </div>
  </ng-template>
</p-dialog>
