import { Injectable } from '@angular/core';
import { CanActivate, Router,  ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable()
export class CioLioGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    canActivate(navigateTo : ActivatedRouteSnapshot) {

        if(this.authService.isLio10())
            return true;
        else{
            let lioNr = this.authService.getLioNumber();
            let routeLioNr = parseInt(navigateTo.paramMap.get('lioNumber')!, 10)
            var hasRights = lioNr == routeLioNr;
 
            if(!hasRights){
                console.log("Unauthorized acces to this route");
                this.router.navigate(['unauthorized']);
            }
            return true;
        }    
    }
}