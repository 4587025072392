import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, mergeMap, catchError } from 'rxjs/operators'
import * as requestActions from '../actions/request.actions'
import { MessageService } from 'primeng/api'
import { RequestService } from '@app-shared-services/request.service'
import { Router } from '@angular/router'
import { PendingRequestActionList } from '@app-shared-types/enums/pending-request-action.enum'
import { RequestStatusEnum } from '@app/shared/types/enums/request-status.enum'

@Injectable()
export class RequestEffect {
  pendingRequestList = PendingRequestActionList

  constructor(
    private actions$: Actions,
    private requestService: RequestService,
    private messageService: MessageService,
    private router: Router
  ) {}

  public getAllRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestActions.getAllRequests),
      mergeMap(({ tableOptions }) => {
        return this.requestService.getAllRequests(tableOptions).pipe(
          map((paginatedResult) => {
            return requestActions.getAllRequestsSuccessfully({ paginatedResult })
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get all requests failed'
            })
            return of(requestActions.getAllRequestsFailed(error))
          })
        )
      })
    )
  )

  public getRequestsOfLio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestActions.getRequestsOfLio),
      mergeMap(({ lioNumber, tableOptions }) => {
        return this.requestService.getRequestsOfLio(lioNumber, tableOptions).pipe(
          map((paginatedResult) => {
            return requestActions.getRequestsOfLioSuccessfully({ paginatedResult })
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get requests of lio failed.'
            })
            return of(requestActions.getRequestsOfLioFailed(error))
          })
        )
      })
    )
  )

  public getRequestById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestActions.getRequestById),
      mergeMap(({ id }) => {
        return this.requestService.getRequestById(id).pipe(
          map((request) => {
            return requestActions.getRequestByIdSuccessfully({ request })
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get request by id failed.'
            })
            return of(requestActions.getRequestByIdFailed(error))
          })
        )
      })
    )
  )

  public getLatestFivePendingRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestActions.getLatestFivePendingRequests),
      mergeMap(({ lioNumber: lioNumber }) => {
        return this.requestService.getLatestFivePendingRequests(lioNumber).pipe(
          map((requests) => {
            return requestActions.getLatestFivePendingRequestsSuccesfuly({ requests })
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get latest pending requests failed'
            })
            return of(requestActions.getLatestFivePendingRequestsFailed(error))
          })
        )
      })
    )
  )

  public getLatestFiveRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestActions.getLatestFiveRequestsByLio),
      mergeMap(({ lioNumber: lioNumber }) => {
        return this.requestService.getLatestFiveRequests(lioNumber).pipe(
          map((requests) => {
            return requestActions.getLatestFiveRequestsByLioSuccesfuly({ requests })
          }),

          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get latest requests failed'
            })
            return of(requestActions.getLatestFiveRequestsByLioFailed(error))
          })
        )
      })
    )
  )

  public createRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestActions.createRequest),
      mergeMap(({ request: request }) => {
        return this.requestService.createRequest(request).pipe(
          map((newRequest) => {
            if(newRequest.status == RequestStatusEnum.Rejected){
              this.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Request was created, but it was rejected',
                detail: newRequest.approvalReason
              })
            }
            else{
              this.messageService.add({
                key: 'toast',
                severity: 'success',
                summary: 'Success',
                detail: 'Request creation successful'
              })
            }
            
            this.router.navigate(['lio', newRequest.lioNumber, 'requests'])
            return requestActions.createRequestSuccessfully({ request: newRequest })
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Request creation failed',
              detail: error.message
            })
            return of(requestActions.createRequestFailed(error))
          })
        )
      })
    )
  )

  public createRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestActions.createRequests),
      mergeMap(({ bulkRequests: bulkRequests }) => {
        return this.requestService.createRequests(bulkRequests).pipe(
          map((status) => {
            var hasErrors = status?.find(s => s.requestStatus == 'Not created' || s.requestStatus == 'Unauthorized') != null
            if(hasErrors){
              this.messageService.add({
                key: 'toast',
                severity: 'error',
                summary: 'Request(s) not created',
                detail: 'Check the upload results section for details',
              })
            }
            else{
              this.messageService.add({
                key: 'toast',
                severity: 'success',
                summary: 'Success',
                detail: 'Request(s) creation successful'
              })
            }
            return requestActions.createRequestsSuccessfully({ bulkRequestsStatus: status })
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Request creation failed',
              detail: error.message
            })
            return of(requestActions.createRequestsFailed({ error: error.message }))
          })
        )
      })
    )
  )

  public approvePendingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestActions.approvePendingRequest),
      mergeMap(({ approvalRequestData: approvalRequestData }) => {
        return this.requestService.approvePendingRequest(approvalRequestData).pipe(
          map(() => {
            this.messageService.add({
              key: 'toast',
              severity: 'success',
              summary: 'Success',
              detail:
                this.pendingRequestList[
                  approvalRequestData.pendingRequestAction
                ].label?.toString() + ' request action successful'
            })
            this.router.navigate(['cio/requests'])
            return requestActions.approvePendingRequestSuccessfuly()
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary:
                this.pendingRequestList[
                  approvalRequestData.pendingRequestAction
                ].label?.toString() + ' request failed',
              detail: error.message
            })
            return of(requestActions.approvePendingRequestFailed(error))
          })
        )
      })
    )
  )
  public bulkApprovePendingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestActions.bulkApprovePendingRequest),
      mergeMap(({ bulkApprovalRequestData: bulkApprovalRequestData }) => {
        return this.requestService.bulkApprovePendingRequest(bulkApprovalRequestData).pipe(
          map(() => {
            this.messageService.add({
              key: 'toast',
              severity: 'success',
              summary: 'Success',
              detail:
                this.pendingRequestList[
                  bulkApprovalRequestData.pendingRequestAction
                ].label?.toString() + ' request action successful'
            })
            return requestActions.bulkApprovePendingRequestSuccessfuly()
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary:
                this.pendingRequestList[
                  bulkApprovalRequestData.pendingRequestAction
                ].label?.toString() + ' request failed',
              detail: 'Something went wrong, Please try again, If the problem persist contact the CIO admin'
            })
            return of(requestActions.bulkApprovePendingRequestFailed(error))
          })
        )
      })
    )
  )
  
  public updateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestActions.updateRequest),
      mergeMap(({ request: request }) => {
        return this.requestService.updateRequest(request).pipe(
          map(() => {
            this.messageService.add({
              key: 'toast',
              severity: 'success',
              summary: 'Success',
              detail: 'Request update successful'
            })
            this.router.navigate(['lio', request.lioNumber, 'requests'])
            return requestActions.updateRequestSuccessfully()
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Request update failed',
              detail: error.message
            })
            return of(requestActions.updateRequestFailed(error))
          })
        )
      })
    )
  )
}
