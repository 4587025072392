import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SettingStateInterface } from "@app-shared-types/setting-state.interface";

export const tableHeadersSettingsSelector = createSelector(
    createFeatureSelector('settings'),
    (state: SettingStateInterface) => { 
        return state?.settings;
    }
)
export const isLoadingSelector = createSelector(
    createFeatureSelector('isLoading'),
    (state: SettingStateInterface) => { 
        return state.isLoading
    }
)
export const errorSelector = createSelector(
    createFeatureSelector('error'),
    (state: SettingStateInterface) => { 
        return state.error
    }
)