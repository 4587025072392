import { Component, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { UserInfoStateInterface } from '@app/shared/types/user-info-state.interface'
import { getLioNumber } from '@app/store/selectors/user-info.selector'
import { Store } from '@ngrx/store'

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent {
  @Input() requestForm: FormGroup
  actingLioNumber: number

  constructor(private userStore: Store<UserInfoStateInterface>) {
    this.userStore
      .select(getLioNumber())
      .subscribe((lioNumber) => (this.actingLioNumber = lioNumber))
  }
}
