import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, mergeMap, catchError } from 'rxjs/operators'
import * as reportActions from '../actions/report.actions'
import { MessageService } from 'primeng/api'
import { ReportsService } from '@app/shared/services/reports.service'

@Injectable()
export class ReportEffect {
  constructor(
    private actions$: Actions,
    private reportService: ReportsService,
    private messageService: MessageService
  ) {}

  public getActiveEicReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportActions.getActiveEicReport),
      mergeMap(({ props: props }) => {
        return this.reportService.getActiveEICCodes(props).pipe(
          map((report) => {
            return reportActions.getActiveEicSuccessful({ report: report })
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get Report failed.'
            })
            return of(reportActions.getActiveEicFailed({ error: error.message }))
          })
        )
      })
    )
  )

  public getAllEicReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportActions.getAllEicReport),
      mergeMap(({ props: props }) => {
        return this.reportService.getAllEICCodes(props).pipe(
          map((report) => {
            return reportActions.getAllEicReportSuccessful({ report: report })
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get Report failed.'
            })
            return of(reportActions.getAllEicReportFailed({ error: error.message }))
          })
        )
      })
    )
  )

  public getDeactivatedCodesReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportActions.getDeactivatedCodesReport),
      mergeMap(({ props: props }) => {
        return this.reportService.getDeactivatedCodes(props).pipe(
          map((report) => {
            return reportActions.getDeactivatedCodesReportSuccessful({ report: report })
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get Report failed.'
            })
            return of(reportActions.getDeactivatedCodesReportFailed({ error: error.message }))
          })
        )
      })
    )
  )

  public getAllApprovedRequestsReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportActions.getAllApprovedRequestsReport),
      mergeMap(({ props: props }) => {
        return this.reportService.getAllApprovedRequests(props).pipe(
          map((report) => {
            return reportActions.getAllApprovedRequestsReportSuccessful({ report: report })
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get Report failed.'
            })
            return of(reportActions.getAllApprovedRequestsReportFailed({ error: error.message }))
          })
        )
      })
    )
  )
}
