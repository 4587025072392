<div class="grid">
  <div class="col-6">
    <section class="bg-link-water border-round">
      <div class="grid">
        <div class="col-auto">
          <h1 class="p-24px h2 text-secondary">Add new LIO</h1>
        </div>
        <div class="col bg-white">
          <div class="p-24px">
            <p class="mb-5 mt-0 border-bottom-1 border-silver">Fill in fields</p>
            <app-lio />
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
