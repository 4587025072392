import { createFeatureSelector, createSelector } from '@ngrx/store'
import { UserInfoStateInterface } from '@app-shared-types/user-info-state.interface'

export const currentUserSelector = createSelector(
  createFeatureSelector('currentUser'),
  (state: UserInfoStateInterface) => {
    return state.currentUser
  }
)

export const isImpersonating = () =>
  createSelector(currentUserSelector, (currentUser) => currentUser.isImpersonating)

export const isLio10 = () =>
  createSelector(currentUserSelector, (currentUser) => currentUser.isLio10!)

export const isLio = () =>
  createSelector(currentUserSelector, (currentUser) => !currentUser.isLio10!)

export const getLioNumber = () =>
  createSelector(currentUserSelector, (currentUser) => currentUser.lioNumber!)
