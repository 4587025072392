import { Component, OnDestroy, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import { EICInterface } from '@app-shared-types/eic.interface'
import { EICStateInterface } from '@app-shared-types/eic-state.interface'
import * as eicActions from '@app-store/actions/eic.actions'
import { eicsSelector, totalRecordsSelector } from '@app-store/selectors/eic.selector'
import {
  requestActionFilterOptions,
  requestActionsList
} from '@app-shared-types/enums/request-action.enum'
import { eicStatusFilterOptions, eicStatusList } from '@app/shared/types/enums/eic-status.enum'
import { VisibilityColumnsHeader } from '@app/shared/types/visibility-columns-header.interface'
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum'
import { EICService } from '@app/shared/services/eic.service'
import { RequestInterface } from '@app/shared/types/request.interface'
import { EICPartsInterface } from '@app/shared/types/eic-parts.interface'
import { eicCodeTypesList } from '@app/shared/types/enums/eic-code-type.enum'
import { ActivatedRoute, Router } from '@angular/router'
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface'

@Component({
  selector: 'app-cio-eic-overview',
  templateUrl: './cio-eic-overview.component.html',
  styleUrls: ['./cio-eic-overview.component.scss']
})
export class CioEicOverviewComponent implements OnInit, OnDestroy {
  eics: EICInterface[]
  eics$: Observable<EICInterface[]>
  versions: RequestInterface[]
  eicSub: Subscription

  settingType: SettingsTypes = SettingsTypes.eicTableHeaders
  visibilityColumnsHeaderObj: VisibilityColumnsHeader = new VisibilityColumnsHeader()
  tableFilterOrSortOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions()

  eicStatusList = eicStatusList
  requestActionsList = requestActionsList
  requestActionFilterOptions = requestActionFilterOptions
  eicStatusFilterOptions = eicStatusFilterOptions
  eicCodeTypesOptions = eicCodeTypesList

  pageNumber: number | undefined = 0
  rowsPerPage: number | undefined = 10

  totalRecords$: Observable<number>
  totalRecords: number
  firstRow: number = 0
  querySub: Subscription
  recordSub: Subscription

  constructor(
    private store: Store<EICStateInterface>,
    private eicService: EICService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.eics$ = this.store.pipe(select(eicsSelector))
    this.totalRecords$ = this.store.pipe(select(totalRecordsSelector))
  }

  ngOnInit() {
    let paramsOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions();

    this.querySub = this.route.queryParams
      .subscribe(params => {
        Object.keys(params).forEach(key => {
          if(params[key] != null){

            switch(key){
              case "requestAction":
                if(params[key] != undefined){
                  paramsOptions[key] = ""
                  params[key].split(",").forEach(requestActionLabel => {
                    paramsOptions[key] += requestActionsList.find((item) => item.label == requestActionLabel)?.value + ","
                  })
                  paramsOptions[key] = paramsOptions[key]?.slice(0, -1)
                }
                break;
              case "status":
                if(params[key] != undefined){
                  paramsOptions[key] = ""
                  params[key].split(",").forEach(eicStatusLabel => {
                    paramsOptions[key] += eicStatusList.find((item) => item.label == eicStatusLabel)?.value + ","
                  })
                  paramsOptions[key] = paramsOptions[key]?.slice(0, -1)
                }
                break;
              default:
                paramsOptions[key] = params[key]
                break;
          }
        }
        })
      })
      this.pageNumber = paramsOptions.pageNumber
      this.rowsPerPage = paramsOptions.rowsPerPage

    if (!this.pageNumber || !this.rowsPerPage) {
      this.pageNumber = 0
      this.rowsPerPage = 10
    }

    this.firstRow = this.rowsPerPage * this.pageNumber

    this.recordSub = this.totalRecords$.subscribe((total) => {
      this.totalRecords = total
    })

    this.tableFilterOrSortOptions.pageNumber = this.pageNumber
    this.tableFilterOrSortOptions.rowsPerPage = this.rowsPerPage

    Object.keys(paramsOptions).forEach(key => {
      if(paramsOptions[key] != null){
        this.tableFilterOrSortOptions[key] = paramsOptions[key]
      }
    })

    this.visibilityColumnsHeaderObj.optionDisabled = 'activeStatus'

    this.getData(this.tableFilterOrSortOptions)
  }

  getEICVersions(eic: EICInterface) {
    if (eic.versions == null) {
      let eicParts: EICPartsInterface = {
        lioNumber: eic.lioNumber,
        eicCodeType: eic.eicCodeTypeObj.value,
        eicRandomSequence: eic.eicRandomSequence,
        eicCheckKey: eic.eicCheckKey
      }

      this.eicService.getAllVersionsofEIC(eicParts).subscribe((data) => {
        eic.versions = data.map((item) => {
          var version = Object.assign({}, item, {
            url: '/eics/requests/'+item.id,
            requestFunctions: this.getRequestFunctions(item)
          })
          return version
        })
      })
    }
  }

  getRequestFunctions(request: RequestInterface) {
    return request.functions.map((r) => r.name)
  }

  onPageChange(tableFilterOrSortOptions: TableFilteringSortingOptions) {
    this.pageNumber = tableFilterOrSortOptions.pageNumber
    this.rowsPerPage = tableFilterOrSortOptions.rowsPerPage

    let props = tableFilterOrSortOptions
    Object.keys(props).forEach(function (key) {
        if(props[key] == null){
          delete props[key];
        }
    });
    this.tableFilterOrSortOptions = props

    this.getData(props);
  }

  ngOnDestroy(): void {
    this.eicSub.unsubscribe()
    this.querySub.unsubscribe()
    this.recordSub.unsubscribe()
  }

  setQueryParams() {
    let queryParams : TableFilteringSortingOptions = new TableFilteringSortingOptions()

      Object.keys(this.tableFilterOrSortOptions).forEach(key => {
        if(this.tableFilterOrSortOptions[key] != null){
          if(key != "sortBy"){
            
            switch(key){
              case "requestAction":
                if(this.tableFilterOrSortOptions[key] != undefined){
                  queryParams[key] = ""
                this.tableFilterOrSortOptions[key]?.split(",").forEach(requestAction => {
                  queryParams[key] += requestActionsList[requestAction].label + ','
                })
                queryParams[key] = queryParams[key]?.slice(0, -1)
                }
                break;
              case "status":
                if(this.tableFilterOrSortOptions[key] != undefined){
                  queryParams[key] = ""
                this.tableFilterOrSortOptions[key]?.split(",").forEach(eicStatus => {
                  queryParams[key] += eicStatusList[eicStatus].label + ','
                })
                queryParams[key] = queryParams[key]?.slice(0, -1)
                }
                break;
              default:
                queryParams[key] = this.tableFilterOrSortOptions[key]
                break;
            }
          }
        }
    });

    this.router.navigate([], { relativeTo: this.route, queryParams: queryParams })
  }

  getData(tableOptions: TableFilteringSortingOptions) {
    this.store.dispatch(eicActions.getEICs({ tableOptions: tableOptions }))

    this.eicSub = this.eics$.subscribe((eics) => {
      this.eics = eics.map((item) => {
        var eic = Object.assign({}, item, {
          statusObj: {
            label: eicStatusList[item.status].label,
            value: item.status
          },
          requestActionObj: {
            label: requestActionsList[item.requestAction].label,
            value: item.requestAction
          },
          eicCodeTypeObj: {
            label: eicCodeTypesList.filter((obj) => {
              return obj.value == item.eicCodeType
            })[0].label,
            value: item.eicCodeType
          }
        })
        return eic
      })
    })
    this.setQueryParams()
  }
}
