import { Component, Input } from '@angular/core'
import { LioInterface } from '../../../shared/types/lio.interface'
import { LioStateInterface } from '../../../shared/types/lio-state.interface'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as LioActions from '../../../store/actions/lio.actions'
import { ConfigureSftpCommand } from '../../../shared/types/commands/configureSFTPCommand.model';
import { Subject, takeUntil } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-modal-configure-sftp',
  templateUrl: './modal-configure-sftp.component.html',
  styleUrls: ['./modal-configure-sftp.component.scss']
})
export class ModalConfigureSftpComponent {

  @Input() lio: LioInterface
  @Input() isEdit: boolean

  visible: boolean = false
  sftpForm: FormGroup
  destroyed$ = new Subject<boolean>()
  
  constructor(private formBuilder: FormBuilder,
    private store: Store<LioStateInterface>,
    private updates$: Actions){

    this.sftpForm = this.formBuilder.group({
      userName: new FormControl(null, 
        Validators.compose(
          [
            Validators.required, 
            Validators.minLength(3), 
            Validators.maxLength(64),
            Validators.pattern("^[a-z0-9]*$")
          ])),
      sshPublicKey: new FormControl(null, 
        Validators.compose(
          [
            Validators.required,
            Validators.pattern("^^ssh-(rsa|dsa|ed25519|ecdsa)(\\s*|\n)([A-Za-z0-9+/]+[=]{0,3})(\\s*.+\\s*)*$")
          ])),
    })
  }

  showDialog() {
    this.visible = true

    if(this.isEdit){
      this.sftpForm.controls['userName'].setValue(this.lio.sftpUsername)
      this.sftpForm.controls['sshPublicKey'].setValue(this.lio.sftpPublicKey)
    }
  }

  saveSFTPConfiguration(){
    
    var sftpConfig: ConfigureSftpCommand = {
      lioNumber: this.lio.lioNumber,
      username: this.sftpForm.controls['userName'].value,
      sshPublicKey: this.sftpForm.controls['sshPublicKey'].value,
    };

    if(!this.isEdit){
      this.store.dispatch(LioActions.configureSftpForLio({ sftpConfig: sftpConfig }))

      this.updates$
      .pipe(ofType(LioActions.CONFIGURE_SFTP_FOR_LIO_SUCCESSFUL), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.visible = false
        this.sftpForm.reset()
      })
    }
    else{
      this.store.dispatch(LioActions.updateSftpForLio({ sftpConfig: sftpConfig }))

      this.updates$
      .pipe(ofType(LioActions.UPDATE_SFTP_FOR_LIO_SUCCESSFUL), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.visible = false
        this.sftpForm.reset()
      })
    }

  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
