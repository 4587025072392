import { createReducer, on } from '@ngrx/store';
import * as settingActions from '../actions/setting.actions';
import { SettingStateInterface } from '@app-shared-types/setting-state.interface';

import { immerOn } from 'ngrx-immer/store';
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum';

export const initialState: SettingStateInterface = {
    isLoading: false,
	settings: undefined,
	error: null
};

export const settingReducer = createReducer(
    { ...initialState },
    on(settingActions.getSettings, (state) => ({
		...state,
	})),
	on(settingActions.getSettingsSuccessful, (state, action) => {
		var newObj = {};
		Object.keys(action.setting).forEach((key, v) => {
			if(typeof action.setting[key] === 'string' && action.setting[key] != null){
				if(/^[\],:{}\s]*$/.test(action.setting[key].replace(/\\["\\\/bfnrtu]/g, '@').
				replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
				replace(/(?:^|:|,)(?:\s*\[)+/g, ''))){
					newObj[key] = JSON.parse(action.setting[key])
				}
				else {
					newObj[key] = action.setting[key]
				}
			}
			else {
				newObj[key] = action.setting[key]
			}
        });

		return {...state,
			settings: newObj
		}
	}),
	on(settingActions.getSettingsFailed, (state, action) => ({
		...state,
		error: action.error
	})),
	on(settingActions.createSetting, (state) => ({
		...state,
		isLoading: true 
	})),
	immerOn(settingActions.createSettingSuccessfully, (state, action) => {
		switch(action.setting.type) {
			case SettingsTypes.eicTableHeaders:
				state.settings.eicTableHeaders = JSON.parse(action.setting.setting)
				break;
			case SettingsTypes.requestTableHeaders:
				state.settings.requestTableHeaders = JSON.parse(action.setting.setting)
				break;
			case SettingsTypes.lioTableHeaders:
				state.settings.lioTableHeaders = JSON.parse(action.setting.setting)
				break;
			case SettingsTypes.lioRequestsTableHeaders:
				state.settings.lioRequestsTableHeaders = JSON.parse(action.setting.setting)
				break;
			case SettingsTypes.allApprovedRequests:
				state.settings.allApprovedRequestsTableHeaders = JSON.parse(action.setting.setting)
				break;
			case SettingsTypes.allEicCodes:
				state.settings.allEicCodesTableHeaders = JSON.parse(action.setting.setting)
				break;
			case SettingsTypes.tpExports:
				state.settings.tpExportsTableHeaders = JSON.parse(action.setting.setting)
				break;
		}
	}),
	on(settingActions.createSettingFailed, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	})),
);
	