import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import { Observable, catchError, throwError} from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MaintanenceStateInterface } from '@app/shared/types/maintenance.interface';
import * as MaintenanceActions from '@app/store/actions/maintenance.actions'

@Injectable()
export class APIInterceptor implements HttpInterceptor {

  constructor(private router: Router, private store: Store<MaintanenceStateInterface>){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const apiReq = req.clone({ url: `${environment.apiBaseUrl}/${req.url}` });
    return next.handle(apiReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if(error.status === 404){
          this.router.navigate(['maintenance'])
          this.store.dispatch(MaintenanceActions.updateMaintenance({isMaintenance: true}))
        }
        return throwError(() => error);
      })
    );
  }
}