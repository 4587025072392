import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, mergeMap, catchError } from 'rxjs/operators'
import * as messageActions from '../actions/message.action'
import { MessageService } from 'primeng/api'
import { HomepageMessageService } from '@app/shared/services/homepage-message.service'

@Injectable()
export class MessageEffect {
  constructor(
    private actions$: Actions,
    private homepageMessageService: HomepageMessageService,
    private messageService: MessageService
  ) {}

  getMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageActions.getMessages),
      mergeMap(() => {
        return this.homepageMessageService.getMessages().pipe(
          map((messages) => messageActions.getMessagesSuccesfully({ messages })),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get messages failed.'
            })
            return of(messageActions.getMessagesFailed({ error: error.message }))
          })
        )
      })
    )
  )
}
