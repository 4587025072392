<form [formGroup]="lioForm">
  <div class="mb-5">
    <label for="LIO_name">Name of LIO <span class="text-required">*</span></label>
    <input
      class="w-full mt-3"
      name="LIO_name"
      inputId="LIO_name"
      type="text"
      pInputText
      placeholder="Input"
      [ariaRequired]="true"
      [required]="true"
      formControlName="name"
      [ngClass]="{'input-error': submitted && !lioForm.controls['name'].valid}"
    />
    <span *ngIf="submitted && !lioForm.controls['name'].valid" 
    class="p-error"> Name of LIO must be filled in.</span>      
  </div>
  <div class="mb-5">
    <label for="contact_person">Name of contact person</label>
    <input
      class="w-full mt-3"
      name="contact_person"
      inputId="contact_person"
      type="text"
      pInputText
      placeholder="Input"
      formControlName="contactPersonName"
    />  
  </div>
  <div class="mb-5">
    <label for="contact_email">Email of contact person <span class="text-required">*</span></label>
    <input
      class="w-full mt-3"
      name="contact_email"
      inputId="contact_email"
      type="text"
      pInputText
      placeholder="Input"
      formControlName="contactPersonEmail"
      [ngClass]="{'input-error': submitted && !lioForm.controls['contactPersonEmail'].valid}"
    />  
    <span class="row p-inputtext-info"> e.g. lio@yourcompanyaddress.com </span>
    <div>
      <span *ngIf="lioForm.controls['contactPersonEmail']?.errors?.['email'] else missingEmail" class="p-error" > 
        Please provide a valid e-mail address.
      </span>     
      <ng-template #missingEmail>
        <span *ngIf="submitted && !lioForm.controls['contactPersonEmail'].valid" class="p-error" > 
          Email of contact person must be filled in.
        </span> 
      </ng-template> 
    </div>  
  </div>
  <div class="mb-5">
    <label for="phone">Phone number <span class="text-required">*</span></label>
    <input
      class="w-full mt-3"
      name="phone"
      inputId="phone"
      type="text"
      pInputText
      placeholder="Input"
      [ariaRequired]="true"
      [required]="true"
      formControlName="phoneNumber"
      [ngClass]="{'input-error': submitted && !lioForm.controls['phoneNumber'].valid}"
    />
    <span *ngIf="lioForm.controls['phoneNumber'].errors?.['pattern'] else missingPhone" class="p-error"> 
      The phone number can only contain numbers, eventually preceeded by the '+' sign.
    </span>
    <ng-template #missingPhone>
      <span *ngIf="submitted && !lioForm.controls['phoneNumber'].valid" class="p-error"> 
        The phone number must be filled in.
      </span>   
    </ng-template>    
 
  </div>
  <div class="mb-5">
    <label for="address">Address</label>
    <input
      class="w-full mt-3"
      name="address"
      inputId="address"
      type="text"
      pInputText
      placeholder="Input"
      formControlName="address"
    />
  </div>
  <div class="mb-5">
    <label for="company_name">Company name</label>
    <input
      class="w-full mt-3"
      name="company_name"
      inputId="company_name"
      type="text"
      pInputText
      placeholder="Input"
      formControlName="companyName"
    />
  </div>
  <div class="mb-5">
    <label for="company_function">Company function <span class="text-required">*</span></label>
    <input
      class="w-full mt-3"
      name="company_function"
      inputId="company_function"
      type="text"
      pInputText
      placeholder="Input"
      [ariaRequired]="true"
      [required]="true"
      formControlName="companyFunction"
      [ngClass]="{'err': submitted && !lioForm.controls['companyFunction'].valid}"
    />
    <span *ngIf="submitted && !lioForm.controls['companyFunction'].valid" class="p-error"> 
      The company function must be filled in.
    </span>    
  </div>
  <div class="mb-5">
    <p>Available markets <span class="text-required">*</span></p>
    <div class="grid" formGroupName="availableMarketGroup"
     [ngClass]="{'input-error availability-markets-error': submitted && !lioForm.controls['availableMarketGroup'].valid}">
      <div class="col-6 p-form-check">
        <p-checkbox
          name="group1"
          formControlName="isElectricity"
          value="Electricity"
          inputId="electricity"
          checkboxIcon="icon icon--checkmark"
          [(ngModel)]="selectedValues"
        ></p-checkbox>
        <label for="electricity" class="ml-3"> Electricity </label>
      </div>
      <div class="col-6 p-form-check">
        <p-checkbox
          class="right"
          name="group1"
          formControlName="isGas"
          value="Gas"
          inputId="gas"
          checkboxIcon="icon icon--checkmark"
          [(ngModel)]="selectedValues"
        ></p-checkbox>
        <label for="gas" class="ml-3"> Gas </label>
      </div>
    </div>
    <div *ngIf="submitted && lioForm.controls['availableMarketGroup'].errors?.['noAvailablemarketChecked']" class="p-error">
      At least one market must be chosen!
    </div>
  </div>
  <div class="mb-5">
    <label for="EIC_code">EIC code</label>
    <input
      class="w-full mt-3"
      name="EIC_code"
      inputId="EIC_code"
      type="text"
      maxlength="16"
      pInputText
      placeholder="Input"
      oninput="this.value=this.value.toUpperCase();"
      formControlName="EICCode"
    />
    <span *ngIf="lioForm.controls['EICCode']?.errors?.['minlength'] || 
      lioForm.controls['EICCode']?.errors?.['maxlength']" class="p-error"> 
      The EIC code must contain exactly 16 characters.
    </span>    
  </div>
  <div class="mb-5">
    <label for="VAT_code">VAT code</label>
    <input
      class="w-full mt-3"
      name="VAT_code"
      inputId="VAT_code"
      type="text"
      pInputText
      placeholder="Input"
      formControlName="VATCode"
    />
  </div>
  <div class="mb-5">
    <label for="ACER_code">ACER code</label>
    <input
      class="w-full mt-3"
      name="ACER_code"
      inputId="ACER_code"
      type="text"
      pInputText
      placeholder="Input"
      formControlName="ACERCode"
    />
  </div>
  <div class="mb-5">
    <label for="upload">Documents</label>
    <p-fileUpload
      id="upload"
      mode="advanced"
      [showUploadButton]="false"
      [showCancelButton]="false"
      [auto]="true"
      [multiple]="true"
      chooseLabel="Select Documents"
      name="documents"
      accept=".doc,.docx,.pdf,image/*"
      [customUpload]="true"
      (uploadHandler)="onUpload($event)"
    >
    <ng-template *ngIf="isLio10" pTemplate="content">
      <div *ngIf="lio.documents.length > 0; else noDocs" class="ml-6">
        <div *ngFor="let document of lio.documents" class="docs p-fileUpload-row flex gap-1 justify-content-between mb-3">
          <div class="p-fileupload-filename mt-1">{{ document.name }}</div>
          <p-button 
          icon="icon icon--trash" 
          severity="danger"
          (onClick)="modalDeleteLioDocument.showDialog(document)"></p-button>
        </div>
      </div>
    </ng-template>
    </p-fileUpload>
  </div>
  <div class="flex justify-content-end gap-3">    
    <p-button *ngIf="lio != null && lio != undefined; else allLio"
      label="Cancel"
      styleClass="p-button-outlined p-button-secondary"
      [outlined]="true" severity="secondary"
      (click)="onCloseUpdateModalMethod()"
    ></p-button>
    <ng-template #allLio>
      <p-button
      label="Cancel"
      [routerLink]="'/lio/all'"
      styleClass="p-button-outlined p-button-secondary"
      [outlined]="true" severity="secondary"
    ></p-button>
    </ng-template>
    
    <p-button label="Save" type="button" (click)="lio != null && lio != undefined ? onUpdate() : onSave()"></p-button>
  </div>
</form>
<app-modal-delete-lio-document #modalDeleteLioDocument></app-modal-delete-lio-document>
