import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Observable, catchError, throwError } from 'rxjs'
import { environment } from 'environments/environment'
import { SettingInterface } from '../types/setting.interface'

@Injectable()
export class SettingsService {
  constructor(private http: HttpClient) {}

  getAllSettings(): Observable<any> {
    return this.http.get<any>('settings/settings').pipe(catchError(this.handleError))
  }

  createSetting(setting: SettingInterface) {
    return this.http.post('settings/setting', setting).pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    var errorMessage = ''
    if (error.status === 0) {
      errorMessage = 'Could not connect to the backend API. Please contact the system administrator'
    } else {
      errorMessage = error.error
    }
    return throwError(() => new Error(errorMessage))
  }
}
