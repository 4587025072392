<form [formGroup]="requestForm">
  <p-fieldset legend="EIC Identification" styleClass="mb-5">
    <div class="pt-5 border-top-1 border-silver" *ngIf="
    formType === 'deactivation' ||
    (formType === 'update' && requestForm.get('deactivationDate')?.value != null)
  ">
      <div class="grid">
        <div class="col-12 mb-5">
          <div class="col-4">
            <label for="deactivationDate">Deactivation date</label>
            <p-calendar
              formControlName="deactivationDate"
              placeholder="Select date"
              readonlyInput="true"
              icon="icon icon--calendar"
              iconAriaLabel="Show calendar"
              [minDate]="minDate"
              dateFormat="dd/mm/yy"
              [showIcon]="true"
              (onSelect)="onSelectDate($event)"
            ></p-calendar>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5 border-top-1 border-silver">
      <div class="grid">
        <div class="col-4 mb-5" *ngIf="codeType == 'X'">
          <label>ACER number</label>
          <input class="w-full mt-3" formControlName="acerNumber" placeholder="Input" pInputText />
          <span *ngIf="requestForm.controls['acerNumber']?.errors?.['minlength'] || 
            requestForm.controls['acerNumber']?.errors?.['maxlength']; else checkAcerPattern" class="p-error"> 
            The ACER number must contain exactly 12 characters.
          </span>
          <ng-template #checkAcerPattern>
            <span *ngIf="requestForm.controls['acerNumber']?.errors?.['pattern']" class="p-error"> 
              The ACER number must match the pattern: [A-Za-z0-9_]+\.[A-Z][A-Z].
            </span>    
          </ng-template>
        </div>
        <div class="col-4 mb-5" *ngIf="codeType == 'X'">
          <label>
            EU VAT code
            <span class="text-required">*</span>
          </label>
          <input class="w-full mt-3" 
          pInputText 
          formControlName="euvatCode"
          [ngClass]="{'err': requestForm.controls['hasBeenSubmitted'].value && 
          !requestForm.controls['euvatCode']?.valid}"
          placeholder="Input" />
          <span *ngIf="requestForm.controls['euvatCode']?.errors?.['maxlength']; else missingVAT" class="p-error"> 
            The VAT must contain a maximum of 25 characters.
          </span>
          <ng-template #missingVAT>
            <span *ngIf="!requestForm.controls['euvatCode']?.valid && codeType == 'X' &&
            requestForm.controls['hasBeenSubmitted'].value" class="p-error"> 
              The VAT must be filled in. 
            </span>    
          </ng-template>                            
        </div>
        <div class="col-4 mb-5">
          <label>EIC parent</label>
          <p-button
            icon="icon icon--tooltip"
            styleClass="p-button-rounded p-button-secondary p-button-text"
            [text]="true" severity="secondary" [rounded]="true"
            pTooltip="Please add here an active EIC code of the same code type."
          ></p-button>
          <input class="w-full mt-3" 
          pInputText 
          maxlength="16"
          minlength="16"
          formControlName="eicParent"
          oninput="this.value=this.value.toUpperCase();"
          placeholder="Input" />
          <span *ngIf="requestForm.controls['eicParent']?.errors?.['minlength'] || 
          requestForm.controls['eicParent']?.errors?.['maxlength']" class="p-error"> 
            The EIC parent must contain exactly 16 characters.
          </span>  
        </div>
      </div>     
    </div>    
    <div class="pt-5 border-top-1 border-silver">
      <div class="grid">
        <div class="col-4 mb-3">
          <label>EIC long name <span class="text-required">*</span></label>
          <input class="w-full mt-3" 
          pInputText 
          formControlName="eicLongName" 
          [ngClass]="{'err': requestForm.controls['hasBeenSubmitted'].value && 
          !requestForm.controls['eicLongName']?.valid}" placeholder="Input" />
          <span *ngIf="requestForm.controls['eicLongName']?.errors?.['maxlength']; else missingName" class="p-error"> 
            The EIC name can contain a maximum of 100 characters.
          </span>
          <ng-template #missingName>
            <span *ngIf="!requestForm.controls['eicLongName']?.valid && 
            requestForm.controls?.['hasBeenSubmitted'].value" class="p-error"> 
              The EIC name must be filled in.
            </span>   
          </ng-template>                   
        </div>
        <div class="col-4 mb-3">
          <label>EIC display name <span class="text-required">*</span></label>
          <input
            class="w-full mt-3"
            pInputText
            formControlName="eicDisplayName"
            placeholder="Input"
            maxlength="16"
            oninput="this.value=this.value.toUpperCase()"
            [ngClass]="{'err': requestForm.controls['hasBeenSubmitted'].value && 
            !requestForm.controls['eicDisplayName']?.valid}"
          />
          <span *ngIf="requestForm.controls['eicDisplayName']?.errors?.['pattern']; else missingDisplayName" class="p-error"> 
            The display name can contain only uppercase letters, numbers and the characters: '+', '-', '_'
          </span>
          <ng-template #missingDisplayName>
            <span *ngIf="!requestForm.controls['eicDisplayName']?.valid &&
            requestForm.controls['hasBeenSubmitted'].value" class="p-error"> 
              The display display name must be filled in.
            </span> 
          </ng-template>                                   
        </div>
        <div class="col-4 mb-3" *ngIf="codeType != 'X'">
          <label>
            Responsible party<span *ngIf="codeType == 'V'" class="text-required">*</span>
          </label>
          <p-button
            icon="icon icon--tooltip"
            styleClass="p-button-rounded p-button-secondary p-button-text"
            [text]="true" severity="secondary" [rounded]="true"
            pTooltip="Please add here an active EIC code of type “X”."
          ></p-button>
          <input
            class="w-full mt-3"
            pInputText
            formControlName="responsibleParty"
            maxlength="16"
            placeholder="Input"
            oninput="this.value=this.value.toUpperCase();"
            [ngClass]="{'err': requestForm.controls['hasBeenSubmitted'].value && 
            !requestForm.controls['responsibleParty']?.valid}"
          />
          <span *ngIf="requestForm.controls['responsibleParty']?.errors?.['maxlength'] || 
          requestForm.controls['responsibleParty']?.errors?.['minlength']; else missingParty" class="p-error"> 
            The responsible party must contain exactly 16 characters.
          </span>
          <ng-template #missingParty>
            <span *ngIf="!requestForm.controls['responsibleParty']?.valid && 
            requestForm.controls['hasBeenSubmitted'].value" class="p-error"> 
              The responsible party must be filled in.
            </span>
          </ng-template>          
        </div>
      </div>     
    </div>
    <div *ngIf="actingLioNumber === 10 && codeType == 'X'" class="pt-5 border-top-1 border-silver">
      <div class="grid">
        <div class="col-4 mb-5">
          <label>Address</label>
          <input class="w-full mt-3" pInputText formControlName="address" placeholder="Input" />
          <span *ngIf="requestForm.controls['address']?.errors?.['maxlength']" class="p-error"> 
            The address can contain a maximum of 70 characters.
          </span>           
        </div>
        <div class="col-4 mb-5">
          <label>Postal code</label>
          <input class="w-full mt-3" pInputText formControlName="postalCode" placeholder="Input" />
          <span *ngIf="requestForm.controls['postalCode']?.errors?.['maxlength']" class="p-error"> 
            The postal code can contain a maximum of 10 characters.
          </span>           
        </div>
        <div class="col-4 mb-5">
          <label>City</label>
          <input class="w-full mt-3" pInputText formControlName="city" placeholder="Input" />
          <span *ngIf="requestForm.controls['city']?.errors?.['maxlength']" class="p-error"> 
            The city can contain a maximum of 35 characters.
          </span>           
        </div>
      </div>       
      <div class="grid">
        <div class="col-4 mb-5"  >
          <label>Country</label>
          <app-country
            [form]="requestForm">
          </app-country>
        </div>
      </div>    
    </div>
  </p-fieldset>
</form>
