import { Injectable } from "@angular/core"
import { Actions, createEffect, ofType } from "@ngrx/effects"
import * as userInfoActions from '../actions/user-info.actions'
import { tap } from "rxjs"
import { Router } from "@angular/router"
import { Store } from "@ngrx/store"
import * as requestActions from '../actions/request.actions'

export const IMPERSONATED_LIO = 'impersonatedLio'
export const CIO = 'CIO'

@Injectable()
export class UserInfoEffect {
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store
  ) {}

  public stopImpersonation$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(userInfoActions.stopLioImpersonation),
        tap(() => {
          this.router.navigate(['/'])
          this.store.dispatch(requestActions.getLatestFivePendingRequests({lioNumber: null}))
          localStorage.setItem(IMPERSONATED_LIO, CIO)
        })
      )
    },
    { dispatch: false }
  )

  public impersonate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(userInfoActions.impersonateLio),
        tap((param) => {
          localStorage.setItem(IMPERSONATED_LIO, param.lioNumber.toString())
        })
      )
    },
    { dispatch: false }
  )
}