import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import { Observable, catchError, throwError } from 'rxjs'
import { EICInterface } from '../types/eic.interface'
import { environment } from 'environments/environment'
import { RequestInterface } from '../types/request.interface'
import { EICPartsInterface } from '../types/eic-parts.interface'
import { PaginatedResult } from '../types/paginated-result.interface'
import { TableFilteringSortingOptions } from '../types/export-options-columns.interface'

@Injectable()
export class EICService {
  constructor(private http: HttpClient) {}

  getAllEICs(
    tableOptions: TableFilteringSortingOptions | undefined
  ): Observable<PaginatedResult<EICInterface>> {
    return this.http
      .get<PaginatedResult<EICInterface>>('eics', { params: this.ToHttpParams(tableOptions) })
      .pipe(catchError(this.handleError))
  }

  getEICsOfLio(
    lioNumber: number,
    tableOptions: TableFilteringSortingOptions | undefined
  ): Observable<PaginatedResult<EICInterface>> {
    return this.http
      .get<PaginatedResult<EICInterface>>('lios/' + lioNumber.toString() + '/eics', {
        params: this.ToHttpParams(tableOptions)
      })
      .pipe(catchError(this.handleError))
  }

  getAllVersionsofEIC(eicParts: EICPartsInterface): Observable<RequestInterface[]> {
    return this.http
      .post<RequestInterface[]>('eics/versions', eicParts)
      .pipe(catchError(this.handleError))
  }

  generateEIC(codeType: string): Observable<string> {
    return this.http
      .post('eics/generate?eicCodeType=' + codeType, null, { responseType: 'text' })
      .pipe(catchError(this.handleError))
  }

  generateEicCheckKey(eic: string): Observable<string> {
    return this.http
      .post('eics/generateCheckKey?eic=' + eic, null, { responseType: 'text' })
      .pipe(catchError(this.handleError))
  }

  validateEic(eic: string): Observable<string> {
    return this.http
      .post('eics/validate?eic=' + eic, null, { responseType: 'text' })
      .pipe(catchError(this.handleError))
  }

  private ToHttpParams(request: any): HttpParams {
    let httpParams = new HttpParams()
    Object.keys(request).forEach(function (key) {
      httpParams = httpParams.append(key, request[key])
    })

    return httpParams
  }
  private handleError(error: HttpErrorResponse) {
    var errorMessage = ''
    if (error.status === 0) {
      errorMessage = 'Could not connect to the backend API. Please contact the system administrator'
    } else {
      errorMessage = error.error
    }
    return throwError(() => new Error(errorMessage))
  }
}
