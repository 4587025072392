export class LioRequestsDefaultTableHeaders {
    defaultColumns = [
        { field: 'eicCode', header: 'EIC code', visible: true, activeStatus: false, sort: true, filter: true, minWidth: 160},
        { field: 'eicLongName', header: 'EIC long name', visible: true, activeStatus: false, sort: true, filter: true, minWidth: 150},
        { field: 'eicDisplayName', header: 'EIC display name', visible: true, activeStatus: false, sort: true, filter: true , minWidth: 150},
        { field: 'euvatCode', header: 'EU VAT code', visible: true, activeStatus: false, sort: true, filter: true, minWidth: 150 },
        { field: 'requestActionObj', header: 'Request action', visible: true, activeStatus: false, sort: true, filter: true, minWidth: 150 },
        { field: 'statusObj', header: 'Status', visible: true, activeStatus: false, sort: true, filter: true, minWidth: 100 },
        { field: 'requestDate', header: 'Request date', visible: true, activeStatus: false, sort: true, filter: true, minWidth: 220 },
    ];


    additionalColumns = [
        { field: 'creationDate', header: 'Creation date', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 220},
        { field: 'deactivationDate', header: 'Deactivation date', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 220 },
        { field: 'approvalDate', header: 'Approval date', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 220 },
        { field: 'updateDate', header: 'Update date', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 220 },
        { field: 'approvalReason', header: 'Approval reason', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 250 },
        { field: 'sourceObj', header: 'Source', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 250 },
        { field: 'description', header: 'Description', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 300 },
        { field: 'eicCodeTypeObj', header: 'EIC Code Type', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 150 },
        { field: 'lioNumber', header: 'LIO Code', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 100 },
        { field: 'eicParent', header: 'EIC Parent', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 300 },
        { field: 'responsibleParty', header: 'Responsible Party', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 300 },
        { field: 'acerNumber', header: 'ACER Number', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 150 },
        { field: 'address', header: 'Address', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 300 },
        { field: 'postalCode', header: 'Postal Code', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 300 },
        { field: 'city', header: 'City', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 300 },
        { field: 'country', header: 'Country', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 300 },
        { field: 'contactDetailsName', header: 'Contact Details Name', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 300 },
        { field: 'contactDetailsEmail', header: 'Contact Details Email', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 300 },
        { field: 'contactDetailsPhoneNumber', header: 'Contact Details Phone', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 300 },
        { field: 'functions', header: 'Functions', visible: false, activeStatus: false, sort: true, filter: true, minWidth: 300 },
    ];
    
    allColumns = [...this.defaultColumns, ...this.additionalColumns];
    
}
  