import { KeycloakService } from 'keycloak-angular'
import { environment } from 'environments/environment'
import { Router } from '@angular/router'

export function initializeKeycloak(keycloak: KeycloakService, router: Router) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak.url,
        realm: 'CIO-LIO',
        clientId: environment.keycloak.clientId
      },
      initOptions: {
        checkLoginIframe: true,
        onLoad: 'login-required',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
      }
    }).catch(error => {
        router.navigate(['authentication-issue']);
    })
}
