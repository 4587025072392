import { SelectItem } from "primeng/api"
import { StatusObj } from "../request.interface"

export enum RequestSourceEnum{
    Form = 0,
    XML_Upload = 1,
    SFTP = 2,
    API = 3
}

export const requestSourceList: SelectItem[] = [
    {label: "Form", value: 0},
    {label: 'XML_Upload', value: 1},
    {label: 'SFTP', value: 2},
    {label: 'API', value: 3}
]

export const requestSourceFilterOptions : StatusObj[] = [
    {label: 'Form', value: 0},
    {label: 'XML_Upload', value: 1},
    {label: 'SFTP', value: 2},
    {label: 'API', value: 3}
  ]
