import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { RequestInterface } from '@app/shared/types/request.interface'
import { Store, select } from '@ngrx/store'
import * as RequestActions from '@app/store/actions/request.actions'
import { FunctionStateInterface } from '@app/shared/types/function.state.interface'
import { functionsSelector } from '@app/store/selectors/function.selector'
import { FunctionInterface } from '@app/shared/types/function.interface'
import { Actions, ofType } from '@ngrx/effects'
import { Observable, Subject, takeUntil } from 'rxjs'
import { BulkRequestsInterface } from '@app/shared/types/bulk-requests.interface'
import { bulkRequestsStatusSelector } from '@app/store/selectors/request.selector'
import { CreateRequestCommand } from '@app/shared/types/commands/createRequestCommand.model'
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface'
import { BulkRequestStatus } from '@app/shared/types/bulk-request-status-interface'

@Component({
  selector: 'app-modal-upload-xml-document',
  templateUrl: './modal-upload-xml-document.component.html',
  styleUrls: ['./modal-upload-xml-document.component.scss']
})
export class ModalUploadXmlDocumentComponent implements OnInit, OnDestroy {
  @ViewChild('fileUpload') fileUpload: any
  visible: boolean = false
  requests: CreateRequestCommand[] = []
  xmlFiles: string[] = [
    'eic-codes-Active.xml',
    'eic-codes-Inactive.xml',
    'eic-codes-Request_Creation.xml',
    'eic-codes-Request_Deactivation.xml',
    'eic-codes-Request_Update.xml',
    'eic-codes-Request_Reactivate.xml'
  ]
  xsdFiles: string[] = [
    'iec62325-451-n-eiccode_v1_2.xsd',
    'urn-entsoe-eu-local-extension-types.xsd',
    'urn-entsoe-eu-wgedi-codelist.xsd'
  ]
  selectedContent: string
  destroyed$ = new Subject<boolean>()
  bulkRequests: BulkRequestsInterface

  bulkRequestsStatus$: Observable<BulkRequestStatus[] | null>
  bulkRequestsStatus: BulkRequestStatus[] | null
  uploadResultVisibility: boolean = false

  @Input() lioNumber: number
  @Input() tableFilterOrSortOptions: TableFilteringSortingOptions
  allFunctions: FunctionInterface[]

  constructor(
    private store: Store<RequestInterface[]>,
    private functionStore: Store<FunctionStateInterface>,
    private updates$: Actions
  ) {
    this.functionStore.select(functionsSelector).subscribe((fct) => (this.allFunctions = fct))
  }

  ngOnInit() {}

  showDialog({ lioNumber, tableFilterOrSortOptions }: any) {
    this.lioNumber = lioNumber
    this.tableFilterOrSortOptions = tableFilterOrSortOptions
    this.visible = true
  }

  onFileSelected(event: any) {
    const file: File = event.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        const xmlData = e.target.result
        this.bulkRequests = {
          xmlRequests: xmlData,
          lioNumber: this.lioNumber
        }
      }
      reader.readAsText(file)
    }
  }

  onSubmit(fileUpload) {

    this.uploadResultVisibility = true

    this.store.dispatch(RequestActions.createRequests({ bulkRequests: this.bulkRequests }))

    this.bulkRequestsStatus$ = this.store.pipe(select(bulkRequestsStatusSelector))
    this.bulkRequestsStatus$.subscribe((status) => {
      this.bulkRequestsStatus = status
    })

    this.updates$
      .pipe(ofType(RequestActions.CREATE_REQUESTS_SUCCESSFUL), takeUntil(this.destroyed$))
      .subscribe(() => {
        this.store.dispatch(
          RequestActions.getRequestsOfLio({ lioNumber:this.lioNumber, tableOptions: this.tableFilterOrSortOptions })
        )
        fileUpload.clear()
      })
  }

  openFile(fileName: string, fileType: string) {
    if (fileType == 'xml') {
      this.selectedContent = require(
        `!!raw-loader!/src/assets/xml/sample-data-files/${fileName}`
      ).default
    } else if (fileType == 'xsd') {
      this.selectedContent = require(
        `!!raw-loader!/src/assets/xml/xsd-templates/${fileName}`
      ).default
    }

    const blob = new Blob([this.selectedContent], { type: 'text/xml' })
    const url = URL.createObjectURL(blob)
    window.open(url, '_blank')
    URL.revokeObjectURL(url)
  }

  onClose() {
    this.visible = false
    this.uploadResultVisibility = false
    this.bulkRequestsStatus = []
    this.fileUpload.clear()
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
