import { createFeatureSelector, createSelector } from "@ngrx/store";
import { EICTpHistoryStateInterface } from "@app/shared/types/eic-tp-history-state.interface";

export const exportSelector = createSelector(
    createFeatureSelector('export'),
    (state: EICTpHistoryStateInterface) => { 
        return state.eicsTpHistory
    }
)
export const isLoadingSelector = createSelector(
    createFeatureSelector('export'),
    (state: EICTpHistoryStateInterface) => { 
        return state.isLoading
    }
)
export const errorSelector = createSelector(
    createFeatureSelector('export'),
    (state: EICTpHistoryStateInterface) => { 
        return state.error
    }
)
export const totalRecordsSelector = createSelector(
    createFeatureSelector('export'),
    (state: EICTpHistoryStateInterface) => {
        return state.totalRecords
    }
)
export const pageNumberSelector = createSelector(
    createFeatureSelector('export'),
    (state: EICTpHistoryStateInterface) => {
        return state.pageNumber
    }
)
export const rowsPerPageSelector = createSelector(
    createFeatureSelector('export'),
    (state: EICTpHistoryStateInterface) => {
        return state.rowsPerPage
    }
)