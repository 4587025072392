import { SelectItem } from "primeng/api"
import { RequestActionObj } from '@app/shared/types/request.interface'

export enum RequestActionEnum{
    Create = 0,
    Update = 1,
    Deactivate = 2,
    Reactivate = 3
}

export const requestActionsList: SelectItem[] = [
    {label: "Create", value: 0, icon:'plus', title: "creation"},
    {label: "Update", value: 1, icon:'update', title: "update"},
    {label: "Deactivate", value: 2, icon:'min', title: "deactivation"},
    {label: "Reactivate", value: 3, icon:'reactivate', title: "reactivation"}
]

export const requestActionFilterOptions : RequestActionObj[] = [
    {label: "Create", value: 0},
    {label: "Update", value: 1},
    {label: "Deactivate", value: 2},
    {label: "Reactivate", value: 3}
  ]