<p-sidebar
  [visible]="true"
  [showCloseIcon]="false"
  styleClass="static flex-1"
  [modal]="false"
  transitionOptions="0s"
>
  <p-menu [model]="defaultItems" />

  <div>
    <div class="menuTitle" (click)="toggleSubMenu($event)">
        <label> {{ (currentUser$ | async).isLio10 ? 'CIO admin' : 'LIO ' }} </label>
        <i class="icon icon--angle-down" ></i>
    </div>
    <p-menu [model]="userItems" />
  </div>

  <div *ngIf="(currentUser$ | async).isImpersonating">
    <div class="menuTitle" (click)="toggleSubMenu($event)">
        <label>LIO {{ (currentUser$ | async).lioNumber }}</label>
        <i class="icon icon--angle-down" ></i>
    </div>
    <p-menu [model]="impersonationItems" />
  </div>

  <div>
    <div class="menuTitle" (click)="toggleSubMenu($event)">
        <label>Reports</label>
        <i class="icon icon--angle-down" ></i>
    </div>
    <p-menu [model]="reportsItems" />
  </div>

  <div *ngIf="(currentUser$ | async).isLio10">
    <div class="menuTitle" (click)="toggleSubMenu($event)">
        <label>Exports</label>
        <i class="icon icon--angle-down" ></i>
    </div>
    <p-menu [model]="exportsItems" />
  </div>

</p-sidebar>

