import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CountryList } from '@app/shared/types/enums/country-list.enum';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {
    countries = CountryList;
    selectedCountry: string;

    @Input() public form: FormGroup;

    ngOnInit() {}

    getFullCountryName() {
      var country = this.countries.filter(country => {
        return country.value === this.selectedCountry
      });
      if(country.length > 0){
        return country[0].label + " (" + country[0].value + ")";
      }
      return "";
    }
}
