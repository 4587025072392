import { Component } from '@angular/core'

@Component({
  selector: 'app-modal-invite',
  templateUrl: './modal-invite.component.html',
  styleUrls: ['./modal-invite.component.scss']
})
export class ModalInviteComponent {
  visible: boolean = false

  showDialog() {
    this.visible = true
  }
}
