<p-fieldset styleClass="mb-5">
  <h2 style="color:rgb(38, 51, 110)" class="mb-5">Functions<span class="text-required">*</span></h2>
  <section >
    <div class="grid"
    [ngClass]="{'invalid-input': noFunctionSelected && functionsControls.controls?.[0]?.touched}"
    >
      <div *ngFor="let functionForm of functionsControls.controls; let i = index;" class="col-2 p-form-check">
        <div [formGroup]="functionForm">
          <p-checkbox
            [binary]="true"
            formControlName="isSelected"
            checkboxIcon="icon icon--checkmark"
          ></p-checkbox>
          <label class="ml-3">{{functionForm.get('functionName').value}}</label>
        </div>
      </div>
      <div *ngIf="functionsControls.controls.length == 0">
        <p-messages
          [(value)]="messages"
          [enableService]="false"
          [closable]="false"
          styleClass="p-message mt-4"
        />
      </div>       
    </div>
    <span *ngIf="noFunctionSelected && functionsControls.controls?.[0]?.touched" class="mt-5 p-error"> 
      At least one function must be selected!
    </span>    
  </section>
</p-fieldset>
