import { createFeatureSelector, createSelector } from '@ngrx/store'
import { LioStateInterface } from '@app-shared-types/lio-state.interface'

export const liosSelector = createSelector(
  createFeatureSelector('lios'),
  (state: LioStateInterface) => {
    return state.lios
  }
)

export const getLioByNumber = (lioNumber: number) =>
  createSelector(liosSelector, (lios) => lios.find((r) => r.lioNumber == lioNumber))

export const isLoadingSelector = createSelector(
  createFeatureSelector('isLoading'),
  (state: LioStateInterface) => {
    return state.isLoading
  }
)
export const errorSelector = createSelector(
  createFeatureSelector('error'),
  (state: LioStateInterface) => {
    return state.error
  }
)
export const totalRecordsSelector = createSelector(
  createFeatureSelector('lios'),
  (state: LioStateInterface) => {
      return state.totalRecords
  }
)
export const pageNumberSelector = createSelector(
  createFeatureSelector('lios'),
  (state: LioStateInterface) => {
      return state.pageNumber
  }
)
export const rowsPerPageSelector = createSelector(
  createFeatureSelector('lios'),
  (state: LioStateInterface) => {
      return state.rowsPerPage
  }
)
