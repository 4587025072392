import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class DateService{
    constructor() {}

    formatDateToCET(date: string){
        if(date != null){
            const offset =  moment['tz'](moment(new Date()), 'Europe/Berlin').utcOffset() * 60000;
            var berlinDateTime = new Date(new Date(date).getTime() + offset);
          
            date = moment(berlinDateTime).format("ddd, MMM DD, y, h:mm A");
          }
          return date
    }
}