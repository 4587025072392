import { EICInterface } from '@app/shared/types/eic.interface';
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface';
import { PaginatedResult } from '@app/shared/types/paginated-result.interface';
import { createAction, props } from '@ngrx/store';

export const DOWNLOAD_TP = '[Download TP Overview Component] Download tp xml';
export const DOWNLOAD_TP_SUCCESSFUL = '[Download TP Overview Component] Download tp xml success';
export const DOWNLOAD_TP_FAILED = '[Download TP Overview Component] Download tp xml failed';

export const EXPORT_TO_TP = '[Export to TP Overview Component] Export to tp xml';
export const EXPORT_TO_TP_SUCCESSFUL = '[Export to TP Overview Component] Export to tp xml success';
export const EXPORT_TO_TP_FAILED = '[Export to TP Overview Component] Export to tp xml failed';

export const GET_EICS_TP_HITSORY = '[EIC TP History Overview Component] Get EICs and tp history';
export const GET_EICS_TP_HITSORY_SUCCESSFUL = '[EIC TP History Overview Component] Get EICs and tp history success';
export const GET_EICS_TP_HITSORY_FAILED = '[EIC TP History Overview Component] Get EICs and tp history failed';

export const downloadTP = createAction(
    DOWNLOAD_TP,
    props<{ eicId: number }>()
);
export const downloadTPSuccessful = createAction(
    DOWNLOAD_TP_SUCCESSFUL,
    props<{export: any}>()
);
export const downloadTPFailed = createAction(
    DOWNLOAD_TP_FAILED,
    props<{error: string}>()
);

export const exportToTP = createAction(
    EXPORT_TO_TP,
    props<{ eicIds: any }>()
);
export const exportToTPSuccessful = createAction(
    EXPORT_TO_TP_SUCCESSFUL,
    props<{export: any}>()
);
export const exportToTPFailed = createAction(
    EXPORT_TO_TP_FAILED,
    props<{error: string}>()
);

export const getEICsTpHistory = createAction(
    GET_EICS_TP_HITSORY,
    props<{ tableOptions: TableFilteringSortingOptions }>()
);

export const getEICsTpHistorySuccessfully = createAction(
    GET_EICS_TP_HITSORY_SUCCESSFUL,
    props<{ paginatedResult: PaginatedResult<EICInterface> }>()
);

export const getEICsTpHistoryFailed = createAction(
    GET_EICS_TP_HITSORY_FAILED,
    props<{error: string}>()
);