<p-dialog
  [header]="'Update lio information'"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  closeIcon="icon icon--close"
>
  <ng-template pTemplate="header">
    <h2 class="h5">Update lio information</h2>
  </ng-template>

  <div class="container" style="margin-top: 20px;" *ngIf="visible">
    <app-lio 
        [lio]="sendLio"
        (onCloseUpdateModal)="onClose()"
    />
  </div>

</p-dialog>