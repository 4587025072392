<div class="p-24px bg-white border-round">
  <div class="flex justify-content-between mb-6">
    <h1 class="mb-3 h3 text-secondary">My EIC codes requests</h1>
  </div>
  
  <app-create-request #modalCreateRequestComponent />
  <app-modal-upload-xml-document #modalUploadXMLDocumentComponent />
  
  <app-table
    [values]="requests"
    [visibilityColumnsHeader]="true"
    [visibilityColumnsHeaderObj]="visibilityColumnsHeaderObj"
    [settingType]="settingType"
    [checkBoxMultiSelect]="true"
    [requestActionsList]="requestActionsList"
    [statusList]="requestStatusList"
    [requestSourceList]="requestSourceList"
    [requestActionFilterOptions]="requestActionFilterOptions"
    [statusFilterOptions]="requestStatusFilterOptions"
    [requestSourceFilterOptions]="requestSourceFilterOptions"
    [eicCodeTypesOptions]="eicCodeTypesOptions"
    [checkBoxMultiSelect]="false"    
    [selectionMode]="'multiple'"
    [totalRecords]="totalRecords"
    [firstRow]="firstRow"
    (onPageChange)="onPageChange($event)"
    (onRowSelect)="redirectToItem($event)"
    [filterOptions]="tableFilterOrSortOptions"
    [isMyRequestPage]="true"
    (onXmlUpload)="openXmlUploadModal()"
    (onCreate)="openCreateModal()"
    
  >
  </app-table>
</div>
