import { ExportPlace } from "./enums/export-place.enum"

export class ExportInterface{
    exportType?: string;
    exportPlace: ExportPlace;
    data: any;
    archived: boolean = false;
    fileName: string;
    zipFileName: string;
    sheetName: string;
}