<div class="p-24px bg-white border-round">
  <div class="flex flex-column mb-5">
    <h1 class="h3 text-secondary">All EIC codes</h1>
    <h5>Report is created on {{ today | date: 'd/M/YYYY' }}</h5>
  </div>

  <app-table
    [values]="allEics"
    [visibilityColumnsHeader]="true"
    [visibilityColumnsHeaderObj]="visibilityColumnsHeaderObj"
    [settingType]="settingType"
    [requestActionFilterOptions]="requestActionFilterOptions"
    [statusFilterOptions]="eicStatusFilterOptions"
    [requestActionsList]="requestActionsList"
    [statusList]="eicStatusList"
    [eicCodeTypesOptions]="eicCodeTypesOptions"
    [requestSourceFilterOptions]="requestSourceFilterOptions"
    [checkBoxMultiSelect]="false"
    export="true"
    [exportPlace]="exportPlace"
    [exportOptions]="exportOptions"
    [totalRecords]="totalRecords"
    [firstRow]="firstRow"
    (onPageChange)="onPageChange($event)"
    [filterOptions]="exportOptionColumns"
  >
  </app-table>
</div>
