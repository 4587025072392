import { Component, OnDestroy, OnInit } from '@angular/core';
import { EICInterface } from '@app/shared/types/eic.interface';
import { eicCodeTypesList } from '@app/shared/types/enums/eic-code-type.enum';
import { eicStatusFilterOptions, eicStatusList } from '@app/shared/types/enums/eic-status.enum';
import { ExportPlace } from '@app/shared/types/enums/export-place.enum';
import { requestActionFilterOptions, requestActionsList } from '@app/shared/types/enums/request-action.enum';
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum';
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface';
import { VisibilityColumnsHeader } from '@app/shared/types/visibility-columns-header.interface';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { EICPartsInterface } from '@app/shared/types/eic-parts.interface';
import { EICService } from '@app/shared/services/eic.service';
import { RequestInterface } from '@app/shared/types/request.interface';
import { EICStateInterface } from '@app/shared/types/eic-state.interface';
import * as exportActions from '@app-store/actions/export.actions'
import { exportSelector } from '@app/store/selectors/export.selector';
import { ActivatedRoute, Router } from '@angular/router';
import {  totalRecordsSelector } from '../../../store/selectors/export.selector'

@Component({
  selector: 'app-tp-exports',
  templateUrl: './tp-exports.component.html',
  styleUrls: ['./tp-exports.component.scss']
})
export class TpExportsComponent  implements OnInit, OnDestroy {
  today: number = Date.now();
  settingType: number;
  allEics$: Observable<EICInterface[]>;
  allEics: EICInterface[];
  eicSub: Subscription;
  report$: Observable<EICInterface[]>;
  visibilityColumnsHeaderObj: VisibilityColumnsHeader = new VisibilityColumnsHeader();

  exportOptionColumns: TableFilteringSortingOptions = new TableFilteringSortingOptions();
  exportPlace: ExportPlace = ExportPlace.TpExport;

  requestActionFilterOptions = requestActionFilterOptions;
  eicStatusFilterOptions = eicStatusFilterOptions;
  requestActionsList = requestActionsList;
  eicStatusList = eicStatusList;
  eicCodeTypesOptions = eicCodeTypesList;

  selectedRows: any;

  pageNumber: number | undefined = 0;
  rowsPerPage: number | undefined = 10;
  tableOptions: TableFilteringSortingOptions
  loading: boolean = false;

  totalRecords$: Observable<number>;
  totalRecords: number;
  firstRow: number = 0;
  querySub: Subscription
  recordSub: Subscription

  tableFilterOrSortOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions();

  constructor(private store: Store<EICStateInterface>, 
              private eicService: EICService,
              private route: ActivatedRoute,
              private router: Router) {
		this.allEics$ = this.store.pipe(select(exportSelector)); 
    this.totalRecords$ = this.store.pipe(select(totalRecordsSelector));   
	}

  ngOnInit(): void {
    let paramsOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions()
    this.querySub = this.route.queryParams.subscribe((params) => {
      Object.keys(params).forEach((key) => {
        if (params[key] != null) {
          switch (key) {
            case 'requestAction':
              if (params[key] != undefined) {
                paramsOptions[key] = ""
                  params[key].split(",").forEach(requestActionLabel => {
                    paramsOptions[key] += requestActionsList.find((item) => item.label == requestActionLabel)?.value + ","
                  })
                  paramsOptions[key] = paramsOptions[key]?.slice(0, -1)
              }
              break
            case 'status':
              if (params[key] != undefined) {
                paramsOptions[key] = ""
                  params[key].split(",").forEach(eicStatusLabel => {
                    paramsOptions[key] += eicStatusList.find((item) => item.label == eicStatusLabel)?.value + ","
                  })
                  paramsOptions[key] = paramsOptions[key]?.slice(0, -1)
              }
              break
            default:
              paramsOptions[key] = params[key]
              break
          }
        }
      })
    })
    this.pageNumber = paramsOptions.pageNumber
    this.rowsPerPage = paramsOptions.rowsPerPage
    
    if (!this.pageNumber || !this.rowsPerPage) {
      this.pageNumber = 0;
      this.rowsPerPage = 10;
    }

    this.firstRow = this.rowsPerPage * this.pageNumber

    this.tableFilterOrSortOptions.pageNumber = this.pageNumber
    this.tableFilterOrSortOptions.rowsPerPage = this.rowsPerPage

    this.recordSub = this.totalRecords$.subscribe(total => {
      this.totalRecords = total
    })

    Object.keys(paramsOptions).forEach((key) => {
      if (paramsOptions[key] != null) {
        this.tableFilterOrSortOptions[key] = paramsOptions[key]
      }
    })

    this.getData(this.tableFilterOrSortOptions)

    this.visibilityColumnsHeaderObj.optionDisabled = "activeStatus";
    this.settingType = SettingsTypes.tpExports;
  };

  getEICVersions(eic: EICInterface){
    if(eic.versions == null){

      let eicParts: EICPartsInterface = {
        lioNumber: eic.lioNumber,
        eicCodeType: eic.eicCodeTypeObj.value,
        eicRandomSequence: eic.eicRandomSequence,
        eicCheckKey: eic.eicCheckKey
      }
      
      this.eicService.getAllVersionsofEIC(eicParts).subscribe(data => {
        eic.versions = data          
          .map(item  => {
            var version = Object.assign({}, item, {
              statusCode: eic.status,
              requestFunctions: this.getRequestFunctions(item)
            })
            return version;
        })
      })
    }
  }

  getRequestFunctions(request: RequestInterface){
    return request.functions.map(r => r.name)
  }

  ngOnDestroy(): void {
    this.eicSub.unsubscribe();
  }

  onRowChange(event: any) {
    this.selectedRows = event;
  }

  getData(tableOptions: TableFilteringSortingOptions){
    this.store.dispatch(exportActions.getEICsTpHistory({ tableOptions: tableOptions }));

    this.eicSub = this.allEics$.subscribe(eics => {
      this.allEics = eics.map((item) => {
        var eic = Object.assign({}, item,
          {
            statusObj: {
              label: eicStatusList[item.status].label,
              value: item.status
            },
            requestActionObj: {
              label: requestActionsList[item.requestAction].label,
              value: item.requestAction
            },
            eicCodeTypeObj: {
              label: eicCodeTypesList.filter(obj => { return obj.value == item.eicCodeType})[0].label,
              value: item.eicCodeType
            }
          })
        eic.requestDate = new Date(item.requestDate)
        return eic
      })
    });
    this.setQueryParams();
  }

  onPageChange(tableFilterOrSortOptions: TableFilteringSortingOptions) {

    this.pageNumber = tableFilterOrSortOptions.pageNumber
    this.rowsPerPage = tableFilterOrSortOptions.rowsPerPage

    let props = tableFilterOrSortOptions;
    Object.keys(props).forEach(function (key) {
        if(props[key] == null){
          delete props[key];
        }
    });

    this.tableFilterOrSortOptions = props

    this.getData(props)
  }

  setQueryParams() {
    let queryParams: TableFilteringSortingOptions = new TableFilteringSortingOptions()

    Object.keys(this.tableFilterOrSortOptions).forEach((key) => {
      if (this.tableFilterOrSortOptions[key] != null) {
        if (key != 'sortBy') {
          switch (key) {
            case 'requestAction':
              if (this.tableFilterOrSortOptions[key] != undefined) {
                queryParams[key] = ""
                this.tableFilterOrSortOptions[key]?.split(",").forEach(requestAction => {
                  queryParams[key] += requestActionsList[requestAction].label + ','
                })
                queryParams[key] = queryParams[key]?.slice(0, -1)
              }
              break
            case 'status':
              if (this.tableFilterOrSortOptions[key] != undefined) {
                queryParams[key] = ""
                this.tableFilterOrSortOptions[key]?.split(",").forEach(eicStatus => {
                  queryParams[key] += eicStatusList[eicStatus].label + ','
                })
                queryParams[key] = queryParams[key]?.slice(0, -1)
              }
              break
            default:
              queryParams[key] = this.tableFilterOrSortOptions[key]
              break
          }
        }
      }
    })

    this.router.navigate([], { relativeTo: this.route, queryParams: queryParams })
  }
}
