import { Component, OnDestroy, OnInit } from '@angular/core';
import { LioDocument } from '@app/shared/types/lio-document';
import { LioStateInterface } from '@app/shared/types/lio-state.interface';
import { Store } from '@ngrx/store';
import * as LioActions from '@app/store/actions/lio.actions';

@Component({
  selector: 'app-modal-delete-lio-document',
  templateUrl: './modal-delete-lio-document.component.html',
  styleUrls: ['./modal-delete-lio-document.component.scss']
})
export class ModalDeleteLioDocumentComponent implements OnInit, OnDestroy{
  visible: boolean = false;
  lioDocument: LioDocument;

  constructor(private lioStore: Store<LioStateInterface>){}

  ngOnInit() {}

  showDialog(document : LioDocument){
    this.lioDocument = document;
    this.visible = true;
  }

  onClose() {
    this.visible = false
  }

  onSubmit() {
    this.lioStore.dispatch(LioActions.deleteLioDocument({documentId : this.lioDocument.id}))
    this.visible = false
  }

  ngOnDestroy() {}
}
