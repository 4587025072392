import { MaintanenceStateInterface } from "@app/shared/types/maintenance.interface";
import { createReducer, on } from "@ngrx/store";
import * as MaintenanceActions from '../actions/maintenance.actions';

const initialState: MaintanenceStateInterface = {
    isMaintenance: false
}

export const maintenanceReducer = createReducer(
    {...initialState},
    on(MaintenanceActions.updateMaintenance, (state, action) => ({
        ...state,
        isMaintenance: action.isMaintenance
    }))
)