import { createReducer, on } from '@ngrx/store';
import * as exportActions from '../actions/history.actions';
import { TpHistoryStateInterface } from '@app/shared/types/tp-history-state.interface';

export const initialState: TpHistoryStateInterface = {
    isLoading: false,
	tpHistory: [],
	error: null,
	pageNumber: 0,
	rowsPerPage: 10,
	totalRecords: 0
};

export const historyReducer = createReducer(
    { ...initialState },
    on(exportActions.getHistory, (state) => ({
		...state,
		isLoading: true 
	})),
	on(exportActions.getHistorySuccessful, (state, action) => ({
		...state,
		isLoading: false,
		tpHistory: action.paginatedResult.entities,
		totalRecords: action.paginatedResult.totalRecords,
		pageNumber: action.paginatedResult.pageNumber,
		rowsPerPage: action.paginatedResult.rowsPerPage
	})),
	on(exportActions.getHistoryFailed, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	})),
);
	