import { createFeatureSelector, createSelector } from "@ngrx/store";
import { EICStateInterface } from "@app-shared-types/eic-state.interface";

export const eicsSelector = createSelector(
    createFeatureSelector('eics'),
    (state: EICStateInterface) => { 
        return state.eics
    }
)
export const isLoadingSelector = createSelector(
    createFeatureSelector('isLoading'),
    (state: EICStateInterface) => { 
        return state.isLoading
    }
)
export const errorSelector = createSelector(
    createFeatureSelector('error'),
    (state: EICStateInterface) => { 
        return state.error
    }
)
export const totalRecordsSelector = createSelector(
    createFeatureSelector('eics'),
    (state: EICStateInterface) => {
        return state.totalRecords
    }
)
export const pageNumberSelector = createSelector(
    createFeatureSelector('eics'),
    (state: EICStateInterface) => {
        return state.pageNumber
    }
)
export const rowsPerPageSelector = createSelector(
    createFeatureSelector('eics'),
    (state: EICStateInterface) => {
        return state.rowsPerPage
    }
)