import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { UserInfoStateInterface } from '@app/shared/types/user-info-state.interface'
import { requiredIfCodeIsXValidator } from '@app/shared/validators/requiredIfCodeIsXValidator'
import { requiredIfIsLio10Validator } from '@app/shared/validators/requiredIfIsLio10Validator'
import { responsiblePartyValidator } from '@app/shared/validators/responsiblePartyValidator'
import { getLioNumber } from '@app/store/selectors/user-info.selector'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-eic-identification',
  templateUrl: './eic-identification.component.html',
  styleUrls: ['./eic-identification.component.scss']
})
export class EicIdentificationComponent implements OnInit, OnDestroy {
  @Input() requestForm: FormGroup
  @Input() formType: string

  formSub?: Subscription
  minDate: Date
  actingLioNumber: number
  codeType: string = 'A'

  requiredIfCodeIsXValidator = requiredIfCodeIsXValidator
  responsiblePartyValidator = responsiblePartyValidator
  requiredIfIsLio10Validator = requiredIfIsLio10Validator

  constructor(private userStore: Store<UserInfoStateInterface>) {
    this.userStore
      .select(getLioNumber())
      .subscribe((lioNumber) => (this.actingLioNumber = lioNumber))
  }

  ngOnInit() {
    this.minDate = new Date()

    this.minDate.setMonth(this.minDate.getMonth() + 2)

    this.formSub = this.requestForm.get('eicCodeType')?.valueChanges.subscribe(() => {
      this.codeType = this.requestForm.get('eicCodeType')?.value
      this.requestForm.get('euvatCode')?.updateValueAndValidity()
      this.requestForm.get('responsibleParty')?.updateValueAndValidity()
      this.requestForm.get('address')?.updateValueAndValidity()
      this.requestForm.get('postalCode')?.updateValueAndValidity()
      this.requestForm.get('city')?.updateValueAndValidity()
      this.requestForm.get('function')?.updateValueAndValidity()
    })
  }

  ngOnDestroy() {
    this.formSub?.unsubscribe()
  }

  onSelectDate(date) {
    if(date != null) {
      const offset = new Date().getTimezoneOffset();
      const hour = offset / 60;
      var d = new Date(date);
      d.setHours(0,0,0,0);
      d.setHours(d.getHours() - hour);
      this.requestForm.controls['deactivationDate'].setValue(d);
    }
    return null;
  }
}
