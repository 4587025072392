<p-table [value]="requests$ | async">
  <ng-template pTemplate="header">
    <tr>
      <th>EIC Code</th>
      <th>EIC longname</th>
      <th>Request Action</th>
      <th *ngIf="isLioPage">Status</th>
      <th>Request date</th>
    </tr>
  </ng-template>


  <ng-template pTemplate="body" let-request>

    <tr class="request-line" [routerLink]="isLioPage ? ('lio/'+lioNumber+'/requests') : ('cio/requests/' + request.id)">
      <td>{{ request.eicCode }}</td>
      <td>{{ request.eicLongName }}</td>
      <td>{{ requestActions[request.requestAction].label }}</td>

      <td *ngIf="isLioPage">
        <p-tag [severity]="requestStatuses[request.status].icon" [value]="requestStatuses[request.status].label" />
      </td>

      <td><time [attr.datetime]="request.requestDate">{{ request.requestDate+'Z' |date: 'EE, MMM d, y, h:mm a' }}</time>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td *ngIf="isLioPage else noPendingRequests">
        No EIC codes latest requests.
      </td>
      <ng-template #noPendingRequests>
        <td>
          No EIC codes pending requests.
        </td>
      </ng-template>

    </tr>
</ng-template>
</p-table>