<div>
  <app-modal-change-status #modalChangeStatusComponent />
  <p-button
    label="back"
    (click)="goToPrevious()"
    icon="icon icon--arrow-left"
    styleClass="p-button-outlined p-button-secondary mb-3"
    [outlined]="true" severity="secondary"
  ></p-button>
  <section class="p-24px bg-white border-round" *ngIf="request">
    <div class="grid">
      <div class="col-6 flex align-items-center justify-content-between mb-3">
        <div class="flex align-items-center gap-5">
          <h1 class="h3 text-secondary">
            Request for {{ requestActionsList[request.requestAction].title }}
          </h1>
          <p-tag
            [severity]="requestStatusList[request.status].icon"
            [value]="requestStatusList[request.status].label"
          ></p-tag>
        </div>
        <div
          *ngIf="request.status == RequestStatusEnum.Pending && currentUser.isLio10"
          class="flex align-items-center gap-3"
        >
          <p-button
            label="Reject"
            styleClass="p-button-danger"
            (onClick)="
              modalChangeStatusComponent.showDialog({
                eicCodes: [request.eicCode],
                requestIds: [request.id],
                selectedAction: PendingRequestActionEnum.Reject,
                title: 'Reject request',
                subTitle: 'Do you want to reject following requests:',
                label: 'Reject'
              })
            "
          />
          <p-button
            label="Approve"
            styleClass="p-button-"
            (click)="
              modalChangeStatusComponent.showDialog({
                eicCodes: [request.eicCode],
                requestIds: [request.id],
                selectedAction: PendingRequestActionEnum.Approve,
                title: 'Approve request',
                subTitle: 'Do you want to approve following request:',
                label: 'Approve'
              })
            "
          />
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-6">
        <div class="description-block--full">
          <p-card [header]="request.eicCode">
            <div class="flex align-items-center gap-3">
              <div *ngIf="canUpdate">
                <app-update-request #modalUpdateRequestComponent />
                <p-button
                  [label]="getButtonLabel()"
                  icon="icon icon--edit"
                  styleClass="p-button-outlined p-button-secondary mb-5"
                  [outlined]="true" severity="secondary"
                  (onClick)="modalUpdateRequestComponent.show(request)"
                ></p-button>
              </div>
            </div>

            <ul
              *ngIf="request.status == RequestStatusEnum.Rejected"
              class="description-list list-none mb-5"
            >
              <li class="description-list__row description-list__row-rejected">
                <p>Reason of rejection:</p>
                <p>{{ request.approvalReason }}</p>
              </li>
            </ul>

            <ul
              *ngIf="request.requestAction == RequestActionEnum.Deactivate"
              class="description-list list-none mb-5"
            >
              <li class="description-list__row description-list__row-rejected">
                <p>Deactivation date:</p>
                <p>
                  {{
                    formatDateToCET(request.deactivationDate) ?? '-'
                  }}
                </p>
              </li>
            </ul>

            <ul class="description-list w-60rem">
              <li *ngIf="requestDiff" style="font-weight: bold" class="description-list__row">
                <p></p>
                <p>Update</p>
                <p>Previous</p>
              </li>
              <li class="description-list__row">
                <p>Request number:</p>
                <p>{{ request.id ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">{{ requestDiff.id ?? '-' }}</p>
              </li>
              <li class="description-list__row">
                <p>EIC Code:</p>
                <p>{{ request.eicCode ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">{{ requestDiff.eicCode ?? '-' }}</p>
              </li>
              <li class="description-list__row">
                <p>EIC Long Name:</p>
                <p>{{ request.eicLongName ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">{{ requestDiff.eicLongName ?? '-' }}</p>
              </li>
              <li class="description-list__row">
                <p>EIC Display Name:</p>
                <p>{{ request.eicDisplayName ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">
                  {{ requestDiff.eicDisplayName ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>ACER number:</p>
                <p>{{ request.acerNumber ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">{{ requestDiff.acerNumber ?? '-' }}</p>
              </li>
              <li class="description-list__row">
                <p>EU VAT Code:</p>
                <p>{{ request.euvatCode ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">{{ requestDiff.euvatCode ?? '-' }}</p>
              </li>
              <li class="description-list__row">
                <p>EIC Parent:</p>
                <p>{{ request.eicParent ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">{{ requestDiff.eicParent ?? '-' }}</p>
              </li>
              <li class="description-list__row">
                <p>Responsible party:</p>
                <p>{{ request.responsibleParty ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">
                  {{ requestDiff.responsibleParty ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>Status:</p>
                <p>{{ requestStatusList[request.status].label ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">
                  {{ requestStatusList[requestDiff.status]?.label ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>Request Action:</p>
                <p>{{ requestActionsList[request.requestAction].label ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">
                  {{ requestActionsList[requestDiff.requestAction]?.label ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>Request date:</p>
                <p>{{ formatDateToCET(request.requestDate) ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">
                  {{ formatDateToCET(requestDiff.requestDate) ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>Creation date:</p>
                <p>{{ formatDateToCET(request.creationDate) ?? '-' }}
                </p>
                <p *ngIf="requestDiff" class="text-light">
                  {{ formatDateToCET(requestDiff.creationDate) ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>Deactivation date:</p>
                <p>{{ formatDateToCET(request.deactivationDate) ?? '-' }}
                </p>
                <p *ngIf="requestDiff" class="text-light">
                  {{ formatDateToCET(requestDiff.deactivationDate) ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>Update date:</p>
                <p>{{ formatDateToCET(request.updateDate) ?? '-' }}
                </p>
                <p *ngIf="requestDiff" class="text-light">
                  {{ formatDateToCET(requestDiff.updateDate) ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>Approval date:</p>
                <p>{{ formatDateToCET(request.approvalDate) ?? '-' }}
                </p>
                <p *ngIf="requestDiff" class="text-light">
                  {{ formatDateToCET(requestDiff.approvalDate) ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>Approval reason:</p>
                <p>{{ request.approvalReason ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">
                  {{ requestDiff.approvalReason ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>Address:</p>
                <p>{{ request.address ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">{{ requestDiff.address ?? '-' }}</p>
              </li>
              <li class="description-list__row">
                <p>Postal code:</p>
                <p>{{ request.postalCode ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">{{ requestDiff.postalCode ?? '-' }}</p>
              </li>
              <li class="description-list__row">
                <p>City:</p>
                <p>{{ request.city ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">{{ requestDiff.city ?? '-' }}</p>
              </li>
              <li class="description-list__row">
                <p>Country:</p>
                <p>{{ request.country ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">{{ requestDiff.country ?? '-' }}</p>
              </li>
              <li class="description-list__row">
                <p>Contact person name:</p>
                <p>{{ request.contactDetailsName ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">
                  {{ requestDiff.contactDetailsName ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>Contact person email:</p>
                <p>{{ request.contactDetailsEmail ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">
                  {{ requestDiff.contactDetailsEmail ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>Contact person telephone:</p>
                <p>{{ request.contactDetailsPhoneNumber ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">
                  {{ requestDiff.contactDetailsPhoneNumber ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>Functions:</p>
                <p>{{ requestFunctions ?? '-' }}</p>
                <p *ngIf="parentRequest" class="text-light">
                  {{ previousRequestFunctions ?? '-' }}
                </p>
              </li>
              <li class="description-list__row">
                <p>Description:</p>
                <p>{{ request.description ?? '-' }}</p>
                <p *ngIf="requestDiff" class="text-light">{{ requestDiff.description ?? '-' }}</p>
              </li>
            </ul>
          </p-card>
        </div>
      </div>
    </div>
  </section>
</div>
