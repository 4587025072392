import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Observable, catchError, throwError } from 'rxjs'
import { FunctionInterface } from '../types/function.interface'

@Injectable()
export class FunctionService {
  constructor(private http: HttpClient) {}

  getAllFunctions(): Observable<FunctionInterface[]> {
    return this.http.get<FunctionInterface[]>('functions/').pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    var errorMessage = ''
    if (error.status === 0) {
      errorMessage = 'Could not connect to the backend API. Please contact the system administrator'
    } else {
      errorMessage = error.error
    }
    return throwError(() => new Error(errorMessage))
  }
}
