import { Component, Input } from '@angular/core'
import { FormArray } from '@angular/forms';
import { Subscription, debounceTime, pairwise, startWith, tap } from 'rxjs';

@Component({
  selector: 'app-function',
  templateUrl: './function.component.html',
  styleUrls: ['./function.component.scss']
})
export class FunctionComponent {

  @Input() functionsControls: FormArray;
  noFunctionSelected: boolean = true;
  functionSub: Subscription;

  messages = [
    {
      severity: 'error',
      detail: 'Please fill in the EIC code type in order to select the function/s.',
    }
  ]

  ngOnInit(){
    this.functionSub = this.functionsControls.statusChanges
    .pipe(
      startWith(null), 
      pairwise(),
      tap(([prev, next]: [any, any]) => {
        this.noFunctionSelected = true;
        if(next=='VALID'){
          this.noFunctionSelected = false;
          return;
        }
  
      }),
      debounceTime(500),
      tap(() => {
        this.functionsControls?.controls?.sort((a, b) => {
          let functionA = a.get('functionName')?.value;
          let functionB = b.get('functionName')?.value;
          return (functionA > functionB) ? 1 : (functionA < functionB) ? -1 : 0;
        })
      })
    )
    .subscribe()
  }

  ngOnDestroy(){
    this.functionSub?.unsubscribe();
  }
}
