<p-dialog
  [header]="'Upload XML Document'"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  closeIcon="icon icon--close"
>
  <ng-template pTemplate="header">
    <h2 class="h5">Upload XML Document</h2>
  </ng-template>

  <div class="container">
    <p-fileUpload
      #fileUpload
      id="upload"
      mode="advanced"
      [showUploadButton]="false"
      [showCancelButton]="false"
      [auto]="true"
      [multiple]="false"
      chooseLabel="Select XML Documents"
      name="documents"
      accept=".xml"
      [customUpload]="true"
      (uploadHandler)="onFileSelected($event)"
    >
    </p-fileUpload>
  </div>

  <div *ngIf="uploadResultVisibility" class="container">
    <h4 style="margin-bottom: 5px">Upload results</h4>
    <div *ngFor="let status of bulkRequestsStatus">
      <h5 style="margin-bottom: 5px; margin-top: 5px">{{ status.eicCode }}</h5>
      <div class="results"><b>Status</b>: {{ status.requestStatus }}</div>
      <div class="results"><b>Reason</b>: {{ status.reason.replace('\r\n', '; ') }}</div>
    </div>
  </div>

  <div class="container" style="margin-top: 20px">
    <h3>Import file</h3>
    <p>Select source XML containing data for import. It will be validated against XSD templates:</p>
    <div *ngFor="let xsdFile of xsdFiles">
      <a class="link-style" (click)="openFile(xsdFile, 'xsd')">{{ xsdFile }}</a>
    </div>
    <p>Sample data file:</p>
    <div *ngFor="let xmlFile of xmlFiles">
      <a class="link-style" (click)="openFile(xmlFile, 'xml')">{{ xmlFile }}</a>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-end gap-3">
      <p-button
        label="Cancel"
        [outlined]="true" severity="secondary"
        (click)="onClose(fileUpload)"
      ></p-button>
      <p-button label="Submit" type="submit" (click)="onSubmit(fileUpload)"></p-button>
    </div>
  </ng-template>
</p-dialog>
