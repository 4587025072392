import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { TpExportsHistoryInterface } from '../types/tp-exports-history.interface';
import { TableFilteringSortingOptions } from '../types/export-options-columns.interface';
import { PaginatedResult } from '../types/paginated-result.interface';
import { EICInterface } from '../types/eic.interface';

@Injectable()
export class ExportsService {
  constructor(private http: HttpClient) {}

    downloadTP(eicId: number ) :Observable<any> {   
        let request =  {
          eicId: eicId
        }
        return this.http.get('exports/tp-download', {
            params: this.ToHttpParams(request), 
            responseType: 'blob' 
        })
        .pipe(
            catchError(this.handleError)
        );
    }

    exportToTP(eicIds: any ) :Observable<any> {   
      return this.http.post('exports/export-to-tp', eicIds)
      .pipe(
          catchError(this.handleError)
      );
    }

    getAllEICsTpHistoryExports(tableOptions: TableFilteringSortingOptions | undefined) :Observable<PaginatedResult<EICInterface>> {
      return this.http.get<PaginatedResult<EICInterface>>('exports/tp-exports', {params: this.ToHttpParams(tableOptions)})
      .pipe(
          catchError(this.handleError)
      );
    }

    private handleError(error: HttpErrorResponse) {
      if (error.status == 400) {
        if (!environment.production) console.error('An error occurred:', error.error)      
        return throwError(() => new Error(error.error))
      }
      if (!environment.production){
        console.error(`Backend returned code ${error.status}, body was: `, error.error)
      }
      return throwError(() => new Error('An error occurred; please try again later.'))
    }

    private ToHttpParams(request: any): HttpParams {
      let httpParams = new HttpParams()
      Object.keys(request).forEach(function (key) {
        httpParams = httpParams.append(key, request[key])
      });
      return httpParams;
    }

}
