import { Component, OnInit } from '@angular/core'
import { eicStatusFilterOptions, eicStatusList } from '@app/shared/types/enums/eic-status.enum'
import {
  requestActionFilterOptions,
  requestActionsList
} from '@app/shared/types/enums/request-action.enum'
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum'
import { ReportInterface } from '@app/shared/types/report.interface'
import { VisibilityColumnsHeader } from '@app/shared/types/visibility-columns-header.interface'
import { getEICCodesAll, totalRecordsSelector } from '@app/store/selectors/report.selector'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import * as ReportActions from '../../../store/actions/report.actions'
import { EICInterface } from '@app/shared/types/eic.interface'
import { ExportPlace } from '@app/shared/types/enums/export-place.enum'
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface'
import { eicCodeTypesList } from '@app/shared/types/enums/eic-code-type.enum'
import { ExportTypeCodes } from '@app/shared/types/enums/export-type-codes.enum'
import { ActivatedRoute, Router } from '@angular/router';
import { requestSourceFilterOptions, requestSourceList } from '@app/shared/types/enums/request-source.enum'

@Component({
  selector: 'app-eic-codes-all',
  templateUrl: './eic-codes-all.component.html',
  styleUrls: ['./eic-codes-all.component.scss']
})
export class EicCodesAllComponent implements OnInit {
  today: number = Date.now()
  settingType: number
  allEics: EICInterface[]
  report$: Observable<EICInterface[]>
  reportsSub: Subscription
  visibilityColumnsHeaderObj: VisibilityColumnsHeader = new VisibilityColumnsHeader()

  exportOptionColumns: TableFilteringSortingOptions = new TableFilteringSortingOptions()
  exportPlace: ExportPlace = ExportPlace.AllEIC

  requestActionFilterOptions = requestActionFilterOptions
  eicStatusFilterOptions = eicStatusFilterOptions
  requestActionsList = requestActionsList
  eicStatusList = eicStatusList
  eicCodeTypesOptions = eicCodeTypesList
  requestSourceFilterOptions = requestSourceFilterOptions

  pageNumber: number | undefined = 0
  rowsPerPage: number | undefined = 10

  totalRecords$: Observable<number>
  totalRecords: number
  firstRow: number = 0
  recordSub: Subscription
  querySub: Subscription

  exportOptions: any[]

  constructor(private reportStore: Store<ReportInterface>,
    private route: ActivatedRoute,
    private router: Router) {
    this.report$ = this.reportStore.select(getEICCodesAll)
    this.totalRecords$ = this.reportStore.select(totalRecordsSelector)
  }

  ngOnInit(): void {
    this.settingType = SettingsTypes.allEicCodes
    this.visibilityColumnsHeaderObj.optionDisabled = 'activeStatus'
    this.exportOptions = [ExportTypeCodes.XML, ExportTypeCodes.CSV, ExportTypeCodes.EXCEL]

    let paramsOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions();

    this.querySub = this.route.queryParams
      .subscribe(params => {
        Object.keys(params).forEach(key => {
          if(params[key] != null){

            switch(key){
              case "requestAction":
                if(params[key] != undefined){
                  paramsOptions[key] = ""
                  params[key].split(",").forEach(requestActionLabel => {
                    paramsOptions[key] += requestActionsList.find((item) => item.label == requestActionLabel)?.value + ","
                  })
                  paramsOptions[key] = paramsOptions[key]?.slice(0, -1)
                }
                break;
              case "status":
                if(params[key] != undefined){
                  paramsOptions[key] = ""
                  params[key].split(",").forEach(eicStatusLabel => {
                    paramsOptions[key] += eicStatusList.find((item) => item.label == eicStatusLabel)?.value + ","
                  })
                  paramsOptions[key] = paramsOptions[key]?.slice(0, -1)
                }
                break;
              default:
                paramsOptions[key] = params[key]
                break;
          }
        }
        })
      })
      this.pageNumber = paramsOptions.pageNumber
      this.rowsPerPage = paramsOptions.rowsPerPage

    if (!this.pageNumber || !this.rowsPerPage) {
      this.pageNumber = 0
      this.rowsPerPage = 10
    }

    this.firstRow = this.rowsPerPage * this.pageNumber

    this.recordSub = this.totalRecords$.subscribe((total) => {
      this.totalRecords = total
    })

    this.exportOptionColumns.pageNumber = this.pageNumber
    this.exportOptionColumns.rowsPerPage = this.rowsPerPage

    Object.keys(paramsOptions).forEach(key => {
      if(paramsOptions[key] != null){
        this.exportOptionColumns[key] = paramsOptions[key]
      }
    })

    this.getData(this.exportOptionColumns)
  }

  onPageChange(tableFilterOrSortOptions: TableFilteringSortingOptions) {
    let props = tableFilterOrSortOptions
    Object.keys(props).forEach(function (key) {
      if (props[key] == null) {
        delete props[key]
      }
    })
    this.exportOptionColumns = props

    this.getData(props)
  }

  getData(tableOptions: TableFilteringSortingOptions) {
    this.reportStore.dispatch(ReportActions.getAllEicReport({ props: tableOptions }))

    this.reportsSub = this.report$.subscribe((data) => {
      this.allEics = data.map((item) => {
        let req = Object.assign({}, item, {
          statusObj: {
            label: eicStatusList[item.status].label,
            value: item.status
          },
          requestActionObj: {
            label: requestActionsList[item.requestAction].label,
            value: item.requestAction
          },
          sourceObj: {
            label: item.source != null ? requestSourceList[item.source].label : '',
            value: item.source != null ? item.source : ''
          },
          eicCodeTypeObj: {
            label: eicCodeTypesList.filter((obj) => {
              return obj.value == item.eicCodeType
            })[0].label,
            value: item.eicCodeType
          }
        })
        return req
      })
    })
    this.setQueryParams()
  }

  setQueryParams() {
    let queryParams : TableFilteringSortingOptions = new TableFilteringSortingOptions()

      Object.keys(this.exportOptionColumns).forEach(key => {
        if(this.exportOptionColumns[key] != null){
          if(key != "sortBy"){
            
            switch(key){
              case "requestAction":
                if(this.exportOptionColumns[key] != undefined){
                  queryParams[key] = ""
                this.exportOptionColumns[key]?.split(",").forEach(requestAction => {
                  queryParams[key] += requestActionsList[requestAction].label + ','
                })
                queryParams[key] = queryParams[key]?.slice(0, -1)
                }
                break;
              case "status":
                if(this.exportOptionColumns[key] != undefined){
                  queryParams[key] = ""
                this.exportOptionColumns[key]?.split(",").forEach(eicStatus => {
                  queryParams[key] += eicStatusList[eicStatus].label + ','
                })
                queryParams[key] = queryParams[key]?.slice(0, -1)
                }
                break;
              default:
                queryParams[key] = this.exportOptionColumns[key]
                break;
            }
          }
        }
    });

    this.router.navigate([], { relativeTo: this.route, queryParams: queryParams })
  }

  ngOnDestroy() {
    this.recordSub.unsubscribe()
    this.reportsSub.unsubscribe()
    this.querySub.unsubscribe()
  }
}
