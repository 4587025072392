import { Component, OnInit } from '@angular/core'
import { RequestStateInterface } from '@app/shared/types/request-state.interface'
import { RequestInterface } from '@app/shared/types/request.interface'
import {
  latestFivePendingRequests,
  latestFiveRequestsOfLio
} from '@app/store/selectors/request.selector'
import * as RequestActions from '@app/store/actions/request.actions'
import { Store, select } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import { UserInfoInterface } from '@app/shared/types/user-info.interface'
import { currentUserSelector } from '@app/store/selectors/user-info.selector'
import { UserInfoStateInterface } from '@app/shared/types/user-info-state.interface'
import { PublicWebsiteUsefulLinks } from '@app/shared/types/enums/public-website-useful-links.enum'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  codes: any[] | undefined
  userSub: Subscription
  lioNumber: number | null
  forLio: boolean
  openRequests$: Observable<RequestInterface[]>
  latestRequests$: Observable<RequestInterface[]>

  PublicWebsiteUsefulLinks = PublicWebsiteUsefulLinks
  homepageDocuments = [
    // 'CIO User Guide v1_4_2.pdf', // TBD add updated version of doc
    'ENTSOE_DISCLAIMER.pdf',
    'CIOLIO Tool User Guide_V1.0.pdf',
    'CIOLIO Tool Public API Documentation_V1.0.pdf',
    'CIOLIO Tool SFTP Configuration Guide_V1.0.pdf'
  ]

  constructor(
    private store: Store<RequestStateInterface>,
    private userStore: Store<UserInfoStateInterface>
  ) {
    this.userSub = this.userStore
      .pipe(select(currentUserSelector))
      .subscribe((user: UserInfoInterface) => {
        this.lioNumber = user.lioNumber
        this.forLio = user.isImpersonating || this.lioNumber != 10
      })
    this.openRequests$ = this.store.select(latestFivePendingRequests())
    this.latestRequests$ = this.store.select(latestFiveRequestsOfLio(this.lioNumber))
  }

  ngOnInit(): void {
    this.store.dispatch(
      RequestActions.getLatestFivePendingRequests({
        lioNumber: this.forLio ? this.lioNumber : null
      })
    )
    this.store.dispatch(RequestActions.getLatestFiveRequestsByLio({ lioNumber: this.lioNumber }))

    this.codes = Array(4).fill('10X1001A1001A23A')
  }

  ngOnDestroy() {
    this.userSub?.unsubscribe()
  }

  onRowChangeMethod() {}
}
