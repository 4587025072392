import { Component, Input, OnInit } from '@angular/core'
import { EICStatusEnum, eicStatusList } from '@app/shared/types/enums/eic-status.enum';
import { RequestActionEnum, requestActionsList } from '@app/shared/types/enums/request-action.enum';
import { requestStatusList } from '@app/shared/types/enums/request-status.enum';

@Component({
  selector: 'app-version-card',
  templateUrl: './version-card.component.html',
  styleUrls: ['./version-card.component.scss']
})
export class VersionCardComponent implements OnInit{

  @Input() version: any;

  RequestActionEnum = RequestActionEnum;
  eicStatusList = eicStatusList;
  EICStatusEnum = EICStatusEnum;
  requestActionsList = requestActionsList;
  requestStatusList = requestStatusList;
  
  ngOnInit(){
    
  }

  requestFunctions(){
    if(this.version.requestFunctions == "" || this.version.requestFunctions == null || this.version.requestFunctions.length == 0){
      return "-";
    }
    let str = "";
    this.version.requestFunctions.forEach((functionName) => {
      str += functionName + ", ";
    });
    str = str.slice(0, -2);
    return str;
  }

  requestEicStatus(){
    if(this.version.eicStatus == null){
      return "-";
    }

    return eicStatusList[this.version.eicStatus].label;
  }
}
