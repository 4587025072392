<form *ngIf="actingLioNumber === 10" [formGroup]="requestForm">
  <p-fieldset legend="Contact details" styleClass="mb-5">
    <div class="grid">
      <div class="col-4">
        <label>Name</label>
        <input
          class="w-full mt-3"
          pInputText
          formControlName="contactDetailsName"
          placeholder="Input"
        />
      </div>
      <div class="col-4">
        <label>E-mail</label>
        <input
          class="w-full mt-3"
          pInputText
          formControlName="contactDetailsEmail"
          placeholder="Input"
        />
        <span  *ngIf="requestForm.controls['contactDetailsEmail']?.errors?.['email']; else maxlength" class="p-error"> 
          Please provide a valid e-mail address.
        </span>
        <ng-template #maxlength>
          <span  *ngIf="requestForm.controls['contactDetailsEmail']?.errors?.['maxlength']" class="p-error"> 
            The e-mail address can contain maximum 70 characters.
          </span>   
        </ng-template>          
      </div>
      <div class="col-4">
        <label>Telephone number</label>
        <input
          class="w-full mt-3"
          pInputText
          formControlName="contactDetailsPhoneNumber"
          placeholder="Input"
        />
        <span *ngIf="requestForm.controls['contactDetailsPhoneNumber']?.errors?.['pattern']; else maxlength"
          class="p-error"> 
          The phone number can only contain numbers, eventually preceeded by the '+' sign.
        </span>
        <ng-template #maxlength>
          <span *ngIf="requestForm.controls['contactDetailsPhoneNumber']?.errors?.['maxlength']" class="p-error"> 
            The phone number can contain a maximum of 15 characters.
          </span>   
        </ng-template>                        
      </div>
    </div>  
  </p-fieldset>
</form>
