import { createAction, props } from "@ngrx/store";
import { UserInfoInterface } from "@app-shared-types/user-info.interface";

export const IMPERSONATE_LIO = '[USERINFO Component] Impersonate lio';
export const STOP_LIO_IMPERSONATION = '[USERINFO Component] Stop lio impersonation';
export const UPDATE_USER_INFO = '[USERINFO Component] Update user info';

export const setUserInfo = createAction(
    UPDATE_USER_INFO,
    props<{ currentUser: UserInfoInterface }>()
);

export const impersonateLio = createAction(
    IMPERSONATE_LIO,
    props<{ lioNumber: number }>()
);

export const stopLioImpersonation = createAction(
    STOP_LIO_IMPERSONATION
);


