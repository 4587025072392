export class ReportsSideBarMenu {
    menu = [
        {
            label: 'All approved requests - for LIOs',
            routerLink: '/reports/all-approved-requests',
        },
        {
            label: 'EIC codes all',
            routerLink: '/reports/all-eic-codes',
        },
        {
            label: 'EIC codes pending for Deactivation',
            routerLink: '/reports/deactivated-codes',
        },
        {
            label: 'Active EIC codes',
            routerLink: '/reports/active-eic-codes',
        }  
    ];
}