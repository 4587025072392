<form [formGroup]="requestForm">
  <p-fieldset legend="Allocated EIC code" styleClass="mb-5">
    <div class="grid">
      <div class="col-12">
        <div class="pt-5 border-top-1 border-silver">
          <h2 class="mb-5 h4 text-secondary">
            Your EIC code:
            <span class="p-2 ml-3 font-normal bg-white border-round">
              {{requestForm.get('lioNumber')?.value}} 
              {{requestForm.get('eicCodeType')?.value ?? '-'}} 
              {{requestForm.get('eicRandomSequence')?.value ?? '-'}} 
              {{requestForm.get('eicCheckKey')?.value ?? '-' }} 
            </span>
          </h2>
          <div>
            <p class="mt-0 mb-1">EIC code <span class="text-required">*</span></p>
            <div class="grid">
              <div class="col-1">
                <p-inputNumber formControlName="lioNumber"></p-inputNumber>
              </div>
              <div class="col-2">
                <p-dropdown
                  class="eicTypeDropdown"
                  placeholder="Select"
                  dropdownIcon="icon icon--angle-down"
                  [options]="codeFunctionOptions"
                  formControlName="eicCodeType"
                  (onChange)="initFunctions(); validateOrGenerateCheckKey('eicCodeType')"
                  [ngClass]="{'err': requestForm.controls['hasBeenSubmitted'].value && 
                  !requestForm.controls['eicCodeType']?.valid}"
                  styleClass="mt-0"
                ></p-dropdown>
                <span *ngIf="!requestForm.controls['eicCodeType'].valid && 
                  requestForm.controls['hasBeenSubmitted'].value && formType == 'create'" 
                  class="p-error"> 
                  An EIC code type must be chosen. 
                </span>
              </div>
              <div class="col-3">
                <input
                  class="w-full"
                  pInputText
                  formControlName="eicRandomSequence"
                  maxlength="12"
                  placeholder="Input"
                  oninput="this.value=this.value.toUpperCase();"
                  (keyup)="validateOrGenerateCheckKey('eicRandomSequence')"
                  [ngClass]="{'err': requestForm.controls['hasBeenSubmitted'].value && 
                !requestForm.controls['eicRandomSequence']?.valid}"
                />
                <span *ngIf="requestForm.controls['eicRandomSequence']?.errors?.['pattern']; else maxlength"
                  class="p-error"> 
                  The EIC sequence can contain only uppercase letters, numbers and the '-' character.
                </span>
                <ng-template #maxlength>
                  <span *ngIf="requestForm.controls['eicRandomSequence']?.errors?.['minlength'] || 
                  requestForm.controls['eicRandomSequence']?.errors?.['maxlength']; else missing"
                    class="p-error"> 
                    The EIC sequence must contain exactly 12 characters.
                  </span>
                  <ng-template #missing>
                    <span  *ngIf="!requestForm.controls['eicRandomSequence'].valid &&
                    requestForm.controls['hasBeenSubmitted'].value && formType == 'create'" 
                      class="p-error"> 
                      The EIC sequence must be filled in.
                    </span>  
                  </ng-template>                                
                </ng-template>                             
              </div>
              <div class="col-2">
                <input
                  pInputText
                  formControlName="eicCheckKey"
                  maxlength="1"
                  oninput="this.value=this.value.toUpperCase();"
                  (blur)="validateOrGenerateCheckKey('eicCheckKey')"
                  [ngClass]="{'err': requestForm.controls['hasBeenSubmitted'].value && 
                  !requestForm.controls['eicCheckKey']?.valid}"
                />
                <div style="display: block;">
                <span *ngIf="requestForm.controls['eicCheckKey']?.errors?.['pattern']; else missing" 
                  class="p-error"> 
                  The check key can contain only uppercase letters or numbers. 
                </span>
                <ng-template #missing>
                  <span  *ngIf="!requestForm.controls['eicCheckKey'].valid && 
                  requestForm.controls['hasBeenSubmitted'].value && formType == 'create'"
                    class="p-error"> 
                    The check key must be filled in. 
                  </span>  
                </ng-template>
              </div>
              </div>
              <div class="col">
                <p-button
                  *ngIf="formType == 'create' && requestForm.get('lioNumber')?.value == 10"
                  (onClick)="generateEIC()"
                  type="submit"
                  >Generate
                </p-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-fieldset>
</form>
