import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface';
import { PaginatedResult } from '@app/shared/types/paginated-result.interface';
import { TpExportsHistoryInterface } from '@app/shared/types/tp-exports-history.interface';
import { createAction, props } from '@ngrx/store';

export const GET_HISTORY = '[Get history Overview Component] Get exports history';
export const GET_HISTORY_SUCCESSFUL = '[Get history Overview Component] Get exports history success';
export const GET_HISTORY_FAILED = '[Get history Overview Component] Get exports history failed';

export const getHistory = createAction(
    GET_HISTORY,
    props<{ tableOptions: TableFilteringSortingOptions }>()
);
export const getHistorySuccessful = createAction(
    GET_HISTORY_SUCCESSFUL,
    props<{ paginatedResult: PaginatedResult<TpExportsHistoryInterface> }>()
);
export const getHistoryFailed = createAction(
    GET_HISTORY_FAILED,
    props<{error: string}>()
);