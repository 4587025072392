import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { EicDefaultTableHeaders } from '@app/shared/helpers/eic-default-table-headers'
import { SettingsTypes } from '@app/shared/types/enums/setings-types.enum'
import { SettingStateInterface } from '@app/shared/types/setting-state.interface'
import { SettingInterface } from '@app/shared/types/setting.interface'
import { VisibilityColumnsHeader } from '@app/shared/types/visibility-columns-header.interface'
import { Store, select } from '@ngrx/store'
import * as settingsActions from '@app-store/actions/setting.actions'
import { LioDefaultTableHeaders } from '@app/shared/helpers/lio-default-table-headers'
import { Observable } from 'rxjs'
import { tableHeadersSettingsSelector } from '@app/store/selectors/setting.selector'
import { Column } from '@app/shared/types/column'
import { RequestDefaultTableHeaders } from '@app/shared/helpers/request-default-table-headers'
import { LioRequestsDefaultTableHeaders } from '@app/shared/helpers/lio-requests-default-table-headers'
import { ActiveEicCodesReportTableHeaders } from '@app/shared/helpers/active-eic-codes-report-table-headers'
import { AllApprovedRequestsReportTableHeaders } from '@app/shared/helpers/all-approved-requests-report-table-header'
import { DeactivatedCodesReportTableHeaders } from '@app/shared/helpers/deactivated-codes-report-table-header'
import { AllEicCodesReportTableHeaders } from '@app/shared/helpers/all-eic-codes-report-table-header'
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface'
import { ExportPlace } from '@app/shared/types/enums/export-place.enum'
import { LazyLoadEvent } from 'primeng/api'
import { Router } from '@angular/router'
import { DatePipe } from '@angular/common'
import { LioUsersTableHeaders } from '@app/shared/helpers/lio-users-table-headers';
import { TpExportsTableHeaders } from '@app/shared/helpers/tp-exports-table';
import { TpExportsHistoryTableHeaders } from '@app/shared/helpers/tp-exports-history-table-headers';
import { Table } from 'primeng/table'
import * as moment from 'moment';
import { isLio10 } from '@app/store/selectors/user-info.selector'
import { UserInfoStateInterface } from '@app/shared/types/user-info-state.interface'
import { FunctionStateInterface } from '@app/shared/types/function.state.interface'
import { functionsSelector } from '@app/store/selectors/function.selector'
import { FunctionInterface } from '@app/shared/types/function.interface'
import { MultiSelectFilterEvent } from 'primeng/multiselect'
import { TableService } from '@app/shared/services/table.service'

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {
  @Input() filterOptions: TableFilteringSortingOptions
  @Input() values: any
  @Input() tableStyleClass: string
  @Input() styleClass: string
  @Input() paginator: boolean
  @Input() nrOfRows: number
  @Input() currentPageReportTemplate: string
  @Input() showCurrentPageReport: boolean
  @Input() rowsPerPageOptions: []
  @Input() showFirstLastIcon: boolean
  @Input() responsiveLayout: string
  @Input() visibilityColumnsHeader: boolean
  @Input() visibilityColumnsHeaderObj: VisibilityColumnsHeader
  @Input() selectionMode: string
  @Input() settingType: SettingsTypes

  @Input() requestActionFilterOptions: any
  @Input() statusFilterOptions: any
  @Input() requestSourceFilterOptions: any
  @Input() requestActionsList: any
  @Input() statusList: any
  @Input() requestSourceList: any
  @Input() eicCodeTypesOptions: any
  @Input() availableMarketsList: any
  @Input() expandableRows: boolean = false
  @Input() showCaption: boolean = true
  @Input() checkBoxMultiSelect: boolean
  @Input() tpHistoryState: any;
  @Input() tpHistoryResult: any;
  
  @Input() export: boolean = false;
  @Input() download: boolean = false;
  @Input() tpHistory: boolean = false;
  @Input() selectedExportTypes: any[];
  @Input() selectedRows: any[];
  @Input() hideExportSelection: boolean = false;
  @Input() totalRecords: number;
  @Input() firstRow: number = 0;
  @Input() exportOptions: any[];
  @Input() fromLioEic: boolean = false;
  @Input() exportPlace: ExportPlace;
  @Input() exportBtnDisabled: boolean = false;
  @Input() defaultSortField: any;
  @Input() isMyRequestPage: boolean = false;
  @Input() enableSelectAll: boolean = true;
  @Input() isUsersTable: boolean = false;

  @Output("onRowChange") onRowChange: EventEmitter<any> = new EventEmitter();
  @Output("onRowExpand") onRowExpand: EventEmitter<any> = new EventEmitter();
  @Output("impersonateLio") impersonateLio: EventEmitter<any> = new EventEmitter();
  @Output("onRowSelect") onRowSelect: EventEmitter<any> = new EventEmitter();
  @Output("onRowUnselect") onRowUnselect: EventEmitter<any> = new EventEmitter();
  @Output("onPageChange") onPageChange: EventEmitter<any> = new EventEmitter();
  @Output("onXmlUpload") onXmlUpload: EventEmitter<any> = new EventEmitter();
  @Output("onCreate") onCreate: EventEmitter<any> = new EventEmitter();
  
  @ViewChild('dt') table: Table;

  SettingsTypes = SettingsTypes;
  exportOptionColumns: TableFilteringSortingOptions = new TableFilteringSortingOptions()

  settings$: Observable<any>;
  settings: any[];
  allTableHeaders: Column[]
  displayedColumns: Column[]
  _selectedColumns: Column[]
  selectedColumns: Column[]
  isRequestTable: boolean

  pageNumber: number
  rowsPerPage: number
  dateRangeStart: string | undefined;
  dateRangeEnd: string | undefined;
  isLio10: boolean = false;
  selectAll: boolean = false;

  allFunctions: FunctionInterface[];
  filteredFunctions: FunctionInterface[] | undefined;

  defaultDate: Date = new Date("January 31 1980 00:00");
  fromFilterValue?: Date;
  toFilterValue?: Date;

  constructor(
    private settingsStore: Store<SettingStateInterface>,
    private router: Router,
    private datePipe: DatePipe,
    private userStore: Store<UserInfoStateInterface>,
    private functionStore: Store<FunctionStateInterface>,
    private tableService: TableService
  ){
    this.userStore.select(isLio10()).subscribe((usr) => (this.isLio10 = usr))
    this.settings$ = this.settingsStore.pipe(select(tableHeadersSettingsSelector));
    this.functionStore.select(functionsSelector).subscribe(fct => this.allFunctions = fct);
  }

  ngOnInit() {
    let defaultTableHeaders: any
    let settingType = ''
    this.isRequestTable = false
    this.visibilityColumnsHeader = this.visibilityColumnsHeader || false;
    this.settingsStore.dispatch(settingsActions.getSettings());
    
    switch (this.settingType) {
      case SettingsTypes.lioTableHeaders:
        defaultTableHeaders = new LioDefaultTableHeaders()
        settingType = 'lioTableHeaders'
        this.isRequestTable = true
        break
      case SettingsTypes.eicTableHeaders:
        defaultTableHeaders = new EicDefaultTableHeaders()
        settingType = 'eicTableHeaders'
        break
      case SettingsTypes.requestTableHeaders:
        defaultTableHeaders = new RequestDefaultTableHeaders()
        settingType = 'requestTableHeaders'
        this.isRequestTable = true
        break
      case SettingsTypes.lioRequestsTableHeaders:
        defaultTableHeaders = new LioRequestsDefaultTableHeaders()
        settingType = 'lioRequestsTableHeaders'
        this.isRequestTable = true
        break
      case SettingsTypes.allApprovedRequests:
        defaultTableHeaders = new AllApprovedRequestsReportTableHeaders()
        settingType = 'allApprovedRequestsTableHeaders'
        break
      case SettingsTypes.allEicCodes:
        defaultTableHeaders = new AllEicCodesReportTableHeaders()
        settingType = 'allEicCodesTableHeaders'
        this.isRequestTable = true
        break
      case SettingsTypes.activeEicCodes:
        defaultTableHeaders = new ActiveEicCodesReportTableHeaders()
        settingType = 'activeEicCodesTableHeaders'
        break
      case SettingsTypes.deactivatedCodes:
        defaultTableHeaders = new DeactivatedCodesReportTableHeaders()
        settingType = 'deactivatedCodesTableHeaders'
        break;
      case SettingsTypes.tpExports:
        defaultTableHeaders = new TpExportsTableHeaders()
        settingType = 'tpExportsTableHeaders'
        this.isRequestTable = true
        break
      case SettingsTypes.tpExportsHistory:
        defaultTableHeaders = new TpExportsHistoryTableHeaders()
        settingType = 'tpExportsHistoryTableHeaders'
        break;
      case SettingsTypes.lioUsers:
          defaultTableHeaders = new LioUsersTableHeaders();
          settingType = "lioUsersTableHeaders";
        break;
    }
    
    this.allTableHeaders = defaultTableHeaders.allColumns?.map((col) => ({
        field: col.field,
        header: col.header,
        activeStatus: col.activeStatus
    }));

    this.settings$.subscribe(settings => {
      this.settings = settings === null || settings === undefined ? undefined : settings[settingType];
      if(this.settings == null) {
        this.displayedColumns = defaultTableHeaders.allColumns?.filter((x) => x.visible === true);
      }
      else{
        this.displayedColumns = this.settings?.filter((x) => x.visible === true)
        .map((y) => {
          let field = defaultTableHeaders.allColumns?.filter((x) => x.field == y.field)[0];
            return {
              field: field.field,
              header: field.header,
              visible: field.visible,
              sort: field.sort,
              filter: field.filter,
              minWidth: field.minWidth
            }
        });
      }

      this._selectedColumns = this.displayedColumns?.map((col) => {
        let activeStatus = this.allTableHeaders.filter((x) => x.field == col.field)[0];
          return {
            field: col.field,
            header: col.header,
            activeStatus: activeStatus.activeStatus
          };
        });
      this.selectedColumns = this._selectedColumns;
      this.updateFilters();
    })

  }

  ngAfterViewInit(){
    this.updateFilters();
  }
  updateFilters(): void {

    if(this.filterOptions && this.table?.filters)
   
      Object.keys(this.filterOptions)?.forEach((key) => {
  
        if(key != 'pageNumber' && key != 'rowsPerPage'){
  
          if(key == 'status'){
            this.table.filters['statusObj'][0].value = this.statusFilterOptions.filter(a => this.filterOptions[key]?.includes(a.value))
          }
          else if(key == 'requestAction'){
            this.table.filters['requestActionObj'][0].value = this.requestActionFilterOptions.filter(a => this.filterOptions[key]?.includes(a.value))
          }
          else if(key == 'eicCodeType'){
            if(this?.table?.filters['eicCodeTypeObj']){
              this.table.filters['eicCodeTypeObj'][0].value = this.eicCodeTypesOptions.filter(a => this.filterOptions[key]?.includes(a.value))
            }
          }
          else if(key == 'source'){
            this.table.filters['sourceObj'][0].value = this.requestSourceFilterOptions.filter(a => this.filterOptions[key]?.includes(a.value))
          }
          else if(key == 'functions'){
            if(this.allFunctions != null && this.allFunctions.length > 0 && this?.table?.filters['functions']){
              var funcIds = this.filterOptions[key]?.split(",")
              
              if(funcIds != null){
                const idSet = new Set(funcIds)
                  this.table.filters['functions'][0].value = this.allFunctions.filter(func => idSet.has(func.id.toString()))
              }
            }
          }
          else if(key.includes('Date')){
              var dateStrObj = this.filterOptions[key].split(",");
              var startDateObj = dateStrObj[0].split("-");
              var endDateObj = dateStrObj[1].split("-");
              this.dateRangeStart = startDateObj[2] + "/" + startDateObj[1] + "/" + startDateObj[0];
              this.dateRangeEnd = endDateObj[2] + "/" + endDateObj[1] + "/" + endDateObj[0];

              if(this.table.filters[key.split('_')[0]])
                this.table.filters[key.split('_')[0]][0].value = this.dateRangeStart + " - " + this.dateRangeEnd;
            }
          else{
            if(this.table.filters[key])
            this.table.filters[key][0].value = this.filterOptions[key]
          }
        }
      })
  }
    
  checkIfFieldIsDate(field: string){
    return field.toLowerCase().includes("date");
  }

  dateToCET(field: string, cesTime, row) {
    let values = row
    if(values[field] == null){
      return null
    }

    if(field.toLowerCase().includes("date")){
      if(field == "registrationDate" || field == "lastLoginDate"){
        if(values[field] == ""){
          return null
        }
        let timeInSeconds = parseInt(values[field], 10)
        const newDate = moment(new Date(timeInSeconds)).format('DD-MM-yyyy')
        return newDate
      }
      else
      {
        if(values[field] != null && values[field] != ""){
          const offset =  moment['tz'](moment(new Date()), 'Europe/Berlin').utcOffset() * 60000;
          var berlinDateTime = new Date(new Date(values[field]).getTime() + offset);

          return moment(berlinDateTime).format("ddd, MMM DD, y, h:mm A");
        }
      }
    }
    return "";
  }

  resetSelectedColumns() {
    let defaultColumns = {}
    switch (this.settingType) {
      case SettingsTypes.eicTableHeaders:
        defaultColumns = new EicDefaultTableHeaders().defaultColumns?.map((col) => ({
          field: col.field,
          header: col.header,
          visible: col.visible
        }))
        break
      case SettingsTypes.lioTableHeaders:
        defaultColumns = new LioDefaultTableHeaders().defaultColumns?.map((col) => ({
          field: col.field,
          header: col.header,
          visible: col.visible
        }))
        break
      case SettingsTypes.requestTableHeaders:
        defaultColumns = new RequestDefaultTableHeaders().defaultColumns?.map((col) => ({
          field: col.field,
          header: col.header,
          visible: col.visible
        }))
        break
      case SettingsTypes.lioRequestsTableHeaders:
        defaultColumns = new LioRequestsDefaultTableHeaders().defaultColumns?.map((col) => ({
          field: col.field,
          header: col.header,
          visible: col.visible
        }))
        break
      case SettingsTypes.allEicCodes:
        defaultColumns = new AllEicCodesReportTableHeaders().defaultColumns?.map((col) => ({
          field: col.field,
          header: col.header,
          visible: col.visible
        }))
        break;
      case SettingsTypes.tpExports:
        defaultColumns = new TpExportsTableHeaders().defaultColumns?.map((col) => ({
          field: col.field,
          header: col.header,
          visible: col.visible
        }))
        break
    }
   
    let setting: SettingInterface = {
      type: this.settingType,
      setting: JSON.stringify(defaultColumns)
    }

    this.settingsStore.dispatch(settingsActions.createSetting({ setting: setting }))
  }

  onRowSelectMethod(event: any) {
    this.onRowSelect.emit(event);
  }

  onRowUnselectMethod(event: any) {
    this.onRowUnselect.emit(event);
  }

  saveSelectedColumns() {
    let displayedColumns = this._selectedColumns.map((col) => ({
      field: col.field,
      header: col.header,
      visible: true
    }))

    let setting: SettingInterface = {
      type: this.settingType,
      setting: JSON.stringify(displayedColumns)
    }

    if (this.displayedColumns.length > displayedColumns.length) {
      let removedColumns = this.displayedColumns.filter(x => !displayedColumns.map(y => y.field).includes(x.field));
      this.resetFiltersForColumns(removedColumns);
    }

    this.settingsStore.dispatch(settingsActions.createSetting({ setting: setting }))
  }
  
  resetFiltersForColumns(columns: Column[]): void {
    let filterOptions = { ...this.filterOptions };
    columns.forEach(column => {
      this.tableService.setFilterPropertyValueForColumn(filterOptions, column.field, undefined);
      if(column.field == 'duration'){
        this.fromFilterValue = undefined;
        this.toFilterValue = undefined;
      }
      if (this.tableService.isFilterableDateColumn(column.field)) {
        this.onDateClear(null, column.field);
      }

      if(this.table.filters[column.field]){
        if (this.table.filters[column.field][0])
          this.table.filters[column.field][0].value = null;
      }
    })
    
    this.exportOptionColumns = filterOptions;
    this.onPageChange.emit(filterOptions);
  }

  onHideSelectColumnsMethod() {
    this._selectedColumns = this.selectedColumns
  }

  onRowChangeMethod(event: any) {
    this.onRowChange.emit(event)
  }

  impersonateLioMethod(event: any) {
    this.impersonateLio.emit(event)
  }

  onCreateMethod(){
    this.onCreate.emit()
  }

  onXmlUploadMethod(){
    this.onXmlUpload.emit()
  }

  setFilteredFunctions(event: MultiSelectFilterEvent) {
    if (!event.filter)
      this.filteredFunctions = this.allFunctions;
    else
      this.filteredFunctions = [...this.allFunctions.filter(f => f.name.toLowerCase().includes(event.filter))];
  }

  onSelectAllChange(filter: (a) => void, event) {
    let filteredFunctions = event?.checked ? [...this.filteredFunctions ?? this.allFunctions] : null;
    this.selectAll = event?.checked;
    filter(filteredFunctions);
  }

  onPageChangeMethod(event: LazyLoadEvent) {
    let filterOrSortOptions: TableFilteringSortingOptions = new TableFilteringSortingOptions();
    filterOrSortOptions.rowsPerPage = event.rows
    filterOrSortOptions.pageNumber =
      event.first != null && event.rows != null ? event.first / event.rows : 0
    filterOrSortOptions.sortBy = event.sortField

    if (event.sortOrder == 1) {
      filterOrSortOptions.sortBy += '|ASC'
    }
    if (event.sortOrder == -1) {
      filterOrSortOptions.sortBy += '|DESC'
    }

    for(var filter in event.filters){
      let filterValue = this.checkIfFieldIsDate(filter) ? event.filters[filter].value : event.filters[filter][0].value;  

      if(this.tableService.isFilterableDateColumn(filter)){
        if(event.filters[filter + "_range"] != undefined){
          var dateStrObj = event.filters[filter + "_range"].value.split(",");
          var startDateObj = dateStrObj[0].split("-");
          var endDateObj = dateStrObj[1].split("-");
          var startDate = startDateObj[2] + "/" + startDateObj[1] + "/" + startDateObj[0];
          var endDate = endDateObj[2] + "/" + endDateObj[1] + "/" + endDateObj[0];

          event.filters[filter][0].value = startDate + " - " + endDate;
        }
        else{
          event.filters[filter][0].value = "";
        }
      }

      switch(filter) {
        case  "requestActionObj":
          var filterValues = this.getFilterValuesForMultiSelect(filterValue);
          filterOrSortOptions["requestAction"] = filterValue != null && filterValue.length > 0 ? filterValues : undefined;
          break;
        case  "statusObj":
          var filterValues = this.getFilterValuesForMultiSelect(filterValue);
          filterOrSortOptions["status"] = filterValue != null && filterValue.length > 0 ? filterValues : undefined;
          break;
        case  "eicCodeTypeObj":
          var filterValues = this.getFilterValuesForMultiSelect(filterValue);
          filterOrSortOptions["eicCodeType"] = filterValue != null && filterValue.length > 0 ? filterValues : undefined;
          break;
        case "availableMarketsList":
          filterOrSortOptions["availableMarketsList"] = filterValue != null ? filterValue[0].value : null;
          break;
        case "registrationDate":
        case "lastLoginDate":          
          if(filterValue != null && filterValue != ""){
            filterOrSortOptions[filter] = this.datePipe.transform(filterValue, 'yyyyMMdd')!;
          }
          break;
        case "sourceObj":
          var filterValues = this.getFilterValuesForMultiSelect(filterValue);
          filterOrSortOptions["source"] = filterValue != null && filterValue.length > 0 ? filterValues : undefined;
          break;
        case "functions":
          if (filterValue === null) {
            this.selectAll = false;
            this.filteredFunctions = undefined;
          }
          var filterValues = this.getFilterValuesForMultiSelect(filterValue, true);
          filterOrSortOptions["functions"] = filterValue != null && filterValue.length > 0 ? filterValues : undefined;
          break;
        case "result":
        case "state":
          var filterValues = this.getFilterValuesForMultiSelect(filterValue);
          filterOrSortOptions[filter] = filterValue != null && filterValue.length > 0 ? filterValues : undefined;
          break;
        case "duration":
          if(this.fromFilterValue != undefined || this.toFilterValue != undefined){
            var fromTime = this.fromFilterValue ? this.getOnlyTimeFromDate(this.fromFilterValue) : ""
            var toTime = this.toFilterValue ? this.getOnlyTimeFromDate(this.toFilterValue) : ""

            var rangeTime = fromTime + ',' + toTime
            filterOrSortOptions[filter] = rangeTime
          }
          else{
            filterOrSortOptions[filter] = "";
          }
          break;
        default:
          filterOrSortOptions[filter] = filterValue;
          break;
      }
    }
    
    this.exportOptionColumns = filterOrSortOptions;
    this.onPageChange.emit(filterOrSortOptions);
  }

  getFilterValuesForMultiSelect(filterValue: any, isFilterFunctions: boolean = false) {
    let filterValues = "";
    if (filterValue != null && filterValue.length > 0) {
      filterValue.forEach((val) => {
        if(isFilterFunctions){
          filterValues += val.id + ",";
        }
        else{
          filterValues += val.value + ","
        }
      })
      filterValues = filterValues.slice(0, -1)
    }
    return filterValues
  }

  redirectToItem($event) {
    if ($event.data.itemUrl) {
      this.router.navigate([$event.data.itemUrl])
    }
  }
    
  onDateSelect($event, field) {
    const eventDate = this.formatDate($event);

    if (this.dateRangeStart === undefined) {
        this.dateRangeStart = eventDate;
    } else if (moment($event).isBefore(this.dateRangeStart)) {
        this.dateRangeStart = eventDate;
        this.dateRangeEnd = undefined;
    } else if (moment($event).isSame(this.dateRangeStart) && this.dateRangeStart !== undefined && this.dateRangeEnd === undefined) {
        this.dateRangeEnd = eventDate;
    } else if (moment($event).isSame(this.dateRangeStart) && this.dateRangeStart !== undefined && this.dateRangeEnd !== undefined) {
        this.dateRangeStart = eventDate;
        this.dateRangeEnd = undefined;
    } else if (moment($event).isAfter(this.dateRangeStart) && this.dateRangeStart !== undefined && this.dateRangeEnd !== undefined) {
        this.dateRangeStart = eventDate;
        this.dateRangeEnd = undefined;
    } else {
        this.dateRangeEnd = eventDate;
    }

    if(this.dateRangeStart != undefined && this.dateRangeEnd != undefined){
      this.table.filter(this.dateRangeStart + "," + this.dateRangeEnd, field + "_range", 'customCreatedDateFilter');
    }
  }

  onDateClear($event, field) {
    this.dateRangeStart = undefined;
    this.dateRangeEnd = undefined;
    this.table.filter('', field + "_range", 'equals');
  }

  functionsToString(functions: any[]){
    var arrFunc = [""];
    functions.forEach((func) => {
      arrFunc.push(func.name);
    });
    arrFunc.shift();
    return arrFunc.toString();
  }

  formatDate(date) {
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    //return day + "/" + month + "/" + date.getFullYear();
    return date.getFullYear() + '-' + month + '-' + day;
  }

  getOnlyTimeFromDate(date){
    return moment(date).format('HH:mm:ss');
  }
  
  clearAllFilters(){
    let displayedColumns = this._selectedColumns.map((col) => ({
      field: col.field,
      header: col.header,
      visible: true
    }))

    this.resetFiltersForColumns(displayedColumns);
  }

}
