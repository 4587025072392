import { Component } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { RequestStateInterface } from '@app/shared/types/request-state.interface'
import { RequestInterface } from '@app/shared/types/request.interface'
import { RequestActionEnum } from '@app/shared/types/enums/request-action.enum'
import { Subject, takeUntil } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'
import { requiredIfCodeIsXValidator } from '@app/shared/validators/requiredIfCodeIsXValidator'
import { responsiblePartyValidator } from '@app/shared/validators/responsiblePartyValidator'
import { requestFunctionsValidator } from '@app/shared/validators/requestFunctionsValidator'
import * as RequestActions from '../../../store/actions/request.actions'
import { FunctionInterface } from '@app/shared/types/function.interface'
import { FunctionStateInterface } from '@app/shared/types/function.state.interface'
import { functionsSelector } from '@app/store/selectors/function.selector'
import { CreateRequestCommand } from '@app/shared/types/commands/createRequestCommand.model'
import { UserInfoStateInterface } from '@app/shared/types/user-info-state.interface'
import { getLioNumber, isLio10 } from '@app/store/selectors/user-info.selector'
import { FunctionStatusEnum } from '@app/shared/types/enums/function-status.enum'
import { MessageService } from 'primeng/api'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-deactivate-request',
  templateUrl: './deactivate-request.component.html',
  styleUrls: ['./deactivate-request.component.scss']
})
export class DeactivateRequestComponent {
  request: RequestInterface
  requestForm: FormGroup

  allFunctions: FunctionInterface[]

  visible = false
  minDate: Date = new Date()
  destroyed$ = new Subject<boolean>()
  isLio10: boolean = false
  actingLioNumber: number

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<RequestStateInterface>,
    private functionStore: Store<FunctionStateInterface>,
    private updates$: Actions,
    private userStore: Store<UserInfoStateInterface>,
    private messageService: MessageService,
    private datePipe: DatePipe
  ) {
    this.userStore.select(isLio10()).subscribe((usr) => (this.isLio10 = usr))
    this.userStore
      .select(getLioNumber())
      .subscribe((lioNumber) => (this.actingLioNumber = lioNumber))
    this.functionStore.select(functionsSelector).subscribe((fct) => (this.allFunctions = fct))

    this.requestForm = this.formBuilder.group({
      lioNumber: new FormControl({ value: null, disabled: true }, Validators.required),
      eicCodeType: new FormControl({ value: null, disabled: true }, Validators.required),
      eicRandomSequence: new FormControl(
        { value: null, disabled: true },
        Validators.compose([
          Validators.pattern('^[A-Z0-9-]+$'),
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12)
        ])
      ),
      eicCheckKey: new FormControl(
        { value: null, disabled: true },
        Validators.compose([
          Validators.pattern('^[A-Z0-9]+$'),
          Validators.required,
          Validators.maxLength(1)
        ])
      ),
      status: new FormControl({ value: 0, disabled: true }, Validators.required),
      deactivationDate: new FormControl(null, Validators.required),
      acerNumber: new FormControl(
        null,
        Validators.compose([Validators.minLength(12), Validators.maxLength(12),, 
          Validators.pattern("([A-Za-z0-9_]+\.[A-Z][A-Z])")])
      ),
      euvatCode: new FormControl(
        null,
        Validators.compose([requiredIfCodeIsXValidator, Validators.maxLength(25)])
      ),
      responsibleParty: new FormControl(
        null,
        Validators.compose([
          Validators.minLength(16),
          Validators.maxLength(16),
          responsiblePartyValidator
        ])
      ),
      eicParent: new FormControl(
        null,
        Validators.compose([Validators.minLength(16), Validators.maxLength(16)])
      ),
      eicLongName: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.maxLength(100)])
      ),
      eicDisplayName: new FormControl(
        null,
        Validators.compose([
          Validators.pattern('^[A-Z0-9+_-]+$'),
          Validators.maxLength(16),
          Validators.required
        ])
      ),
      address: new FormControl(null, this.actingLioNumber == 10 ? Validators.maxLength(70) : null),
      postalCode: new FormControl(
        null,
        this.actingLioNumber == 10 ? Validators.maxLength(10) : null
      ),
      city: new FormControl(null, this.actingLioNumber == 10 ? Validators.maxLength(35) : null),
      country: new FormControl(null, this.actingLioNumber == 10 ? Validators.maxLength(2) : null),
      contactDetailsName: new FormControl(
        null,
        this.actingLioNumber == 10 ? Validators.maxLength(70) : null
      ),
      contactDetailsEmail: new FormControl(
        null,
        this.actingLioNumber == 10
          ? Validators.compose([Validators.email, Validators.maxLength(70)])
          : null
      ),
      contactDetailsPhoneNumber: new FormControl(
        null,
        this.actingLioNumber == 10
          ? Validators.compose([Validators.pattern('^[+]?[0-9]+$'), Validators.maxLength(15)])
          : null
      ),
      functions: this.formBuilder.array([], requestFunctionsValidator),
      description: new FormControl(null, Validators.maxLength(700)),
      hasBeenSubmitted: new FormControl(false, null)
    })
  }

  ngOnInit() {}

  show(request: RequestInterface) {
    this.request = request
    this.visible = true

    if (this.request != null) {
      this.requestForm.patchValue(this.request)

      this.minDate.setMonth(this.minDate.getMonth() + 2)
      var newMinDate = this.datePipe.transform(this.minDate, 'yyyy-MM-dd')
      this.minDate = newMinDate ? new Date(newMinDate) : this.minDate    

      this.requestForm.controls['deactivationDate'].setValue(this.minDate)
      this.initFunctions()
    }
  }

  onSubmit() {
    var selectedFunctions: FunctionInterface[] = []
    this.functions.value.forEach((f) => {
      if (f.isSelected == true)
        selectedFunctions.push(this.allFunctions.find((fct) => fct.id == f.id)!)
    })

    if (this.requestForm.valid) {
      var newRequest: CreateRequestCommand = {
        parentRequestId: this.request.id,
        lioNumber: this.requestForm.controls['lioNumber'].value,
        eicCodeType: this.requestForm.controls['eicCodeType'].value,
        eicRandomSequence: this.requestForm.controls['eicRandomSequence'].value,
        eicCheckKey: this.requestForm.controls['eicCheckKey'].value,
        eicLongName: this.requestForm.controls['eicLongName'].value,
        eicDisplayName: this.requestForm.controls['eicDisplayName'].value,
        responsibleParty:
          this.requestForm.controls['responsibleParty'].value != ''
            ? this.requestForm.controls['responsibleParty'].value
            : null,
        eicParent:
          this.requestForm.controls['eicParent'].value != ''
            ? this.requestForm.controls['eicParent'].value
            : null,
        euVATCode:
          this.requestForm.controls['euvatCode'].value != ''
            ? this.requestForm.controls['euvatCode'].value
            : null,
        acerNumber:
          this.requestForm.controls['acerNumber'].value != ''
            ? this.requestForm.controls['acerNumber'].value
            : null,
        requestAction: RequestActionEnum.Deactivate,
        deactivationDate: new Date(this.requestForm.controls['deactivationDate'].value),
        address:
          this.requestForm.controls['address'].value != ''
            ? this.requestForm.controls['address'].value
            : null,
        postalCode:
          this.requestForm.controls['postalCode'].value != ''
            ? this.requestForm.controls['postalCode'].value
            : null,
        city:
          this.requestForm.controls['city'].value != ''
            ? this.requestForm.controls['city'].value
            : null,
        country:
          this.requestForm.controls['country'].value != ''
            ? this.requestForm.controls['country'].value
            : null,
        contactDetailsName:
          this.requestForm.controls['contactDetailsName'].value != ''
            ? this.requestForm.controls['contactDetailsName'].value
            : null,
        contactDetailsEmail:
          this.requestForm.controls['contactDetailsEmail'].value != ''
            ? this.requestForm.controls['contactDetailsEmail'].value
            : null,
        contactDetailsPhoneNumber:
          this.requestForm.controls['contactDetailsPhoneNumber'].value != ''
            ? this.requestForm.controls['contactDetailsPhoneNumber'].value
            : null,
        description: this.requestForm.controls['description'].value,
        functions: selectedFunctions.map((item) => item.id)
      }

      this.store.dispatch(RequestActions.createRequest({ request: newRequest }))

      this.updates$
        .pipe(ofType(RequestActions.CREATE_REQUEST_SUCCESSFUL), takeUntil(this.destroyed$))
        .subscribe(() => {
          this.visible = false
          this.requestForm.reset()
        })
    } else {
      // in order to display the validation error messages for the fields
      this.requestForm.controls['hasBeenSubmitted'].setValue(true)
      this.requestForm.controls['functions'].markAllAsTouched()
      this.requestForm.updateValueAndValidity()
      this.messageService.add({
        key: 'toast',
        severity: 'error',
        summary: 'Request is not created',
        detail: 'Check the fields for details'
      })
    }
  }

  initFunctions() {
    this.functions.clear()

    var eligibleFunctions = this.allFunctions.filter(
      (f) =>
        f.code == this.requestForm.controls['eicCodeType'].value &&
        (f.status == FunctionStatusEnum.Active ||
          this.request.functions.find((fd) => fd.id == f.id))
    )

    eligibleFunctions.forEach((f) => {
      const functionForm: FormGroup = this.formBuilder.group({
        id: f.id,
        functionName: [f.name + (f.status == FunctionStatusEnum.Inactive ? " (Inactive)" : "")],
        isSelected: this.request.functions.find((fd) => fd.name == f.name) != null ? true : false
      }) as FormGroup
      this.functions.push(functionForm)
    })
  }

  get functions() {
    return this.requestForm.get('functions') as FormArray
  }

  onClose() {
    this.functions.controls = []
    this.visible = false
    this.requestForm.reset()
    this.minDate = new Date()
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
