<app-homepage-messages></app-homepage-messages>

<div class="w-7 p-4 mb-7">
  <h1 class="h2">Central Issuing Office tool</h1>

  <p class="mb-0">
    The CIO tool has been designed to handle the main activities of the Central Issuing Office,
    namely:
  </p>

  <ol class="mt-0 mb-6">
    <li>The collection of the EIC codes which are issued by all the Local Issuing Offices;</li>
    <li>The validation of all the requests;</li>
    <li>The integration into the Master and Reference Database of ENTSO-E;</li>
    <li>The publication of all EIC codes.</li>
  </ol>

  <p>
    This tool intents to the improvement of the data quality, on the support by ENTSO-E and on the
    time reduction of the time between the requests and the final publication of all the EIC codes.
  </p>
</div>

<div class="grid gap-5 mb-6">
  <div class="col">
    <div class="flex gap-2 justify-content-between mb-3">
      <h2 class="h5">{{ !forLio ? 'Requests to approve' : 'Pending requests' }}</h2>
      <p-button label="More" severity="help" [text]="true" [routerLink]="!forLio ? ('/cio/requests') : ('lio/' + lioNumber + '/requests')" />
    </div>
    <div class="flex flex-column gap-3 pl-0 m-0">
        <app-request-home-table [requests$]="openRequests$" />
    </div>
  </div>
  
  <div *ngIf="forLio" class="col">
    <div class="flex gap-2 justify-content-between mb-3">
      <h2 class="h5">Latest requests</h2>
      <p-button label="More" severity="help" [text]="true" [routerLink]="'lio/' + lioNumber + '/requests'" />
    </div>
    <div class="flex flex-column gap-3 pl-0 m-0">
      <app-request-home-table [requests$]="latestRequests$" [isLioPage]="true" [lioNumber]="lioNumber"/>
    </div>
  </div>
</div>

<div class="grid mb-5">
  <div class="col">
    <p-panel >
      <ng-template pTemplate="header">
        <div class="flex-1 flex gap-2 justify-content-between">
          <div class="flex align-items-center gap-2">
            <span class="font-semibold">Useful links to EE website</span>
            <i class="icon icon--external-link icon--lg opacity-60"></i>
          </div>
        </div>
      </ng-template>
      <ul class="flex flex-column gap-2 pl-2 m-0 list-none">
        <li *ngFor="let link of PublicWebsiteUsefulLinks" class="home-doc">
          <a class="link-style" target="_blank" [href]="link.value" style="text-decoration: none;">{{link.label}}</a>
        </li>
      </ul>
    </p-panel>
  </div>
  <div class="col">
    <p-panel >
      <ng-template pTemplate="header" >
        <div class="flex-1 flex gap-2 justify-content-between">
          <div class="flex align-items-center gap-2">
            <span class="font-semibold">EIC documents</span>
            <i class="icon icon--document icon--lg opacity-60"></i>
          </div>
        </div>
      </ng-template>
      <ul class="flex flex-column gap-2 pl-0 m-0 list-none" style="min-height: 26em;">
        <li *ngFor="let doc of homepageDocuments" class="home-doc">
          <a [href]="'assets/homepage-docs/'+doc" target="_blank" style="text-decoration: none;" >{{doc}}</a>
        </li>
      </ul>
    </p-panel>
  </div>
</div>
