import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent {

  @Input() request: Request // to be deleted after the rest of components using it are done
  @Input() descriptionControl: FormControl;

}

