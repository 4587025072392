<header>
  <div
    class="flex align-items-center justify-content-between py-2 px-5 bg-secondary"
  >
    <a href="#" class="p-3">
      <img src="/assets/img/logo.svg" alt="logo" />
    </a>

    <div class="flex align-items-center gap-3">

      <legend style="font-size: 15px;">{{userEmail}}</legend> 
      <p-button 
      class="logoutBtn"
      (click)="onLogOut()">
      <i style="padding:1rem" class="icon icon--logout"></i>
      </p-button>
      
    </div>
  </div>
</header>
