import { Component } from '@angular/core'
import { CioMenuItems } from '@app/shared/helpers/cio-side-bar-menu';
import { LioMenuItems } from '@app/shared/helpers/lio-side-bar-menu';
import { UserInfoStateInterface } from '@app/shared/types/user-info-state.interface';
import { UserInfoInterface } from '@app/shared/types/user-info.interface';
import { currentUserSelector } from '@app/store/selectors/user-info.selector';
import { Store, select } from '@ngrx/store';
import { MenuItem } from 'primeng/api'
import { Observable } from 'rxjs';
import * as UserActions from '../../store/actions/user-info.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { ReportsSideBarMenu } from '@app/shared/helpers/reports-side-bar-menu';
import { SideBarMenu } from '@app/shared/helpers/side-bar-menu';
import { ExportsSideBarMenu } from '@app/shared/helpers/exports-side-bar-menu';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent {
  currentUser$: Observable<UserInfoInterface>;

  defaultItems: MenuItem[] = new SideBarMenu().menu;
  reportsItems: MenuItem[] = new ReportsSideBarMenu().menu;
  userItems: MenuItem[] | [];
  impersonationItems: MenuItem[] | [];
  exportsItems: MenuItem[] | [];

  constructor(private userStore: Store<UserInfoStateInterface>) {
    this.currentUser$ = this.userStore.pipe(select(currentUserSelector));
  }

  ngOnInit() {
    this.currentUser$.subscribe((currentUser: UserInfoInterface) => {
        if(currentUser.isLio10){
          this.userItems = new CioMenuItems().menu;

          if(currentUser.isImpersonating){
            let lioSideBarMenu = new LioMenuItems(currentUser.lioNumber!.toString(), this.removeImpersonate.bind(null, this.userStore));
            this.impersonationItems = lioSideBarMenu.impersonationMenu;
          }
          this.exportsItems = new ExportsSideBarMenu().menu;
        }
        else{
          this.userItems = new LioMenuItems(currentUser.lioNumber!.toString(), this.removeImpersonate.bind(null, this.userStore)).menu;
        }
			}
		);
  }

  removeImpersonate(store: { dispatch: (arg0: TypedAction<"[USERINFO Component] Stop lio impersonation">) => void; }) {
    store.dispatch(UserActions.stopLioImpersonation());
  }

  toggleSubMenu(el) {
    if(el.target.children.length == 0){
      el.target.parentElement.nextElementSibling.firstChild.classList.toggle("hideMenuItemsContainer");
      el.target.parentElement.getElementsByTagName("i")[0].classList.toggle("active")
    }
    else{
      el.target.nextSibling.firstChild.classList.toggle("hideMenuItemsContainer");
      el.target.getElementsByTagName("i")[0].classList.toggle("active")
    }
  }
}
