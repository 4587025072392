import { Component, Input, OnInit } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { saveAs } from 'file-saver';

import * as exportsActions from "app/store/actions/export.actions";


@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent  implements OnInit {
  @Input() selectedRow: any[];  

  constructor(private exportsStore: Store, private actions$: Actions) {}

  ngOnInit(): void {}

  download(){
    this.exportsStore.dispatch(exportsActions.downloadTP({eicId: this.selectedRow[0]['id']}))
    this.actions$.subscribe((data) => {
      if(data["export"] != undefined){
        saveAs(data["export"], this.selectedRow[0]['eicCode'] + '.xml')
      }
    });
  }
}
