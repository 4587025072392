export enum DeactivatedCodestXmlNodes {
    REPORT = "Report",
    NAMESPACE_SCHEMA_LOCATION = "/assets/reporting/deactivated_codes.xsd",
    NAMESPACE_NAME = "deactivated_codes.xsd",
    NAMESPACE_XSI = "http://www.w3.org/2001/XMLSchema-instance",
    NAMESPACE = "deactivated_codes.xsd",

    TABLIX1 = "Tablix1",
    DEATILS_COLLECTION = "Details_Collection",
    DETAILS = "Details"
}
    