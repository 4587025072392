<div class="p-24px bg-white border-round">
    <div class="flex flex-column mb-5">
      <h1 class="h3 text-secondary">Transparency platform exports</h1>
    </div>
    
    <app-table
        [values]="allEics"
        [visibilityColumnsHeader]="true"
        [visibilityColumnsHeaderObj]="visibilityColumnsHeaderObj"
        [settingType]="settingType"
        (onRowExpand)="getEICVersions($event)"
        [expandableRows]="true"
        [requestActionFilterOptions]="requestActionFilterOptions"
        [statusFilterOptions]="eicStatusFilterOptions"
        [requestActionsList]="requestActionsList"        
        [statusList]="eicStatusList"
        [eicCodeTypesOptions]="eicCodeTypesOptions"
        [totalRecords]="totalRecords"
        [firstRow]="firstRow"
        export="true"
        [hideExportSelection]="true"
        download="true"
        tpHistory="true"
        [exportPlace]="exportPlace"
        [selectionMode]="'multiple'"
        [checkBoxMultiSelect]="true"
        (onRowChange)="onRowChange($event)"
        (onPageChange)="onPageChange($event)"
        [selectedRows]="selectedRows"
        [filterOptions]="tableFilterOrSortOptions">
    </app-table>
</div>

