import { SelectItem } from "primeng/api";

export enum AvailableMarketsEnum{
    Electricity = 0,
    Gas = 1
}

export const availableMarketsList: SelectItem[] = [
    {label: "Electricity", value: 0},
    {label: "Gas", value: 1}
]