import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ReportInterface } from '@app/shared/types/report.interface'

export const getActiveEIC = createSelector(
  createFeatureSelector('report'),
  (state: ReportInterface) => {
    return state.activeEIC;
  }
);

export const getEICCodesAll = createSelector(
  createFeatureSelector('report'),
  (state: ReportInterface) => {
    return state.eicCodesAll;
  }
);

export const getDeactivatedCodes = createSelector(
  createFeatureSelector('report'),
  (state: ReportInterface) => {
    return state.dectivatedCodes;
  }
);

export const getAllApprovedRequests = createSelector(
  createFeatureSelector('report'),
  (state: ReportInterface) => {
    return state.allApprovedRequests;
  }
);

export const isLoadingSelector = createSelector(
  createFeatureSelector('isLoading'),
  (state: ReportInterface) => {
    return state.isLoading
  }
);

export const errorSelector = createSelector(
  createFeatureSelector('error'),
  (state: ReportInterface) => {
    return state.error
  }
);
export const totalRecordsSelector = createSelector(
  createFeatureSelector('report'),
  (state: ReportInterface) => {
      return state.totalRecords
  }
)
