import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, mergeMap, catchError } from 'rxjs/operators'
import * as settingActions from '../actions/setting.actions'
import { MessageService } from 'primeng/api'
import { SettingsService } from '@app/shared/services/settings.service'

@Injectable()
export class SettingEffect {
  constructor(
    private actions$: Actions,
    private settingsService: SettingsService,
    private messageService: MessageService
  ) {}

  public getSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingActions.getSettings),
      mergeMap(() => {
        return this.settingsService.getAllSettings().pipe(
          map((settings) => {
            if (settings != null) {
              return settingActions.getSettingsSuccessful({ setting: settings })
            }
            return settingActions.getSettingsSuccessful({ setting: [] })
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get Settings failed.'
            })
            return of(settingActions.getSettingsFailed({ error: error.message }))
          })
        )
      })
    )
  )

  public createSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingActions.createSetting),
      mergeMap(({ setting: setting }) => {
        return this.settingsService.createSetting(setting).pipe(
          map(() => {
            this.messageService.add({
              key: 'toast',
              severity: 'success',
              summary: 'Success',
              detail: 'Setting creation succesfull'
            })
            return settingActions.createSettingSuccessfully({ setting: setting })
          }),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Setting creation failed',
              detail: error.message
            })
            return of(settingActions.createSettingFailed(error))
          })
        )
      })
    )
  )
}
