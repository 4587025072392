import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TpHistoryStateInterface } from "@app/shared/types/tp-history-state.interface";

export const historySelector = createSelector(
    createFeatureSelector('history'),
    (state: TpHistoryStateInterface) => { 
        return state.tpHistory
    }
)
export const isLoadingSelector = createSelector(
    createFeatureSelector('history'),
    (state: TpHistoryStateInterface) => { 
        return state.isLoading
    }
)
export const errorSelector = createSelector(
    createFeatureSelector('history'),
    (state: TpHistoryStateInterface) => { 
        return state.error
    }
)
export const totalRecordsSelector = createSelector(
    createFeatureSelector('history'),
    (state: TpHistoryStateInterface) => {
        return state.totalRecords
    }
)
export const pageNumberSelector = createSelector(
    createFeatureSelector('history'),
    (state: TpHistoryStateInterface) => {
        return state.pageNumber
    }
)
export const rowsPerPageSelector = createSelector(
    createFeatureSelector('history'),
    (state: TpHistoryStateInterface) => {
        return state.rowsPerPage
    }
)