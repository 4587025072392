import { SelectItem } from "primeng/api"

export enum EICCodeTypeEnum {
    A = "Substations (A)",
    T = "Tie-line (T)", 
    V = "Location (V)",
    X = "Location (V)",
    Y = "Area (Y)",
    Z = "Measurement point (Z)",
    W = "Resource object (W)"
}

export const eicCodeTypesList: SelectItem[] = [
    {label: "Substations (A)", value: 'A'},
    {label: "Tie-line (T)", value: 'T'},
    {label: "Location (V)", value: 'V'},
    {label: "Party (X)", value: 'X'},
    {label: "Area (Y)", value: 'Y'},
    {label: "Measurement point (Z)", value: 'Z'},
    {label: "Resource object (W)", value: 'W'}
]
    
export const eicCodeTypesDictionary : {[index: string]: string } = { 
    'A': "Substations (A)",
    'T': "Tie-line (T)",
    'V': "Location (V)",
    'X': "Party (X)",
    'Y': "Area (Y)",
    "Z": "Measurement point (Z)",
    'W': "Resource object (W)"
}