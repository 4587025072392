import { Component, Input } from '@angular/core'
import { requestActionsList } from '@app/shared/types/enums/request-action.enum';
import { RequestStatusEnum, requestStatusList } from '@app/shared/types/enums/request-status.enum';
import { RequestInterface } from '@app/shared/types/request.interface'
import { Observable } from 'rxjs';

@Component({
  selector: 'app-request-home-table',
  templateUrl: './request-home-table.component.html',
  styleUrls: ['./request-home-table.component.scss']
})
export class RequestLineComponent {
  
  @Input() lioNumber: number
  @Input() isLioPage: boolean
  @Input() requests$: Observable<RequestInterface[]>

  requestStatuses = requestStatusList;
  requestActions = requestActionsList;

  get RequestStatusEnum(){
    return RequestStatusEnum
  }
}
