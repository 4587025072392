import { AbstractControl, Validators } from '@angular/forms'

export function requiredIfIsLio10Validator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null
  }

  return formControl.parent.get('lioNumber')?.value == '10'
    ? Validators.required(formControl)
    : null
}
