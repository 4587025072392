import { SelectItem } from "primeng/api"
import { StatusObj } from "../request.interface"

export enum RequestStatusEnum{
    Pending = 0,
    Approved = 1,
    Rejected = 2
}

export const requestStatusList: SelectItem[] = [
    {label: "Pending", value: 0, icon: 'warning'},
    {label: "Approved", value: 1, icon: 'success'},
    {label: "Rejected", value: 2, icon: 'danger'},
]

export const requestStatusFilterOptions : StatusObj[] = [
    {label: 'Pending', value: 0},
    {label: 'Approved', value: 1},
    {label: 'Rejected', value: 2}
  ]
