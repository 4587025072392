export const environment = {
  production: false,
  apiBaseUrl: 'https://cio-api-dev.entsoe.eu',
  sftp: {
    connectionStringPart1: 'sasftpxmluploaddev.',
    connectionStringPart2: '@sasftpxmluploaddev.blob.core.windows.net'
  },
  keycloak: {
    url: 'https://keycloak-uat.entsoe.eu/auth',
    clientId: 'CIO-LIO-UAT'
  },
  envBaseUrl: 'https://cio-dev.entsoe.eu',
  adminEmail: 'cio-admin@entsoe.eu'
}
