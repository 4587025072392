import * as UserInfoActions from '../actions/user-info.actions';
import { UserInfoStateInterface } from "@app-shared-types/user-info-state.interface";
import { createReducer, on } from "@ngrx/store";
import { immerOn } from "ngrx-immer/store";

const initialState: UserInfoStateInterface = {
    currentUser: {
        isLio10: false,
        lioNumber: null,
        isImpersonating: false,
    }
};

export const userInfoReducer = createReducer(
    { ...initialState },
    on(UserInfoActions.setUserInfo, (state, action) => ({
        ...state,
        currentUser: action.currentUser
    })),
    immerOn(UserInfoActions.impersonateLio, (state, action) => {
        state.currentUser.isImpersonating = true;
        state.currentUser.lioNumber = action.lioNumber;
	}),
    immerOn(UserInfoActions.stopLioImpersonation, (state) => {
        state.currentUser.isImpersonating = false;
        state.currentUser.lioNumber = 10;
	}),
)