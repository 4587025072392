import { Component, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { RequestStateInterface } from '@app/shared/types/request-state.interface'
import { Store } from '@ngrx/store'
import {
  pageNumberSelector,
  requestsSelector,
  rowsPerPageSelector
} from '@app/store/selectors/request.selector'
import { Observable, Subscription } from 'rxjs'
import { RequestInterface } from '@app/shared/types/request.interface'
import * as RequestActions from '../../../store/actions/request.actions'
import { RequestStatusEnum, requestStatusList } from '@app/shared/types/enums/request-status.enum'
import { RequestActionEnum, requestActionsList } from '@app/shared/types/enums/request-action.enum'
import { PendingRequestActionEnum } from '@app/shared/types/enums/pending-request-action.enum'
import { UpdateRequestComponent } from '../update-request/update-request.component'
import { UserInfoStateInterface } from '@app/shared/types/user-info-state.interface'
import { UserInfoInterface } from '@app/shared/types/user-info.interface'
import { currentUserSelector } from '@app/store/selectors/user-info.selector'
import { Location } from '@angular/common'
import { DateService } from '@app/shared/services/date-service'

@Component({
  selector: 'app-cio-request-detail',
  templateUrl: './cio-request-detail.component.html',
  styleUrls: ['./cio-request-detail.component.scss']
})
export class CioRequestDetailComponent {
  @ViewChild('modalUpdateRequestComponent') modalUpdateRequestComponent: UpdateRequestComponent

  requestId: number
  requestsSub: Subscription
  requests$: Observable<RequestInterface[]>
  request: RequestInterface

  canUpdate: boolean = false
  currentUser: UserInfoInterface
  userSub: Subscription

  requestDiff: RequestInterface
  parentRequest: RequestInterface
  requestFunctions: string[] = []
  previousRequestFunctions: string[] = []

  requestStatusList = requestStatusList
  requestActionsList = requestActionsList
  RequestStatusEnum = RequestStatusEnum
  RequestActionEnum = RequestActionEnum
  PendingRequestActionEnum = PendingRequestActionEnum

  pageNumber: number
  rowsPerPage: number
  pageSub: Subscription
  rowsSub: Subscription
  count: number = 0
  history: string[] = []
  constructor(
    private route: ActivatedRoute,
    private store: Store<RequestStateInterface>,
    private userStore: Store<UserInfoStateInterface>,
    private router: Router,
    private location: Location,
    private dateService: DateService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
    this.requestId = parseInt(this.route.snapshot.paramMap.get('requestId')!, 10)
    this.requests$ = this.store.select(requestsSelector)

    this.userSub = this.userStore.select(currentUserSelector).subscribe((user) => {
      this.currentUser = user
    })
    this.pageSub = this.store.select(pageNumberSelector).subscribe((page) => {
      this.pageNumber = page
    })
    this.rowsSub = this.store.select(rowsPerPageSelector).subscribe((rows) => {
      this.rowsPerPage = rows
    })
  }

  ngOnInit() {
    this.requestsSub = this.requests$.subscribe((reqs) => {
      if (reqs != undefined && reqs?.find((r) => r.id == this.requestId)) {
        this.request = reqs.find((r) => r.id == this.requestId)!
        this.initAvailableActions()
        this.requestFunctions = this.getSelectedFunctions(this.request)

        // show comparison with parent request (if it exists ) for pending requests
        if (this.request.status == RequestStatusEnum.Pending && this.request.parentRequestId! > 0) {
          this.parentRequest = reqs.find((r) => r.id == this.request.parentRequestId)!
          if (this.parentRequest) {
            this.requestDiff = this.differenceInObj(this.parentRequest, this.request)
            if (this.requestDiff.functions != null) {
              this.previousRequestFunctions = this.getSelectedFunctions(this.parentRequest)
            }
          } else {
            this.store.dispatch(
              RequestActions.getRequestById({ id: this.request.parentRequestId! })
            )
          }
        }
      } else {
        this.store.dispatch(RequestActions.getRequestById({ id: this.requestId }))
      }
    })
  }

  differenceInObj(firstObj: any, secondObj: any): any {
    let differenceObj: any = {}
    for (const key in firstObj) {
      if (Object.prototype.hasOwnProperty.call(firstObj, key)) {
        if (firstObj[key] != secondObj[key]) {
          differenceObj[key] = firstObj[key]
        }
      }
    }
    return differenceObj
  }

  initAvailableActions() {
    this.canUpdate = this.request.isMostRecent! && this.request.status == RequestStatusEnum.Pending
  }

  getSelectedFunctions(request: RequestInterface) {
    return request.functions.map((r) => r.name)
  }

  goToPrevious() {
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this.router.navigate(['cio/requests'])
    }
  }

  getButtonLabel() {
    if (this.request.status == RequestStatusEnum.Pending) {
      return 'Edit request'
    }
    if (
      this.request.status == RequestStatusEnum.Rejected &&
      this.request.requestAction == RequestActionEnum.Create
    ) {
      return 'Create'
    }
    return 'Update'
  }

  formatDateToCET(date: string){
    date = this.dateService.formatDateToCET(date)
    return date
  }

  ngOnDestroy() {
    this.requestsSub.unsubscribe()
    this.userSub?.unsubscribe()
    this.rowsSub.unsubscribe()
    this.pageSub.unsubscribe()
  }
}
