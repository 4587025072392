import { Component } from '@angular/core'
import { AuthService } from '@app/authentication/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  userEmail: string;
  constructor(private authService: AuthService){}

  ngOnInit() {
    this.userEmail = this.authService.getUserEmail()
  }

  onLogOut(){
    this.authService.logOut();
  }
}
