import { APP_INITIALIZER, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { MenubarModule } from 'primeng/menubar'
import { ButtonModule } from 'primeng/button'
import { AvatarModule } from 'primeng/avatar'
import { SidebarModule } from 'primeng/sidebar'
import { MenuModule } from 'primeng/menu'
import { MessagesModule } from 'primeng/messages'
import { PanelModule } from 'primeng/panel'
import { TagModule } from 'primeng/tag'
import { CardModule } from 'primeng/card'
import { TableModule } from 'primeng/table'
import { ToolbarModule } from 'primeng/toolbar'
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { FieldsetModule } from 'primeng/fieldset'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputTextModule } from 'primeng/inputtext'
import { DropdownModule } from 'primeng/dropdown'
import { CalendarModule } from 'primeng/calendar'
import { CheckboxModule } from 'primeng/checkbox'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { TooltipModule } from 'primeng/tooltip'
import { ListboxModule } from 'primeng/listbox'
import { DialogModule } from 'primeng/dialog'
import { RadioButtonModule } from 'primeng/radiobutton'
import { StoreModule } from '@ngrx/store'
import { ProgressBarModule } from 'primeng/progressbar';

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { AppLayoutComponent } from './layouts/app-layout/app-layout.component'
import { TintedLayoutComponent } from './layouts/tinted-layout/tinted-layout.component'
import { RouterLayoutComponent } from './layouts/router-layout/router-layout.component'

import { HomeComponent } from './views/home/home.component'
import { RequestOverviewComponent } from './views/request/request-overview/request-overview.component'
import { ModalInviteComponent } from './components/modal/modal-invite/modal-invite.component'
import { CreateRequestComponent } from './views/request/create-request/create-request.component'
import { UpdateRequestComponent } from './views/request/update-request/update-request.component'
import { DeactivateRequestComponent } from './views/request/deactivate-request/deactivate-request.component'
import { CioRequestDetailComponent } from './views/request/cio-request-detail/cio-request-detail.component'
import { LioOverviewComponent } from './views/lio/lio-overview/lio-overview.component'
import { NewLioComponent } from './views/lio/new-lio/new-lio.component'
import { LioDetailComponent } from './views/lio/lio-detail/lio-detail.component'
import { ModalChangeStatusComponent } from './components/modal/modal-change-status/modal-change-status.component'
import { ReactivateRequestComponent } from './views/request/reactivate-request/reactivate-request.component'
import { LioRequestOverviewComponent } from './views/lio/lio-request/lio-request-overview/lio-request-overview.component'

import { HeaderComponent } from './components/header/header.component'
import { SideBarComponent } from './components/side-bar/side-bar.component'
import { RequestLineComponent } from './components/request-home-table/request-home-table.component'
import { AllocatedEicCodeComponent } from './components/forms/allocated-eic-code/allocated-eic-code.component'
import { EicIdentificationComponent } from './components/forms/eic-identification/eic-identification.component'
import { ContactDetailsComponent } from './components/forms/contact-details/contact-details.component'
import { FunctionComponent } from './components/forms/function/function.component'
import { DescriptionComponent } from './components/forms/description/description.component'
import { LioComponent } from './components/forms/lio/lio.component'
import { MessageService } from 'primeng/api'
import { initializeKeycloak } from './init/keycloak-init.factory'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { APIInterceptor } from './interceptors/apiInterceptor'
import { EICService } from './shared/services/eic.service'
import { eicReducer } from './store/reducers/eic.reducer'
import { EffectsModule } from '@ngrx/effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { EICEffect } from './store/effects/eic.effects'
import { CioEicOverviewComponent } from './views/cio-eic-overview/cio-eic-overview.component'
import { CommonModule, DatePipe } from '@angular/common'
import { requestReducer } from './store/reducers/request.reducer'
import { RequestEffect } from './store/effects/request.effects'
import { RequestService } from './shared/services/request.service'
import { environment } from 'environments/environment'
import { LioEffect } from './store/effects/lio.effects'
import { lioReducer } from './store/reducers/lio.reducer'
import { LioService } from './shared/services/lio.service'
import { SelectButtonModule } from 'primeng/selectbutton'
import { userInfoReducer } from './store/reducers/user-info.reducer'
import { FileUploadModule } from 'primeng/fileupload'
import { ToastModule } from 'primeng/toast'
import { MultiSelectModule } from 'primeng/multiselect'
import { settingReducer } from './store/reducers/setting.reducer'
import { SettingEffect } from './store/effects/settings.effects'
import { SettingsService } from './shared/services/settings.service'
import { TableComponent } from './components/table/table.component'
import { VersionCardComponent } from './components/version-card/version-card.component'
import { functionReducer } from './store/reducers/function.reducer'
import { FunctionEffect } from './store/effects/function.effects'
import { FunctionService } from './shared/services/function.service'
import { ModalUploadXmlDocumentComponent } from './components/modal/modal-upload-xml-document/modal-upload-xml-document.component'
import { ActiveEicCodesComponent } from './views/reports/active-eic-codes/active-eic-codes.component'
import { EicCodesAllComponent } from './views/reports/eic-codes-all/eic-codes-all.component'
import { DeactivatedCodesComponent } from './views/reports/deactivated-codes/deactivated-codes.component'
import { AllApprovedRequestsComponent } from './views/reports/all-approved-requests/all-approved-requests.component'
import { ExportListComponent } from './components/export-list/export-list.component'
import { reportReducer } from './store/reducers/report.reducer'
import { ReportEffect } from './store/effects/report.effect'
import { ReportsService } from './shared/services/reports.service'
import { CioGuard } from './authentication/guards/cio.guard'
import { AuthenticationModule } from './authentication/authentication.module'
import { AuthService } from './authentication/auth.service'
import { CioLioGuard } from './authentication/guards/ciolio.guard';
import { TpExportsComponent } from './views/exports/tp-exports/tp-exports.component';
import { DownloadComponent } from './components/download/download.component'
import { ExportsService } from './shared/services/exports.service'
import { ExportEffect } from './store/effects/export.effect';
import { TpHistoryComponent } from './components/tp-history/tp-history.component'
import { LoaderInterceptor } from './interceptors/loader.interceptor'
import { LoaderService } from './shared/services/loader.service';
import { CountryComponent } from './components/country/country.component';
import { ModalTpHistoryComponent } from './components/modal/modal-tp-history/modal-tp-history.component'
import { exportReducer } from './store/reducers/export.reducer'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { messageReducer } from './store/reducers/message.reducer'
import { HomepageMessageService } from './shared/services/homepage-message.service'
import { MessageEffect } from './store/effects/message.effects'
import { ModalUpdateLioInfosComponent } from './components/modal/modal-update-lio-infos/modal-update-lio-infos.component'
import { HomepageMessagesComponent } from './components/homepage-messages/homepage-messages.component'
import { ModalConfigureSftpComponent } from './components/modal/modal-configure-sftp/modal-configure-sftp.component'
import { historyReducer } from './store/reducers/history.reducer'
import { HistoryEffect } from './store/effects/history.effects'
import { HistoryService } from './shared/services/history.service'
import { LioEicOverviewComponent } from './views/lio-eic-overview/lio-eic-overview.component'
import { UserInfoEffect } from './store/effects/user-info.effect'
import { LioRequestDetailComponent } from './views/request/lio-request-detail/lio-request-detail.component'
import { DateService } from './shared/services/date-service'
import { MaintenanceViewComponent } from './views/maintenance-view/maintenance-view.component'
import { maintenanceReducer } from './store/reducers/maintenance.reducer'
import { Router } from '@angular/router';
import { ModalDeleteLioDocumentComponent } from './components/modal/modal-delete-lio-document/modal-delete-lio-document.component'

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    HeaderComponent,
    SideBarComponent,
    TableComponent,
    HomeComponent,
    RequestLineComponent,
    TintedLayoutComponent,
    CreateRequestComponent,
    AllocatedEicCodeComponent,
    EicIdentificationComponent,
    ContactDetailsComponent,
    FunctionComponent,
    DescriptionComponent,
    RouterLayoutComponent,
    RequestOverviewComponent,
    UpdateRequestComponent,
    DeactivateRequestComponent,
    CioRequestDetailComponent,
    LioRequestDetailComponent,
    LioOverviewComponent,
    NewLioComponent,
    LioComponent,
    LioDetailComponent,
    ModalInviteComponent,
    ModalChangeStatusComponent,
    ModalTpHistoryComponent,
    ModalConfigureSftpComponent,
    ReactivateRequestComponent,
    LioRequestOverviewComponent,
    CioEicOverviewComponent,
    LioEicOverviewComponent,
    TableComponent,
    VersionCardComponent,
    TableComponent,
    ModalUploadXmlDocumentComponent,
    ActiveEicCodesComponent,
    EicCodesAllComponent,
    DeactivatedCodesComponent,
    AllApprovedRequestsComponent,
    ExportListComponent,
    TpExportsComponent,
    DownloadComponent,
    TpHistoryComponent,
    CountryComponent,
    HomepageMessagesComponent,
    ModalUpdateLioInfosComponent,
    MaintenanceViewComponent,
    ModalDeleteLioDocumentComponent
  ],
  imports: [
    AuthenticationModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MultiSelectModule,
    AppRoutingModule,
    MenubarModule,
    ButtonModule,
    SelectButtonModule,
    AvatarModule,
    SidebarModule,
    MenuModule,
    MessagesModule,
    PanelModule,
    TagModule,
    CardModule,
    TableModule,
    ToolbarModule,
    OverlayPanelModule,
    FieldsetModule,
    InputNumberModule,
    InputTextModule,
    DropdownModule,
    FormsModule,
    ProgressBarModule,
    ReactiveFormsModule,
    CalendarModule,
    CheckboxModule,
    InputTextareaModule,
    TooltipModule,
    ListboxModule,
    DialogModule,
    RadioButtonModule,
    FileUploadModule,
    ToastModule,
    ProgressSpinnerModule,
    KeycloakAngularModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreModule.forFeature('functions', functionReducer),
    StoreModule.forFeature('eics', eicReducer),
    StoreModule.forFeature('requests', requestReducer),
    StoreModule.forFeature('lios', lioReducer),
    StoreModule.forFeature('settings', settingReducer),
    StoreModule.forFeature('report', reportReducer),
    StoreModule.forFeature('export', exportReducer),
    StoreModule.forFeature('messages',messageReducer),
    StoreModule.forFeature('history',historyReducer),
    StoreModule.forFeature('maintenance', maintenanceReducer),
    EffectsModule.forFeature([
      EICEffect,
      LioEffect,
      RequestEffect,
      SettingEffect,
      FunctionEffect,
      ReportEffect,
      ExportEffect,
      MessageEffect,
      HistoryEffect,
      UserInfoEffect
    ]),
    StoreModule.forFeature('currentUser', userInfoReducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true // Pauses recording actions and state changes when the extension window is not open
    })
  ],
  providers: [
    MessageService,
    EICService,
    RequestService,
    LioService,
    SettingsService,
    ReportsService,
    FunctionService,
    ExportsService,
    HistoryService,
    LoaderService,
    AuthService,
    LoaderService,
    HomepageMessageService,
    DateService,
    CioGuard,
    CioLioGuard,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true
    },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: LoaderInterceptor, 
      multi: true 
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, Router]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
