import { AbstractControl } from "@angular/forms";

export function requestFunctionsValidator(formControl: AbstractControl) {
  
  if (!formControl.parent) {
    return null;
  }
  
  return formControl.value.some(e => e.isSelected == true) ? null : { noFunctionSelected: true };
  
}