<p-dialog
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '80vw' }"
  [closable]="false"
  position="top"
>
    <ng-template pTemplate="header">
        <h2 class="h5">Transparency platform upload history</h2>
    </ng-template>


    <div class="container" style="margin-top: 5px;">
        <app-table
            [values]="history"
            [settingType]="settingType"  
            [totalRecords]="totalRecords"
            [showCaption]="false"      
            [tpHistoryState]="tpHistoryState"
            [tpHistoryResult]="tpHistoryResult"
            selectionMode="false"
            styleClass="p-datatable-gridlines"
            tableStyleClass="table-layout-fixed border-round"
            (onPageChange)="onPageChange($event)"
            [firstRow]="firstRow">
        </app-table>
    </div>

    <ng-template pTemplate="footer">
        <div class="flex justify-content-end gap-3">
        <p-button
            label="Close"
            styleClass="p-button-outlined p-button-secondary"
            [outlined]="true" severity="secondary"
            (click)="onClose()"
        ></p-button>
        </div>
    </ng-template>

</p-dialog>