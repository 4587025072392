import { createReducer, on } from '@ngrx/store';
import * as functionActions from '../actions/function.actions';
import { FunctionStateInterface } from '@app/shared/types/function.state.interface';

export const initialState: FunctionStateInterface = {
    isLoading: false,
	functions: [],
	error: null
};

export const functionReducer = createReducer(
    { ...initialState },
    on(functionActions.getFunctions, (state) => ({
		...state,
		isLoading: true 
	})),
	on(functionActions.getFunctionsSuccessfully, (state, action) => ({
		...state,
		isLoading: false,
		functions: action.functions 
	})),
	on(functionActions.getFunctionsFailed, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	})),

);
	