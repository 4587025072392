<div>
  <p-button
    *ngIf="isLio10"
    label="back"
    (click)="goToPrevious()"
    styleClass="p-button-outlined p-button-secondary mb-3"
    [outlined]="true"
    severity="secondary"
    icon="icon icon--arrow-left"
  ></p-button>

  <section class="p-24px bg-white border-round">
    <div class="flex align-items-center justify-content-between gap-5">
      <h1 class="mb-3 h3 text-secondary">LIO {{ lioNumber }}</h1>
    </div>

    <p-card header="Users" styleClass="mb-5">
      <app-table
        [values]="users"
        selectionMode="false"
        styleClass="p-datatable-gridlines"
        tableStyleClass="table-layout-fixed border-round"
        [settingType]="settingType"
        [totalRecords]="totalRecords"
        [showCaption]="false"
        [nrOfRows]="50"
      ></app-table>
    </p-card>

    <p-card *ngIf="lio" header="Info" styleClass="mb-5">
      <p-button
        *ngIf="isLio10"
        label="Update information"
        (onClick)="modalUpdateLioInfos.showDialog()"
      />

      <ul class="description-list w-60rem">
        <li class="description-list__row">
          <p>LIO number:</p>
          <p>{{ lio.lioNumber ?? '-' }}</p>
        </li>
        <li class="description-list__row">
          <p>Owner:</p>
          <p>{{ lio.createdBy ?? '-' }}</p>
        </li>
        <li class="description-list__row">
          <p>Creation date:</p>
          <p>{{ (lio.creationDate + 'Z' | date: 'dd/MM/YYYY') ?? '-' }}</p>
        </li>
        <li class="description-list__row">
          <p>Name of LIO:</p>
          <p>{{ lio.name ?? '-' }}</p>
        </li>
        <li class="description-list__row">
          <p>Name of contact person:</p>
          <p>{{ lio.contactPersonName ?? '-' }}</p>
        </li>
        <li class="description-list__row">
          <p>Email of contact person:</p>
          <p>{{ lio.contactPersonEmail ?? '-' }}</p>
        </li>
        <li class="description-list__row">
          <p>Phone number:</p>
          <p>{{ lio.phoneNumber ?? '-' }}</p>
        </li>
        <li class="description-list__row">
          <p>Address:</p>
          <p>{{ lio.address ?? '-' }}</p>
        </li>
        <li class="description-list__row">
          <p>Company name:</p>
          <p>{{ lio.companyName ?? '-' }}</p>
        </li>
        <li class="description-list__row">
          <p>Company function:</p>
          <p>{{ lio.companyFunction ?? '-' }}</p>
        </li>
        <li class="description-list__row">
          <p>Markets:</p>
          <p>
            {{
              lio.isElectricity && lio.isGas
                ? 'Electricity, Gas'
                : !lio.isElectricity && !lio.isGas
                ? '-'
                : lio.isGas
                ? 'Gas'
                : 'Electricity'
            }}
          </p>
        </li>
        <li class="description-list__row">
          <p>EIC code:</p>
          <p>{{ lio.eicCode ?? '-' }}</p>
        </li>
        <li class="description-list__row">
          <p>VAT code:</p>
          <p>{{ lio.vatCode ?? '-' }}</p>
        </li>
        <li class="description-list__row">
          <p>ACER code:</p>
          <p>{{ lio.acerCode ?? '-' }}</p>
        </li>
        <li class="description-list__row">
          <p>Documents:</p>
          <div *ngIf="lio.documents.length > 0; else noDocs" class="ml-6">
            <div *ngFor="let document of lio.documents">
              <a [href]="[document.uri]">
                <div class="p-fileupload-filename mt-1">{{ document.name }}</div>
              </a>
            </div>
          </div>
          <ng-template #noDocs>
            <p>-</p>
          </ng-template>
        </li>
      </ul>
    </p-card>

    <app-modal-update-lio-infos [sendLio]="lio" #modalUpdateLioInfos></app-modal-update-lio-infos>
    <p-card *ngIf="lio" header="SFTP configuration">
      <app-modal-configure-sftp [lio]="lio" [isEdit]="lio.sftpUsername != null" />
      <div *ngIf="lio.sftpUsername != null" class="mt-5">
        <ul class="description-list w-60rem">
          <li class="description-list__row">
            <p>Username:</p>
            <p>{{ lio.sftpUsername ?? '-' }}</p>
          </li>
          <li class="description-list__row">
            <p>SSH Public key:</p>
            <p style="word-wrap: break-word">{{ lio.sftpPublicKey ?? '-' }}</p>
          </li>
          <li class="description-list__row">
            <p>Hostname:</p>
            <p style="word-wrap: break-word">
              {{ sftpConnectionString.connectionStringPart1 }}{{ lio.sftpUsername }}
              {{ sftpConnectionString.connectionStringPart2 }}
            </p>
          </li>
        </ul>
      </div>
    </p-card>
  </section>
</div>
