<div>
  <p-button
  [label]="isEdit ? 'Edit SFTP configuration' : 'Configure SFTP for LIO '+lio.lioNumber "
  icon="icon icon--plus"
  (click)="showDialog()"
></p-button>

  <p-dialog
    header="Configure SFTP"
    [(visible)]="visible"
    [modal]="true"
    [style]="{ width: '50vw' }"
    closeIcon="icon icon--close"
  >
    <ng-template pTemplate="header">
      <h2 class="h5">Configure SFTP for LIO {{lio.lioNumber}}</h2>
    </ng-template>
    <form [formGroup]="sftpForm">
      <div class="mb-5">
        <label>Username</label>
        <p-button
        icon="icon icon--tooltip"
        styleClass="p-button-rounded p-button-secondary p-button-text"
        [text]="true" severity="secondary" [rounded]="true"
        pTooltip="Username must be between 3 and 64 characters long and can only contain lowercase letters and numbers."
    ></p-button>
        <input formControlName="userName" class="w-full mt-2" pInputText placeholder="Input"/>
      </div>
      <div>
        <label>SSH public key</label>
        <p-button
            icon="icon icon--tooltip"
            styleClass="p-button-rounded p-button-secondary p-button-text"
            [text]="true" severity="secondary" [rounded]="true"
            pTooltip="Make sure the public key starts with “ssh-rsa” and doesn't contain other information than the public key."
        ></p-button>
        <textarea
          formControlName="sshPublicKey"
          pInputTextarea
          class="w-full p-inputtextarea-m mt-2"
          placeholder="ssh-rsa ..."
        ></textarea>
      </div>
    </form>
    <ng-template pTemplate="footer">
      <div class="flex justify-content-end gap-3">
        <p-button
          label="Cancel"
          styleClass="p-button-outlined p-button-secondary"
          [outlined]="true" severity="secondary"
          (click)="visible = false"
        ></p-button>
        <p-button label="Save configuration" type="submit" 
        [disabled]="!sftpForm.valid"
        (click)="saveSFTPConfiguration()" ></p-button>
      </div>
    </ng-template>
  </p-dialog>
</div>
