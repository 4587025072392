import { createAction, props } from '@ngrx/store';
import { EICInterface } from '@app-shared-types/eic.interface';
import { PaginatedResult } from '@app/shared/types/paginated-result.interface';
import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface';

export const GET_EICS = '[EIC Overview Component] Get EICs';
export const GET_EICS_SUCCESSFUL = '[EIC Overview Component] Get EICs succes';
export const GET_EICS_FAILED = '[EIC Overview Component] Get EICs failed';

export const GET_EICS_OF_LIO = '[EIC Overview Component] Get EICs of LIO';
export const GET_EICS_OF_LIO_SUCCESSFUL = '[EIC Overview Component] Get EICs of LIO succes';
export const GET_EICS_OF_LIO_FAILED = '[EIC Overview Component] Get EICs of LIO failed';

export const GENERATE_EIC = '[EIC Overview Component] Generate EIC';
export const GENERATE_EIC_SUCCESSFUL = '[EIC Overview Component] Generate EIC succes';
export const GENERATE_EIC_FAILED = '[EIC Overview Component] Generate EIC failed';

export const GENERATE_EIC_CHECK_KEY = '[EIC Overview Component] Generate EIC check key';
export const GENERATE_EIC_CHECK_KEY_SUCCESSFUL = '[EIC Overview Component] Generate EIC check key succes';
export const GENERATE_EIC_CHECK_KEY_FAILED = '[EIC Overview Component] Generate EIC check key failed';

export const VALIDATE_EIC = '[EIC Overview Component] Validate EIC';
export const VALIDATE_EIC_SUCCESSFUL = '[EIC Overview Component] Validate EIC succes';
export const VALIDATE_EIC_FAILED = '[EIC Overview Component] Validate EIC failed';

export const getEICs = createAction(
    GET_EICS,
    props<{ tableOptions: TableFilteringSortingOptions }>()
);

export const getEICsSuccessfully = createAction(
    GET_EICS_SUCCESSFUL,
    props<{ paginatedResult: PaginatedResult<EICInterface> }>()
);

export const getEICsFailed = createAction(
    GET_EICS_FAILED,
    props<{error: string}>()
);

export const getEICsOfLio = createAction(
    GET_EICS_OF_LIO,
    props<{ lioNumber: number; tableOptions: TableFilteringSortingOptions }>()
);

export const getEICsOfLioSuccessfully = createAction(
    GET_EICS_OF_LIO_SUCCESSFUL,
    props<{ paginatedResult: PaginatedResult<EICInterface> }>()
);

export const getEICsOfLioFailed = createAction(
    GET_EICS_OF_LIO_FAILED,
    props<{error: string}>()
);

export const generateEIC = createAction(
    GENERATE_EIC,
    props<{codeType: string}>()
);

export const generateEICSuccessfully = createAction(
    GENERATE_EIC_SUCCESSFUL,
    props<{eic: string}>()
);

export const generateEICFailed = createAction(
    GENERATE_EIC_FAILED,
    props<{error: string, eicCode: string}>()
);

export const validateEic = createAction(
    VALIDATE_EIC,
    props<{eic: string}>()
);

export const validateEicSuccessfully = createAction(
    VALIDATE_EIC_SUCCESSFUL,
    props<{isValid: boolean}>()
);

export const validateEicFailed = createAction(
    VALIDATE_EIC_FAILED,
    props<{error: string}>()
);

export const generateEicCheckKey = createAction(
    GENERATE_EIC_CHECK_KEY,
    props<{eic: string}>()
);

export const generateEicCheckKeySuccessfully = createAction(
    GENERATE_EIC_CHECK_KEY_SUCCESSFUL,
    props<{checkKey: string}>()
);

export const generateEicCheckKeyFailed = createAction(
    GENERATE_EIC_CHECK_KEY_FAILED,
    props<{error: string}>()
);