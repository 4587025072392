export enum ActiveCodeXmlNodes {
    REPORT = "Report",
    NAMESPACE_SCHEMA_LOCATION = "/assets/reporting/active_eic_codes.xsd",
    NAMESPACE_NAME = "active_eic_codes.xsd",
    NAMESPACE_XSI = "http://www.w3.org/2001/XMLSchema-instance",
    NAMESPACE = "active_eic_codes.xsd",

    LIO_CODES = "LIO_Codes",
    OFFICE_LIO_NUMBER_COLLECTION = "LV_OfficeLioNumber_Collection",

    OFFICE_LIO_NUMBER = "LV_OfficeLioNumber",
    OFFICE_LIO_NAME_COLLECTION = "LV_OfficeLioName_Collection",
    OFFICE_LIO_NAME = "LV_OfficeLioName",
    EIC_STATUS_COLLECTION = "LV_EicStatus_Collection",
    EIC_STATUS = "LV_EicStatus",
    EIC_STATUS_TOTAL = "EicStatus2",
    TEXTBOX1 = "Textbox1"
}
    