import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, mergeMap, catchError } from 'rxjs/operators'
import * as functionActions from '../actions/function.actions'
import { MessageService } from 'primeng/api'
import { FunctionService } from '@app/shared/services/function.service'

@Injectable()
export class FunctionEffect {
  constructor(
    private actions$: Actions,
    private functionService: FunctionService,
    private messageService: MessageService
  ) {}

  getFunctions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(functionActions.getFunctions),
      mergeMap(() => {
        return this.functionService.getAllFunctions().pipe(
          map((functions) => functionActions.getFunctionsSuccessfully({ functions })),
          catchError((error) => {
            this.messageService.add({
              key: 'toast',
              severity: 'error',
              summary: 'Error',
              detail: 'Get functions failed.'
            })
            return of(functionActions.getFunctionsFailed({ error: error.message }))
          })
        )
      })
    )
  )
}
