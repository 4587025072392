import { Component } from '@angular/core';

@Component({
  selector: 'app-authentication-issue',
  templateUrl: './authentication-issue.component.html',
  styleUrls: ['./authentication-issue.component.scss']
})
export class AuthenticationIssueComponent {

}
