import { createReducer, on } from '@ngrx/store';
import * as exportActions from '../actions/export.actions';
import { EICTpHistoryStateInterface } from '@app/shared/types/eic-tp-history-state.interface';

export const initialState: EICTpHistoryStateInterface = {
    isLoading: false,
	eicsTpHistory: [],
	error: null,
	pageNumber: 0,
	rowsPerPage: 10,
	totalRecords: 0
};

export const exportReducer = createReducer(
    { ...initialState },
    on(exportActions.getEICsTpHistory, (state) => ({
		...state,
		isLoading: true 
	})),
	on(exportActions.getEICsTpHistorySuccessfully, (state, action) => ({
		...state,
		isLoading: false,
		eicsTpHistory: action.paginatedResult.entities,
		totalRecords: action.paginatedResult.totalRecords,
		pageNumber: action.paginatedResult.pageNumber,
		rowsPerPage: action.paginatedResult.rowsPerPage
	})),
	on(exportActions.getEICsTpHistoryFailed, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	})),
);
	