<div class="flex flex-inline">
    <p-dropdown *ngIf="!hideExportSelection" [options]="exportTypes" [(ngModel)]="selectedExport" optionLabel="label" [showClear]="true" placeholder="Select export type">
        <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="selectedExport">
                <div>
                    <i [class]="'icon icon--' + selectedExport.icon.toLowerCase()" ></i>
                    {{ selectedExport.label }}
                </div>
            </div>
        </ng-template>
        <ng-template let-type pTemplate="item">
            <div class="flex align-items-center gap-2">
                <div>
                    <i [class]="'icon icon--' + type.icon.toLowerCase()"></i>
                    {{ type.label }}
                </div>
            </div>
        </ng-template>
    </p-dropdown>
    
    <p-button class="exportBtn" (click)="exportReport()" [disabled]="exportBtnDisabled">Export</p-button>
</div>


