import { createReducer, on } from '@ngrx/store';
import * as reportActions from '../actions/report.actions';
import { ReportInterface } from '@app/shared/types/report.interface';

export const initialState: ReportInterface = {
    isLoading: false,
	error: null,
	activeEIC: [],
	eicCodesAll: [],
	dectivatedCodes: [],
	allApprovedRequests: [],
	pageNumber: 0,
  	rowsPerPage: 10,
  	totalRecords: 0
};

export const reportReducer = createReducer(
    { ...initialState },
    on(reportActions.getActiveEicReport, (state) => ({
		...state,
		isLoading: true 
	})),
	on(reportActions.getActiveEicSuccessful, (state, action) => ({
		...state,
		isLoading: false,
		activeEIC: action.report.entities,
		totalRecords: action.report.totalRecords,
    	pageNumber: action.report.pageNumber,
    	rowsPerPage: action.report.rowsPerPage
	})),
	on(reportActions.getActiveEicFailed, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	})),

    on(reportActions.getAllEicReport, (state) => ({
		...state,
		isLoading: true 
	})),
	on(reportActions.getAllEicReportSuccessful, (state, action) => ({
		...state,
		isLoading: false,
		eicCodesAll: action.report.entities,
		totalRecords: action.report.totalRecords,
    	pageNumber: action.report.pageNumber,
    	rowsPerPage: action.report.rowsPerPage 
	})),
	on(reportActions.getAllEicReportFailed, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	})),

    on(reportActions.getDeactivatedCodesReport, (state) => ({
		...state,
		isLoading: true 
	})),
	on(reportActions.getDeactivatedCodesReportSuccessful, (state, action) => ({
		...state,
		isLoading: false,
		dectivatedCodes: action.report.entities,
		totalRecords: action.report.totalRecords,
    	pageNumber: action.report.pageNumber,
    	rowsPerPage: action.report.rowsPerPage
	})),
	on(reportActions.getDeactivatedCodesReportFailed, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	})),

    on(reportActions.getAllApprovedRequestsReport, (state) => ({
		...state,
		isLoading: true 
	})),
	on(reportActions.getAllApprovedRequestsReportSuccessful, (state, action) => ({
		...state,
		isLoading: false,
		allApprovedRequests: action.report.entities,
		totalRecords: action.report.totalRecords,
    	pageNumber: action.report.pageNumber,
    	rowsPerPage: action.report.rowsPerPage
	})),
	on(reportActions.getAllApprovedRequestsReportFailed, (state, action) => ({
		...state,
		isLoading: false,
		error: action.error
	})),
);
	