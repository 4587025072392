<div>
  <p-button
    label="Add new user"
    icon="icon icon--plus"
    (click)="showDialog()"
    disabled="true"
  ></p-button>
  <p-dialog
    header="Invite new users"
    [(visible)]="visible"
    [modal]="true"
    [style]="{ width: '50vw' }"
    closeIcon="icon icon--close"
  >
    <ng-template pTemplate="header">
      <h2 class="h5">Invite new users</h2>
    </ng-template>
    <div>
      <div class="mb-5">
        <div class="flex align-items-center">
          <label for="email_addresses">Enter email addresses</label>
          <p-button
            icon="icon icon--tooltip"
            styleClass="p-button-rounded p-button-secondary p-button-text"
            pTooltip="Enter the email addresses of the users you want to invite. Separate multiple email addresses with a comma."
          ></p-button>
          <span class="text-light">(optional)</span>
        </div>
        <textarea
          name="email_addresses"
          inputId="email_addresses"
          pInputTextarea
          class="w-full p-inputtextarea-sm mt-3"
        ></textarea>
      </div>
      <div>
        <label for="message">
          Include a personal message with this invitation
          <span class="text-light">(optional)</span>
        </label>
        <textarea
          name="message"
          inputId="message"
          pInputTextarea
          class="w-full p-inputtextarea-sm mt-3"
        ></textarea>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <div class="flex justify-content-end gap-3">
        <p-button
          label="Cancel"
          styleClass="p-button-outlined p-button-secondary"
          (click)="visible = false"
        ></p-button>
        <p-button label="Invite" type="submit"></p-button>
      </div>
    </ng-template>
  </p-dialog>
</div>
