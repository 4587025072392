import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, catchError, throwError } from 'rxjs'
import { MessageInterface } from '../types/message.interface'

@Injectable()
export class HomepageMessageService {
  constructor(private http: HttpClient) {}

  getMessages(): Observable<MessageInterface[]> {
    return this.http.get<MessageInterface[]>('messages').pipe(catchError(this.handleError))
  }

  private handleError(error: HttpErrorResponse) {
    var errorMessage = ''
    if (error.status === 0) {
      errorMessage = 'Could not connect to the backend API. Please contact the system administrator'
    } else {
      errorMessage = error.error
    }
    return throwError(() => new Error(errorMessage))
  }
}
