import { TableFilteringSortingOptions } from '@app/shared/types/export-options-columns.interface';
import { PaginatedResult } from '@app/shared/types/paginated-result.interface';
import { createAction, props } from '@ngrx/store';

export const GET_ACTIVE_EIC_REPORT = '[Active EIC Report Overview Component] Get active eic report';
export const GET_ACTIVE_EIC_REPORT_SUCCESSFUL = '[Active EIC Report Overview Component] Get active eic report succes';
export const GET_ACTIVE_EIC_REPORT_FAILED = '[Active EIC Report Overview Component] Get active eic report failed';

export const GET_ALL_EIC_REPORT = '[All EIC Report Overview Component] Get all eic report';
export const GET_ALL_EIC_REPORT_SUCCESSFUL = '[All EIC Report Overview Component] Get all eic report succes';
export const GET_ALL_EIC_REPORT_FAILED = '[All EIC Report Overview Component] Get all eic report failed';

export const GET_DEACTIVATED_CODES_REPORT = '[Deactivated codes Report Overview Component] Get deactivated codes report';
export const GET_DEACTIVATED_CODES_REPORT_SUCCESSFUL = '[Deactivated codes Report Overview Component] Get deactivated codes report succes';
export const GET_DEACTIVATED_CODES_REPORT_FAILED = '[Deactivated codes Report Overview Component] Get deactivated codes report failed';

export const GET_ALL_APPROVED_REQUESTS_REPORT = '[All approved requests Report Overview Component] Get all approved requests report';
export const GET_ALL_APPROVED_REQUESTS_REPORT_SUCCESSFUL = '[All approved requests Report Overview Component] Get all approved requests report succes';
export const GET_ALL_APPROVED_REQUESTS_REPORT_FAILED = '[All approved requests Report Overview Component] Getall approved requests report failed';


export const getActiveEicReport = createAction(
    GET_ACTIVE_EIC_REPORT,
    props<{ props: TableFilteringSortingOptions | undefined }>()
);
export const getActiveEicSuccessful = createAction(
    GET_ACTIVE_EIC_REPORT_SUCCESSFUL,
    props<{report: PaginatedResult<any>}>()
);
export const getActiveEicFailed = createAction(
    GET_ACTIVE_EIC_REPORT_FAILED,
    props<{error: string}>()
);

export const getAllEicReport = createAction(
    GET_ALL_EIC_REPORT,
    props<{ props: TableFilteringSortingOptions | undefined }>()
);
export const getAllEicReportSuccessful = createAction(
    GET_ALL_EIC_REPORT_SUCCESSFUL,
    props<{report: PaginatedResult<any>}>()
);
export const getAllEicReportFailed = createAction(
    GET_ALL_EIC_REPORT_FAILED,
    props<{error: string}>()
);

export const getDeactivatedCodesReport = createAction(
    GET_DEACTIVATED_CODES_REPORT,
    props<{ props: TableFilteringSortingOptions | undefined }>()
);
export const getDeactivatedCodesReportSuccessful = createAction(
    GET_DEACTIVATED_CODES_REPORT_SUCCESSFUL,
    props<{report: PaginatedResult<any>}>()
);
export const getDeactivatedCodesReportFailed = createAction(
    GET_DEACTIVATED_CODES_REPORT_FAILED,
    props<{error: string}>()
);

export const getAllApprovedRequestsReport = createAction(
    GET_ALL_APPROVED_REQUESTS_REPORT,
    props<{ props: TableFilteringSortingOptions | undefined }>()
);
export const getAllApprovedRequestsReportSuccessful = createAction(
    GET_ALL_APPROVED_REQUESTS_REPORT_SUCCESSFUL,
    props<{report: PaginatedResult<any>}>()
);
export const getAllApprovedRequestsReportFailed = createAction(
    GET_ALL_APPROVED_REQUESTS_REPORT_FAILED,
    props<{error: string}>()
);