import { createReducer, on } from '@ngrx/store'
import * as messageActions from '../actions/message.action'
import { MessageStateInterface } from '@app/shared/types/message-state.interface'

export const initialState: MessageStateInterface = {
  isLoading: false,
  messages: [],
  error: null
}

export const messageReducer = createReducer(
  { ...initialState },
  on(messageActions.getMessages, (state) => ({
    ...state,
    isLoading: true
  })),
  on(messageActions.getMessagesSuccesfully, (state, action) => ({
    ...state,
    isLoading: false,
    messages: action.messages
  })),
  on(messageActions.getMessagesFailed, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
    messages: []
  }))
)
